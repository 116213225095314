import {zodResolver} from '@hookform/resolvers/zod'
import {Select} from 'antd'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import LoadingTable from 'src/common/LoadingTable'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {FILTER_SELECTALL_SORTBY, USERTYPE} from 'src/constants'
import {cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo} from 'src/utils'
import {replaceValueAll} from 'src/utils/string'
import {z} from 'zod'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import RoleBadge from '../base/roleBadge'
import {useParams} from 'react-router-dom'

const {Option} = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  userState: any
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchListUsers: (currentPage?: number, pageSize?: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
  fetchListUserSelected: any
  setUserState: any
  setSelectedUserIds: Dispatch<SetStateAction<any>>
  selectedUserIds: any
}

// define headers
const headers = [
  {
    label: 'STUDENT ID',
    className: 'min-w-150px',
  },
  {
    label: 'NAME',
    className: 'min-w-200px',
  },
  {
    label: 'EMAIL',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'PHONE',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'ROLE',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'DATE',
    className: 'min-w-100px pe-5',
  },
]

const TableMembers = ({
  userState,
  loading,
  setLoading,
  fetchListUsers,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  fetchListUserSelected,
  setUserState,
  setSelectedUserIds,
  selectedUserIds,
}: IProps) => {
  const initialValues: any = {
    text: '',
    type: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    selectType: '',
  }
  const [page_index, setPage_index] = useState(1)
  const [page_size, setPage_size] = useState(10)
  const [searchParams, setSearchParams] = useState<any>(initialValues)
  const {id} = useParams()
  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác

  const validationSchema = z.object({
    text: z.string().optional(),
    type: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    selectType: z.any(),
  })
  const fieldNames = ['text', 'type', 'sortType', 'fromDate', 'toDate']

  // Using validate for input
  const {control, getValues, reset, setValue} = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const showDateField =
    getValues('text') || getValues('type') || getValues('fromDate') || getValues('toDate')

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    setLoading(true)
    const dateInfoFromDate = searchParams.fromDate
    const dateInfoToDate = searchParams.toDate
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text,
        searchParams?.type,
        searchParams?.sortType,
        searchParams.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        searchParams.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        searchParams.selectType
      )
    )
    id
      ? fetchListUserSelected(page_index, page_size, {
          ...cleanedParams,
          dateField: showDateField ? 'updated_at' : 'created_at',
          status: 'ACTIVE',
        })
      : fetchListUsers(page_index, page_size, {
          ...cleanedParams,
          dateField: showDateField ? 'updated_at' : 'created_at',
          status: 'ACTIVE',
        })
    // fetchListUsers(page_index, page_size, {...cleanedParams, dateField: showDateField ? 'updated_at' : 'created_at'})
  }

  useEffect(() => {
    setLoading(true)
    const dateInfoFromDate = searchParams.fromDate
    const dateInfoToDate = searchParams.toDate
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text,
        searchParams?.type,
        searchParams?.sortType,
        searchParams.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        searchParams.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        searchParams.selectType
      )
    )
    id
      ? fetchListUserSelected(page_index, page_size, {...cleanedParams, status: 'ACTIVE'})
      : fetchListUsers(page_index, page_size, {...cleanedParams, status: 'ACTIVE'})
    // fetchListUsers(page_index, page_size, cleanedParams)
  }, [])

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })

    // const cleanedParams = cleanParamsAPI(
    //   getParams(
    //     replaceValueAll(getValues('text')),
    //     replaceValueAll(getValues('gender')),
    //     replaceValueAll(getValues('sortType'))
    //   )
    // )
    setSearchParams(initialValues)
    setLoading(true)
    id
      ? fetchListUserSelected(1, 10, {status: 'ACTIVE'})
      : fetchListUsers(1, 10, {status: 'ACTIVE'})
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        replaceValueAll(getValues('text')?.trimStart()),
        replaceValueAll(getValues('type')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : ''
      )
    )
    setSearchParams(() => {
      return cleanedParams
    })
    setLoading(true)
    id
      ? fetchListUserSelected(1, queryParams.page_size || 10, {
          ...cleanedParams,
          dateField: showDateField ? 'updated_at' : 'created_at',
          status: 'ACTIVE',
        })
      : fetchListUsers(1, queryParams.page_size || 10, {
          ...cleanedParams,
          dateField: showDateField ? 'updated_at' : 'created_at',
          status: 'ACTIVE',
        })
  }

  const renderRole = (type: string) => {
    switch (type) {
      case 'STUDENT':
        return <RoleBadge text='Học viên' color='secondary' />
      case 'TEACHER':
        return <RoleBadge text='Giáo viên' color='secondary' />
      default:
        return <></>
    }
  }
  const allUsers = userState?.users || []
  // const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const handleToggleCheck = (userId: string) => {
    setUserState((prevUserState: any) => {
      let updatedSelectedUserIds = [...selectedUserIds] // Tạo một bản sao mới của mảng cũ

      const updatedUsers = prevUserState.users.map((user: any) => {
        if (user.id === userId) {
          // Toggle the 'is_selected' property for the specific user
          const newUser = {
            ...user,
            is_selected: !user.is_selected,
          }

          // Thêm hoặc xóa userId từ mảng tạm thời
          if (newUser.is_selected) {
            updatedSelectedUserIds.push(userId)
          } else {
            updatedSelectedUserIds = updatedSelectedUserIds.filter((id) => id !== userId)
          }

          return newUser
        }
        return user
      })

      setSelectedUserIds(updatedSelectedUserIds)

      return {
        ...prevUserState,
        users: updatedUsers,
      }
    })
  }

  const isCheckedAllMemberAll = () => {
    // Kiểm tra xem tất cả người dùng có đều được chọn hay không
    return allUsers.every((user: any) => user.is_selected)
  }

  const handleToggleAll = () => {
    setUserState((prevUserState: any) => {
      const updatedUsers = prevUserState.users.map((user: any) => ({
        ...user,
        is_selected: !isCheckedAllMemberAll(), // Đảo ngược trạng thái chọn tất cả
      }))

      const updatedSelectedUserIds = updatedUsers
        .filter((user: any) => user.is_selected)
        .map((user: any) => user.id)

      setSelectedUserIds(updatedSelectedUserIds)

      return {
        ...prevUserState,
        users: updatedUsers,
      }
    })
  }

  return (
    <div className='card-body py-5'>
      <div className='card-header border-0'>
        <div className='w-100'>
          <div className='row'>
            {/* begin:: Search */}
            <div className='col-xl-3 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                <HookFormTextField
                  control={control}
                  name='text'
                  placeholder='Search'
                  defaultValue={queryParams?.text}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            {/* end:: Search */}
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='type'
                placeholder='Role'
                defaultValue={queryParams.gender ?? ''}
                className='sapp-fs-select sapp-fs'
              >
                {USERTYPE.map((type) => (
                  <Option key={type.label} value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                size='large'
                defaultValue={queryParams?.sortType ?? ''}
                className='sapp-fs-select sapp-fs'
              >
                {FILTER_SELECTALL_SORTBY.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2 '>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams?.fromDate}
                isListScreen
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-2 '>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams?.toDate}
                isListScreen
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6 mb-6 px-0'>
        <div className='container m-0'>
          <div className='row'>
            <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit='Search'
                okClick={onSubmit}
                resetClick={handleResetFilter}
                disabled={loading}
                loading={loading}
                classNameCancel='sapp-fs--default'
                classNameSubmit='sapp-fs--default'
              />
            </div>
          </div>
        </div>
      </div>
      <SappTable
        headers={headers}
        loading={loading}
        data={allUsers}
        isCheckedAll={id ? isCheckedAllMemberAll() : isCheckedAll}
        hasCheckAll={true}
        onChange={
          id
            ? handleToggleAll
            : () => {
                toggleCheckAll(!isCheckedAll, true)
              }
        }
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((header, i) => (
              <LoadingTable key={header} headers={[1, 2, 3, 4, 5]} />
            ))}
          </>
        ) : (
          <>
            {userState?.users?.map((user: any) => {
              const isChecked = id ? user.is_selected : checkedList.includes(user.id)
              const user_contract = user?.user_contacts?.find(
                (user: any) => user?.is_default === true
              )

              return (
                <tr key={user.id}>
                  <td>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked || selectedUserIds.includes(user.id) || false}
                      ktCheck={isChecked || selectedUserIds.includes(user.id) || false}
                      onChange={() => (id ? handleToggleCheck(user.id) : toggleCheck(user.id!))}
                    />
                  </td>
                  <td className='text-start'>{user?.hubspot_contact_id}</td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-2 sapp-table-title-des text-wrap text-break'>
                      {user?.detail?.full_name}
                    </div>
                  </td>
                  <td className='text-start'>
                    {user_contract?.email || user?.user_contacts?.[0]?.email}
                  </td>
                  <td className='text-start'>
                    {user_contract?.phone || user?.user_contacts?.[0]?.phone}
                  </td>
                  <td className='text-start'>{renderRole(user?.type)}</td>
                  <td className='text-start'>
                    <DatetimeColumn created_at={user?.created_at} updated_at={user?.updated_at} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <div></div>
      <PagiantionSAPP
        currentPage={userState?.metadata?.page_index || 1}
        pageSize={userState?.metadata?.page_size || 10}
        totalItems={userState?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default TableMembers
