import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import PageLayouts from 'src/components/layout/PageLayouts'
import { DEFAULT_SELECT_ALL, FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import useChecked from 'src/hooks/use-checked'
import { ITabs, Role } from 'src/type'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_GROUPS_GR } from 'src/constants/permission'
import { SupporterGroupTable } from 'src/components/supporter-group/SupporterGroupTable'
import { CommentAPI } from 'src/apis/comments'
import { ISupportGroupItem, ISupportGroupList } from 'src/type/comment'
import { ISubject, ISubjectList } from 'src/type/subject'
import { debounce } from 'lodash'
import { SubjectAPI } from 'src/apis/subject'
import { ICourseCategoies } from 'src/type/courses'
import { CoursesAPI } from 'src/apis/courses'
import { Department } from 'src/type/department'
import { DepartmentAPI } from 'src/apis/department'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: 'Comments',
  },
  {
    link: '',
    title: LANG_SIDEBAR.supporterGroupList,
  },
]
const fieldNames = [
  'text',
  'course_category_id',
  'subject_id',
  'sort_type',
  'fromDate',
  'toDate',
  'department_id',
]
const initialValues = {
  text: '',
  course_category_id: '',
  subject_id: '',
  sort_type: '',
  fromDate: '',
  toDate: '',
  department_id: '',
}
const SupporterGroupList = () => {
  const [groupList, setGroupList] = useState<ISupportGroupList>()
  const [deparmentList, setDepartmentList] = useState<Department[]>([])
  const navigate = useNavigate()
  const { profileMe } = useUserContext()
  const allowRenderCreateGroups = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GROUPS_GR.CREATE_GROUP) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(search)

  //handle filter department

  const fetchDepartment = async (pageIndex?: number, pageSize?: number, params?: Object) => {
    try {
      const res = await DepartmentAPI.get({
        page_index: pageIndex ?? 1,
        page_size: pageSize ?? 50,
        params,
      })
      setDepartmentList((prev) => [...prev, ...(res.data ?? [])])
    } catch (error) {}
  }

  // ==================== Handle Subject ============================

  /**
   * @description state lưu giá trị của subject
   */

  const [subjects, setSubjects] = useState<ISubjectList>()
  const requestOngoingRef = useRef(false)

  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await SubjectAPI.getSubjects(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.subjects.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  const getSubjects = async (params?: Object) => {
    const resMentor = await fetchSubjects(1, 20, params ?? {})
    setSubjects(resMentor?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    if (watch('course_category_id')) {
      getSubjects({ name: e, course_category_id: watch('course_category_id') })
    }
  }, 500)

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  // ====================End Handle Subject ============================

  // ====================Start Handle Program ==========================
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()

  const fetchCourseCategory = async () => {
    try {
      const res = await CoursesAPI.getCategory({ page_index: 1, page_size: 100 })
      setCourseCategory(res?.data)
      if (watch('course_category_id') && watch('course_category_id') !== 'all') {
        await getSubjects({ course_category_ids: watch('course_category_id') })
      }
    } catch (error) {}
  }

  const newCourseCategory = useMemo(
    () =>
      courseCategory?.course_categories?.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [courseCategory]
  )

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('subject_id', '')
    if (watch('course_category_id') !== 'all') {
      await getSubjects({ course_category_id: watch('course_category_id') })
    }
  }

  // ====================End Handle Program ============================

  const queryParams = {
    text: searchParams.get('text') ?? '',
    course_category_id: searchParams.get('course_category_id') ?? '',
    subject_id: searchParams.get('subject_id') ?? '',
    sort_type: searchParams.get('sort_type') ?? '',
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
    department_id: searchParams.get('department_id') ?? '',
  }
  const validateSchema = z.object({
    text: z.string().optional(),
    course_category_id: z.string().optional(),
    subject_id: z.string().optional(),
    department_id: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    sort_type: z.string().optional(),
  })

  const { control, getValues, reset, setValue, watch } = useForm({
    resolver: zodResolver(validateSchema),
    mode: 'onSubmit',
  })
  const handleChangeParams = (currentPage: number, size: number) => {
    const query = {
      page_index: currentPage,
      page_size: size,
      text: getValues('text') ?? '',
      course_category_id: getValues('course_category_id') ?? '',
      subject_id: getValues('subject_id') ?? '',
      department_id: getValues('subject_id') ?? '',
      sort_type: replaceValueAll(getValues('sort_type')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }
    const queryString = Object.entries(query)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const getParams = (
    text: string,
    sort_type: string,
    course_category_id: string,
    subject_id: string,
    department_id: string,
    fromDate?: string | Date,
    toDate?: string | Date,
    dateField?: string
  ) => ({
    text,
    course_category_id,
    subject_id,
    department_id,
    sort_type,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)
  const checkDatefield =
    queryParams.text || queryParams.sort_type || queryParams.fromDate || queryParams.toDate
  const showDatefield =
    getValues('text') || getValues('sort_type') || getValues('fromDate') || getValues('toDate')

  const filterParams = cleanParamsAPI(
    getParams(
      queryParams.text?.trim() ?? '',
      queryParams.sort_type ?? '',
      queryParams.course_category_id ?? '',
      queryParams.subject_id ?? '',
      queryParams.department_id ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      checkDatefield ? 'updated_at' : 'created_at'
    )
  )
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<ISupportGroupItem>(
    groupList?.data || []
  )

  const fetchGroupList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await CommentAPI.getSupportGroupList({
        page_index: currentPage,
        page_size: pageSize,
        params,
      })
      setGroupList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('text').trim() ?? '',
        getValues('sort_type') ?? '',
        getValues('course_category_id') ?? '',
        getValues('subject_id') ?? '',
        getValues('department_id') ?? '',
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        showDatefield ? 'updated_at' : 'created_at'
      )
    )
    setLoading(true)
    fetchGroupList(1, queryParams.page_size || 10, cleanedParams)
    handleChangeParams(1, queryParams.page_size || 10)
  }

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((field) => {
      setValue(field, initialValues[field as keyof typeof initialValues])
    })
    setLoading(true)
    navigate('/support-group')
    fetchGroupList(1, 10, { dateField: 'created_at' })
  }

  useEffect(() => {
    fetchGroupList(1, 10)
    fetchCourseCategory()
    fetchDepartment(1, 50)
  }, [])

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.supporterGroupList} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='card-header border-0 pt-10 px-10'>
          <div className='container'>
            <div className='row'>
              {/* begin:: Search */}
              <div className='col-xl-3 col-sm-4 ps-0'>
                <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
                  <HookFormTextField
                    control={control}
                    name='text'
                    placeholder='Search by Group Name'
                    defaultValue={queryParams?.text}
                    isListScreen
                    onSubmit={onSubmit}
                  />
                </div>
              </div>
              {/* end:: Search */}
              <div className='col-xl-3 col-sm-4 ps-0'>
                <HookFormSelectAntd
                  name='course_category_id'
                  placeholder='Program'
                  control={control}
                  defaultValue={queryParams?.course_category_id ?? ''}
                  classNameHeight='sapp-h-40'
                  onChange={handleCourseCategoryChange}
                >
                  {DEFAULT_SELECT_ALL?.concat(
                    newCourseCategory ?? ([] as Array<{ label: string; value: string }>)
                  ).map((course_category_ids) => (
                    <Option key={course_category_ids.value} value={course_category_ids.value}>
                      {course_category_ids.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 ps-0'>
                <HookFormSelectAntd
                  name='subject_id'
                  placeholder='Subject'
                  control={control}
                  onSearch={(e) => {
                    if (e === undefined) {
                      return
                    }
                    debounceSearchSubject(e)
                  }}
                  handleNextPage={(e: any) =>
                    handleNextPageSubject({
                      name: e,
                      course_category_id: watch('course_category_id'),
                    })
                  }
                  showSearch
                  loading={loading}
                  defaultValue={queryParams?.subject_id ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {subjectCourse?.map((subject) => (
                    <Option key={subject.value} value={subject.value}>
                      {subject.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 ps-0 mt-xl-0 mt-5'>
                <HookFormSelectAntd
                  name='department_id'
                  placeholder='Department'
                  control={control}
                  onSearch={(e) => {
                    if (e === undefined) {
                      return
                    }
                    fetchDepartment(1, 50, { text: e })
                  }}
                  handleNextPage={(e: any) => {}}
                  showSearch
                  loading={loading}
                  defaultValue={queryParams?.department_id ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {deparmentList?.map((deparment) => (
                    <Option key={deparment.id} value={deparment.id}>
                      {deparment.name}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 ps-0 mt-5'>
                <HookFormSelectAntd
                  name='sort_type'
                  placeholder='Sort by'
                  control={control}
                  defaultValue={queryParams?.sort_type ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {FILTER_SELECTALL_SORTBY.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 ps-0 mt-5 ps-0'>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParams?.fromDate}
                  isListScreen
                />
              </div>
              <div className='col-xl-3 col-sm-4 mt-5 ps-0'>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParams?.toDate}
                  isListScreen
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
          <>
            {allowRenderCreateGroups && (
              <ButtonIconPrimary
                iconName='plus'
                title='Create Supporter Group'
                onClick={() => navigate(`${PageLink.CREATE_SUPORTER_GROUP}`)}
                size='small'
              />
            )}
          </>
        </div>
        <SupporterGroupTable
          checkedList={checkedList}
          fetchList={fetchGroupList}
          filterParams={filterParams}
          getParams={getParams}
          isCheckedAll={isCheckedAll}
          loading={loading}
          groupList={groupList}
          queryParams={queryParams}
          setLoading={setLoading}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          handleChangeParams={handleChangeParams}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(SupporterGroupList)
