import React, {createContext, PropsWithChildren, useContext, useState} from 'react'

type Context = {
  isSingle: boolean
  setIsSingle: React.Dispatch<React.SetStateAction<boolean>>
}

const initContext: Context = {
  isSingle: false,
  setIsSingle: () => {},
}

const QuestionContext = createContext<Context>(initContext)

export function QuestionProvider(props: PropsWithChildren<{}>) {
  const [isSingle, setIsSingle] = useState<boolean>(false)
  return <QuestionContext.Provider value={{isSingle, setIsSingle}} {...props} />
}

export function useQuestionContext(): Context {
  const context = useContext(QuestionContext)
  if (!context) {
    throw new Error('Error!')
  }
  return context
}
