import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { ITabs, ImportType, Role } from 'src/type'
import withAuthComponents from 'src/components/auth/with-auth-components'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { KTIcon } from 'src/_metronic/helpers'
import toast from 'react-hot-toast'
import { useState } from 'react'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ModalImportCheckDuplicateContact from './ModalImport'
import { useQueryResponse } from 'src/components/staff-list/components/core/QueryResponseProvider'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_MARKETING_GR } from 'src/constants/permission'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.marketing,
  },
  {
    link: `${PageLink.CHECK_DUPLICATE_CONTACT}`,
    title: 'Check Duplicate Contacts',
  },
]

const CheckDuplicateContact = () => {
  const { refetch } = useQueryResponse()
  const [openUpload, setOpenUpload] = useState(false)

  const beforeUpload = (file: File) => {
    const isExcel =
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    const isCSV = file.type === 'text/csv'
    if (!isExcel && !isCSV) {
      toast.error('You can only upload Excel (.xlsx) or CSV (.csv) files!')
      return false
    }
    if (file.size > 5 * 1024 * 1024) {
      toast.error('The file is too large! It must be less than or equal to 5 MB!')
      return false
    }
    return true
  }

  // * Check phân quyền
  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderImport = hasPermission(
    profileMe?.roles,
    TITLE_MARKETING_GR.CHECK_DUPLICATE_CONTACT
  )

  return (
    <PageLayouts pageTitle='Check Duplicate Contacts' breadcrumbs={breadcrumbs}>
      <div className='card sapp-min-h-80vh mb-5'>
        <div className='card-body d-flex justify-content-center flex-center flex-column'>
          <ButtonIcon
            disabled={!allowRenderImport}
            title={'Upload file'}
            className='w-10 mx-xl-5 btn btn-primary btn-sapp-filter me-3 px-3 py-3 sapp-font-import-student sapp-color-import-student'
            onClick={() => setOpenUpload(true)}
            isListScreen
          >
            <KTIcon iconName='file-up' className='fs-3 pe-3' iconType='outline' />
          </ButtonIcon>
          <div className='d-flex justify-content-center flex-center flex-column sapp-color-gray-role pt-4'>
            <span>Tải lên tệp tin danh sách contacts cần kiểm tra trùng lặp.</span>
            <span>
              File mẫu:{' '}
              <a href={'/IMPORT_CHECK_DUPLICATE_CONTACT.xlsx'} download>
                template_check_duplicate.xlsx
              </a>
            </span>
          </div>
        </div>
      </div>

      <ModalImportCheckDuplicateContact
        open={openUpload}
        setOpen={setOpenUpload}
        title='Import Check Duplicate Contacts'
        uploadUrl={`users/import-check-duplicate-contact`}
        type={ImportType.CHECK_DUPLICATE_CONTACT}
        beforeUpload={(file) => beforeUpload(file)}
        refetch={refetch}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(CheckDuplicateContact)
