import { Popover, Tooltip } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { useUserContext } from 'src/context/UserProvider'
import sapp from 'src/_metronic/assets/icons/sapp_menu.svg'
import PopoverProfile from './PopoverProfile'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import MenuItem from './MenuItem'
import { KTIcon } from 'src/_metronic/helpers'

interface IProps {
  item?: any
  isOpen?: boolean
  onToggle?: any
  menuMarketing?: Array<any>
  menuLMS?: Array<any>
  menuAdmin?: Array<any>
  activeAdmin?: boolean
  activeMarketing?: boolean
}

const MenuLeft = ({ menuLMS, menuAdmin, menuMarketing, activeAdmin, activeMarketing }: IProps) => {
  const { logOut, profileMe } = useUserContext()
  const navigate = useNavigate()
  const sidebarLeft = true

  return (
    <div className='app-sidebar-primary'>
      <div className='app-sidebar-logo d-none d-md-flex flex-center pt-10 pb-2'>
        <Link to={PageLink.DASHBOARD}>
          <img alt='Logo' src={sapp} className='sapp-h-45px' />
        </Link>
      </div>

      <div className='app-sidebar-menu flex-grow-1 my-5'>
        <div className='menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-6'>
          <div className='menu-item py-2'>
            <Popover
              placement='rightBottom'
              arrow={false}
              content={
                <div className='px-2 py-4 w-250px mh-75 overflow-auto min-h-100 h-550px'>
                  <div className='menu-item'>
                    <div className='menu-content'>
                      <span className='menu-section fs-5 fw-bolder ps-1 py-1'>
                        {LANG_SIDEBAR.lms}
                      </span>
                    </div>
                  </div>

                  {menuLMS?.map((item) => (
                    <MenuItem key={item.id} item={item} sidebarLeft={sidebarLeft} />
                  ))}
                </div>
              }
            >
              <span
                className={`${
                  !activeAdmin && !activeMarketing ? 'active' : ''
                } menu-link menu-center`}
              >
                <span className='menu-icon me-0'>
                  <i className='ki-outline ki-home-1 fs-1'></i>
                </span>
              </span>
            </Popover>
          </div>

          {menuAdmin && menuAdmin?.length > 0 && (
            <div className='menu-item py-2'>
              <Popover
                placement='rightTop'
                arrow={false}
                content={
                  <div className='menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto'>
                    <div className='menu-item'>
                      <div className='menu-content'>
                        <span className='menu-section fs-5 fw-bolder ps-1 py-1'>
                          {LANG_SIDEBAR.admin}
                        </span>
                      </div>
                    </div>
                    {menuAdmin?.map((item) => (
                      <MenuItem key={item.id} item={item} sidebarLeft={sidebarLeft} />
                    ))}
                  </div>
                }
              >
                <span className={`${activeAdmin ? 'active' : ''} menu-link menu-center`}>
                  <span className='menu-icon me-0'>
                    <i className='ki-outline ki-shield-tick fs-1'></i>
                  </span>
                </span>
              </Popover>
            </div>
          )}

          {menuMarketing && menuMarketing?.length > 0 && (
            <div className='menu-item py-2'>
              <Popover
                placement='rightTop'
                arrow={false}
                content={
                  <div className='menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto'>
                    <div className='menu-item'>
                      <div className='menu-content'>
                        <span className='menu-section fs-5 fw-bolder ps-1 py-1'>
                          {LANG_SIDEBAR.operations}
                        </span>
                      </div>
                    </div>
                    {menuMarketing?.map((item) => (
                      <MenuItem key={item.id} item={item} sidebarLeft={sidebarLeft} />
                    ))}
                  </div>
                }
              >
                <span className={`${activeMarketing ? 'active' : ''} menu-link menu-center`}>
                  <span className='menu-icon me-0'>
                    <KTIcon iconName='abstract-26' iconType='outline' />
                  </span>
                </span>
              </Popover>
            </div>
          )}
        </div>
      </div>

      <div className='d-flex flex-column flex-center pb-4 pb-lg-8' id='kt_app_sidebar_footer'>
        <Popover
          placement='rightTop'
          content={<PopoverProfile profile={profileMe} />}
          arrow={false}
        >
          <div className='cursor-pointer symbol symbol-40px mb-9'>
            <img
              src={
                profileMe?.detail?.avatar?.['40x40'] ?? profileMe?.detail?.avatar?.ORIGIN ?? avatar
              }
              alt='user'
            />
          </div>
        </Popover>

        <Tooltip title='Log out' color='#ffffff' placement='right'>
          <div
            className='app-navbar-item cursor-pointer'
            onClick={() => logOut().then(() => navigate(PageLink.AUTH_LOGIN))}
          >
            <i className='ki-outline ki-exit-right fs-1'></i>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default MenuLeft
