import React, { useState } from 'react'
import { ITabs } from 'src/type'
import SappTabs from 'src/components/tabs/SappTabs'
import Progress from 'src/components/base/Progress'
import LoadingCard from '../my-profile/overview-profile/loading/LoadingCard'
import { IStudentDetail } from 'src/type/students'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import MoreInfoUser from './MoreInfoUser'
import { multiply, round } from 'lodash'
import ModalPreviewImage from './ModalPreviewImage'
import './Card.scss'

interface IProps {
  userDetail: IStudentDetail | undefined
  tabs: ITabs[]
  loading: boolean
  progress: number
}

const Card = ({ userDetail, tabs, loading, progress }: IProps) => {
  const [openPreview, setOpenPreview] = useState(false)
  const showAvatar = userDetail?.detail?.avatar?.['160x160']
  const showAvatarOrigin = userDetail?.detail?.avatar?.ORIGIN
  const userContact = userDetail?.user_contacts?.find((user) => user.is_default)
  return (
    <>
      {loading ? (
        <LoadingCard />
      ) : (
        <div className='card sapp-card--profile mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='me-7 mb-4'>
                {/* Start Show Avatar Profile */}
                <div
                  className={`symbol symbol-100px symbol-lg-160px symbol-fixed position-relative ${
                    showAvatar || showAvatarOrigin ? 'cursor-pointer' : ''
                  } ${showAvatar ? '' : 'object-fit-cover'}`}
                  onClick={() => setOpenPreview(showAvatar || showAvatarOrigin ? true : false)}
                >
                  <img src={showAvatar ?? showAvatarOrigin ?? avatar} alt='avatar' />
                </div>
                {/* End Show Avatar User */}
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      {/* Start Name User */}
                      <div className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                        {userDetail?.detail?.full_name}
                      </div>
                      {/* End Name User */}
                      <div>
                        <i className='ki-outline ki-verify fs-1 text-primary'></i>
                      </div>
                    </div>
                    <MoreInfoUser
                      email={userDetail?.detail?.email ?? userContact?.email}
                      phone={userDetail?.detail?.phone ?? userContact?.phone}
                      university={userDetail?.detail?.university}
                      status={userDetail?.status}
                    />
                  </div>

                  <div className='d-flex my-4'>
                    {/* Start Button Follow */}
                    {/* <a
                      href={PageLink.DASHBOARD}
                      className='btn btn-sm btn-light me-2'
                      id='kt_user_follow_button'
                    >
                      <i className='ki-outline ki-check fs-3 d-none'></i>

                      <span className='indicator-label'>Follow</span>

                      <span className='indicator-progress'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </a> */}
                    {/* End Button Follow */}

                    {/* Start Button Hire Me */}
                    {/* <a
                      href={PageLink.DASHBOARD}
                      className='btn btn-sm btn-primary me-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_offer_a_deal'
                    >
                      Hire Me
                    </a> */}
                    {/* End Button Hire Me */}
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          {/* <i className='ki-outline ki-arrow-up fs-3 text-success me-2'></i> */}
                          <div className='fs-2 fw-bold'>30</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>Course enrolled</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          {/* <i className='ki-outline ki-arrow-down fs-3 text-danger me-2'></i> */}
                          <div className='fs-2 fw-bold'>30</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>Course completed</div>
                      </div>
                    </div>
                  </div>
                  {/* Start Progress */}
                  <Progress title='Completion' percent={round(multiply(progress, 100), 0)} />
                  {/* End Progress */}
                </div>
              </div>
            </div>
            {/* Start Tabs */}
            <SappTabs tabs={tabs} />
            {/* End Tabs */}
          </div>
          <ModalPreviewImage
            openPreview={openPreview}
            setOpenPreview={setOpenPreview}
            avatarResize={userDetail?.detail?.avatar?.ORIGIN}
          />
        </div>
      )}
    </>
  )
}

export default Card
