import { UploadFile } from 'antd/es/upload'
import axios, { CancelTokenSource } from 'axios'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { UploadAPI } from 'src/apis/upload'
import { useConfirm } from 'src/hooks/use-confirm'
import SappModal from '../../SappModal'
import './ModalUploadFile.scss'
import Resources from './Resources'
import UploadFileHandle from './UploadFileHandle'
import { IResource, RESOURCE_LOCATION, UPLOAD_TYPE } from './UploadFileInterface'

interface IModalUploadProps {
  open: boolean
  setOpen: any
  setSelectedFile?: any
  setUnSelectedFile?: any
  fileType: keyof typeof UPLOAD_TYPE
  isMultiple?: boolean
  resourceLocation: RESOURCE_LOCATION
  fileChecked?: any
  onlyTab?: 'UPLOAD_FILE' | 'RESOURCES'
  title?: string
  getDefaultChecked?: (resources: IResource[]) => IResource[]
  customValidate?: (file: UploadFile<any>, fileList: UploadFile<any>[], index: number) => boolean
  parentId?: string
  maxCount?: number
  submitButtonTitle?: string
  object_id?: string | null | undefined
  setDataFile?: Dispatch<SetStateAction<any>> | undefined
  is_public?: boolean
  is_resource?: boolean
  is_grading?: boolean
}

/**
 * Thông tin về tệp đang được xử lý.
 */
interface FileInformation {
  originFileObj?: File
  uid: string
}

/**
 * Tham số cho cuộc gọi API pre-upload.
 */
interface ResponsePreUploadParams {
  content_type: string
  name: string
  location: string
  size: string
  source: CancelTokenSource
  display_name: string
  parent_id?: string
  is_public?: boolean
  is_grading?: boolean
}

export const initCompleteModal = {
  open: false,
  errorRows: 0,
  totalRows: 0,
  fileName: '',
}

const ModalUploadFile = ({
  open,
  setOpen,
  setSelectedFile,
  setUnSelectedFile,
  fileType,
  isMultiple,
  resourceLocation,
  fileChecked,
  onlyTab,
  title,
  getDefaultChecked,
  customValidate,
  parentId,
  maxCount,
  submitButtonTitle = 'Save',
  object_id,
  setDataFile,
  is_public,
  is_resource = true,
  is_grading = false,
}: IModalUploadProps) => {
  const sourceRef = useRef<CancelTokenSource>()
  const isCancel = useRef<boolean>()
  const [uploadFile, setUploadFile] = useState<UploadFile[] | undefined>()

  const [progress, setProgress] = useState<{ [key: string]: number }>({})
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false)

  const [fileResource, setFileResource] = useState<{
    listDataChecked: IResource[] | IResource
    unCheckedListData?: IResource[]
  }>()

  const [tab, setTab] = useState<'UPLOAD_FILE' | 'RESOURCES'>(onlyTab || 'UPLOAD_FILE')

  const responseUploadedFiles: any[] = []

  const { confirm, contextHolder } = useConfirm()

  const handleCancel = (closeModal: boolean = true, removedUid?: string) => {
    if (sourceRef.current) {
      sourceRef.current.cancel()
      isCancel.current = true
    }
    if (removedUid) {
      setUploadFile((e) => e?.filter((f) => f.uid !== removedUid))
      setProgress((e) => {
        delete e[removedUid]
        return e
      })
    } else {
      setUploadFile(undefined)
      setProgress({})
    }

    if (closeModal) {
      setOpen(false)
      setTab('UPLOAD_FILE')
    }
  }

  useEffect(() => {
    return () => {
      handleCancel()
    }
  }, [])

  const getProgress = (percent: number, index: string) => {
    if (percent < 7) {
      percent = 7
    }

    setProgress((progress) => {
      return { ...progress, [index]: percent }
    })
  }

  /**
   * Xử lý quá trình tải lên tệp.
   *
   * @param u - Thông tin về tệp.
   * @param currentIndex - Chỉ số của tệp hiện tại đang được xử lý.
   * @param responsePreUpload - Phản hồi từ cuộc gọi API preUpload.
   * @param saveLocation - Vị trí lưu trữ tệp sẽ được lưu.
   * @param source - Axios CancelTokenSource để hủy tải lên.
   */
  const processFileUpload = async (
    u: FileInformation,
    currentIndex: number,
    responsePreUpload: any, // Điều chỉnh kiểu nếu cần thiết
    saveLocation: any, // Điều chỉnh kiểu nếu cần thiết
    source: CancelTokenSource
  ) => {
    // Cập nhật trạng thái tệp đang được tải lên
    setUploadFile((e: any) => {
      const updatedFiles = [...(e || [])]
      updatedFiles[currentIndex] = { ...e[currentIndex], status: 'uploading' }
      return updatedFiles
    })

    // Thiết lập trạng thái loading và reset tiến trình
    setLoadingUpload(true)
    setProgress({})

    try {
      // Lấy tiến trình tải lên
      getProgress(7, u.uid)

      // Kiểm tra xem tệp có tồn tại không
      if (!u.originFileObj) {
        return
      }

      // Gọi API để bắt đầu quá trình tải lên
      const response = await UploadAPI.startUpload({
        content_type: u.originFileObj.type,
        blob: u.originFileObj,
        size: u.originFileObj.size?.toString() || '',
        description: '',
        name: u.originFileObj.name || 'undefined',
        source,
        getProgress: (percent: number) => getProgress(percent, u.uid),
        responsePreUpload,
        saveLocation,
        is_grading,
      })

      setDataFile && setDataFile((prevData: any) => [...prevData, response])
      // Xử lý phản hồi từ API tải lên
      if (response) {
        responseUploadedFiles.push(response)
        setUploadFile((e: any) => {
          const updatedFiles = [...e]
          updatedFiles[currentIndex] = {
            ...e[currentIndex],
            status: 'done',
            id: response.id,
          }
          return updatedFiles
        })
      }
    } catch (uploadError) {
      // Xử lý lỗi tải lên
      if (axios.isCancel(uploadError)) {
        toast.success('Upload has been canceled successfully!')
        return
      }

      setUploadFile((e: any) => {
        const updatedFiles = [...e]
        updatedFiles[currentIndex] = { ...e[currentIndex], status: 'error' }
        return updatedFiles
      })

      toast.error('An error occurred during the upload process!')
    } finally {
      // Kết thúc quá trình loading
      setLoadingUpload(false)

      // Nếu là tệp cuối cùng, đặt timeout để kết thúc quá trình
      if (currentIndex + 1 === (uploadFile?.length || 0)) {
        setSelectedFile && setSelectedFile(responseUploadedFiles, 'upload')
        toast.success('Upload completed!')
        setTimeout(() => {
          handleCancel()
          setLoadingUpload(false)
        }, 1000)
      }

      // Nếu chưa phải là tệp cuối cùng, tiếp tục tải lên tệp tiếp theo
      if (currentIndex + 1 < (uploadFile?.length || 0)) {
        handleUploadFile(currentIndex + 1)
      }
    }
  }

  /**
   * Xử lý việc tải lên các tệp.
   *
   * @param currentIndex - Chỉ số của tệp hiện tại đang được xử lý.
   */
  const handleUploadFile = async (currentIndex: number = 0) => {
    // Nếu là tệp cuối cùng, đặt timeout để kết thúc quá trình
    if (currentIndex && currentIndex >= (uploadFile?.length || 0)) {
      // Cập nhật danh sách tệp đã tải lên và thông báo thành công
      setSelectedFile && setSelectedFile(responseUploadedFiles, 'upload')
      setTimeout(() => {
        handleCancel()
        setLoadingUpload(false)
      }, 1000)
      return
    }
    // Kiểm tra nếu không có tệp
    if (!uploadFile?.length) {
      toast.error(
        `Before uploading, please choose the file in the format of ${UPLOAD_TYPE[fileType].extension}.`
      )
      return
    }

    // Thiết lập CancelTokenSource và biến theo dõi việc hủy
    sourceRef.current = axios.CancelToken.source()
    isCancel.current = false

    try {
      // Lấy thông tin về tệp hiện tại
      const u = uploadFile[currentIndex]
      // Kiểm tra xem tệp có tồn tại không
      if (!u || !u.originFileObj) {
        // Nếu không có tệp, chuyển sang xử lý tệp tiếp theo
        handleUploadFile(currentIndex + 1)
        return
      }

      // Thiết lập source để tải lên và lấy vị trí lưu trữ
      const source = sourceRef.current || axios.CancelToken.source()
      const saveLocation = await UploadAPI.prepareSaveLocation({
        resourceLocation,
        parentId,
        source,
        object_id: object_id ?? undefined,
      })

      // Thiết lập thông tin pre-upload
      const responsePreUploadParams: ResponsePreUploadParams = {
        is_public,
        content_type: u.originFileObj.type,
        name: (u.originFileObj.name || 'undefined' || '').split('.').slice(0, -1).join('.'),
        location: saveLocation?.location || '',
        size: u.originFileObj.size?.toString() || '',
        source,
        display_name: u.originFileObj.name || 'undefined' || '',
        is_grading: is_grading,
        ...(parentId === 'null' ? {} : { parent_id: parentId }),
      }

      try {
        // Gọi API pre-upload
        const responsePreUpload = await UploadAPI.preUpload(responsePreUploadParams)

        // Xử lý quá trình tải lên
        await processFileUpload(u, currentIndex, responsePreUpload, saveLocation, source)
      } catch (preUploadError: any) {
        // Xử lý lỗi pre-upload
        if (axios.isCancel(preUploadError)) {
          toast.success('Upload has been canceled successfully!')
          handleUploadFile(currentIndex + 1)
          return
        }

        if (preUploadError?.response?.data?.error?.code !== '400|150001') {
          setUploadFile((e: any) => {
            const updatedFiles = [...e]
            updatedFiles[currentIndex] = { ...e[currentIndex], status: 'error' }
            return updatedFiles
          })
          handleUploadFile(currentIndex + 1)
          return
        }

        // Hiển thị cảnh báo và xác nhận đổi tên nếu cần
        confirm({
          okButtonCaption: 'Yes',
          cancelButtonCaption: 'No',
          body: `${
            u.originFileObj.name || 'File'
          } already exists. Do you want to upload this file?`,
          onClick: async () => {
            if (!u.originFileObj) {
              return
            }

            try {
              // Gọi lại pre-upload với exist_type là 'BOTH'
              const responsePreUpload = await UploadAPI.preUpload({
                ...responsePreUploadParams,
                exist_type: 'BOTH',
              })

              // Xử lý quá trình tải lên
              await processFileUpload(u, currentIndex, responsePreUpload, saveLocation, source)
            } catch (retryError) {
              // Xử lý lỗi khi thử lại tải lên
              if (axios.isCancel(retryError)) {
                toast.success('Upload has been canceled successfully!')
                return
              }

              setUploadFile((e: any) => {
                const updatedFiles = [...e]
                updatedFiles[currentIndex] = { ...e[currentIndex], status: 'error' }
                return updatedFiles
              })
            }
          },
          onClose: () => {
            // Đặt trạng thái lỗi và xử lý tệp tiếp theo
            setUploadFile((e: any) => {
              const updatedFiles = [...e]
              updatedFiles[currentIndex] = { ...e[currentIndex], status: 'error' }
              return updatedFiles
            })
            handleUploadFile(currentIndex + 1)
          },
        })
        return
      }
    } catch (uploadFileError) {
      // Xử lý lỗi khi tải lên tệp
      if (axios.isCancel(uploadFileError)) {
        toast.success('Upload has been canceled successfully!')
        return
      }

      setUploadFile((e: any) => ({ ...e, status: 'error' }))
      toast.error('An error occurred during the upload process!')
    }
  }

  const handleAddFile = () => {
    if (!fileResource) {
      toast.error(`Please select the file in resources.`)
      return
    }
    if (Array.isArray(fileResource.listDataChecked) && fileResource.listDataChecked.length <= 0) {
      toast.error(`Please select the file in resources.`)
      return
    }
    setSelectedFile && setSelectedFile(fileResource.listDataChecked, 'resources')
    setUnSelectedFile && setUnSelectedFile(fileResource.unCheckedListData || [], 'resources')
    setOpen(false)
    setTab('UPLOAD_FILE')
  }

  return (
    <div>
      {contextHolder}
      <SappModal
        open={open}
        title={title || `Add ${UPLOAD_TYPE[fileType].type.toLocaleLowerCase()}`}
        cancelButtonCaption='Cancel'
        okButtonCaption={submitButtonTitle}
        dialogClassName={`${
          tab === 'UPLOAD_FILE' ? 'sapp-mw-900px' : 'sapp-mw-1200px'
        }  modal-dialog-centered ${!onlyTab ? 'sapp-modal_upload-file' : ''} `}
        confirmOnclose
        handleClose={handleCancel}
        handleSubmit={tab === 'UPLOAD_FILE' ? handleUploadFile : handleAddFile}
        loading={tab === 'UPLOAD_FILE' ? loadingUpload : false}
        {...(!onlyTab && {
          customTitle: (title) => (
            <div>
              {/* <div className='mb-10 text-capitalize fw-bold fs-3'>{title}</div> */}
              <div className='d-flex column-gap-6'>
                <div
                  className={`fs-4 pb-7 sapp-modal_upload-file_tab_title ${
                    tab === 'UPLOAD_FILE' ? '_checked' : 'text-gray-500'
                  } `}
                  onClick={() => setTab('UPLOAD_FILE')}
                >
                  Upload File
                </div>
                {is_resource && (
                  <div
                    className={`fs-4 pb-7 sapp-modal_upload-file_tab_title ${
                      tab === 'RESOURCES' ? '_checked' : 'text-gray-500'
                    } `}
                    onClick={() => setTab('RESOURCES')}
                  >
                    Resources
                  </div>
                )}
              </div>
            </div>
          ),
        })}
        showIconDelete={false}
        footerEnd
        // classBody='p-7'
        classNameContent='sapp-p-content'
      >
        <div className={tab === 'UPLOAD_FILE' ? '' : 'd-none'}>
          <UploadFileHandle
            uploadFile={uploadFile}
            setUploadFile={setUploadFile}
            progress={progress}
            loading={loadingUpload}
            handleCancel={handleCancel}
            fileType={fileType}
            icon={UPLOAD_TYPE[fileType]?.icon}
            isMultiple={isMultiple}
            customValidate={customValidate}
            maxCount={maxCount}
          ></UploadFileHandle>
        </div>
        {!is_grading && (
          <div className={tab === 'RESOURCES' ? '' : 'd-none'}>
            <Resources
              setResource={(e) => {
                setFileResource(e)
              }}
              fileType={fileType}
              isMultiple={isMultiple}
              fileChecked={fileChecked}
              getDefaultChecked={getDefaultChecked}
            ></Resources>
          </div>
        )}
      </SappModal>
    </div>
  )
}
export default ModalUploadFile
