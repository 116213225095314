import {PageLink} from 'src/constants'
import {UserExportHelper} from 'src/helper/export'
import {useEffect, useState} from 'react'
import ModalImportStaff from '../ModalImportStaff'
import SAPPActionButton from 'src/common/SAPPActionButton'
import {useNavigate} from 'react-router-dom'

interface IProps {
  valueStatus: string | null
  valueSortBy: string | null
  searchTerm: string
  sortRole: string | null
  fromDate: Date | null
  toDate: Date | null
  gender: string | null
  location?: any
}

const StaffListToolbar = ({
  searchTerm,
  valueSortBy,
  valueStatus,
  sortRole,
  fromDate,
  toDate,
  gender,
  location,
}: IProps) => {
  const [openUpload, setOpenUpload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLocation, setIsLocation] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    setIsLocation(location.pathname)
  }, [location.pathname])

  const exportStaff = async () => {
    setLoading(true)
    try {
      await UserExportHelper.exportToXlsxStaff(
        valueStatus,
        valueSortBy,
        searchTerm,
        sortRole,
        fromDate,
        toDate,
        gender
      )
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const handleAddStaff = () => {
    navigate(PageLink.CREATE_STAFF)
  }

  return (
    <>
      <SAPPActionButton
        onClick={exportStaff}
        setOpen={setOpenUpload}
        onClickAdd={handleAddStaff}
        titleAdd='Create'
        loading={loading}
        location={isLocation}
      />
      <ModalImportStaff open={openUpload} setOpen={setOpenUpload} />
    </>
  )
}

export {StaffListToolbar}
