import { Col, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useConfirm } from 'src/hooks/use-confirm'
import { PageLink, VALIDATE_NUMBER, VALIDATE_PASS_POINT, VALIDATION_FILED } from 'src/constants'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import { toast } from 'react-hot-toast'
import { EntranceApi } from 'src/apis/entrance-test'
import { IETest, Section, SettingCategory } from 'src/type/entrancetest'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import MenuTabsSection from './create/MenuTabsSection'
import SettingEntrance from './SettingEntrance'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { TITLE_OPTIONS_ENTRANCE } from 'src/constants/entrancetest'

const Settings = ({
  listInfo,
  setStep,
  step,
}: {
  listInfo: IETest | undefined
  setStep?: any
  step?: any
}) => {
  const { id } = useParams()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)

  const validationSettingSectionSchema = z
    .object({
      setting: z.array(
        z.object({
          pass_point: z
            .string({ required_error: VALIDATION_FILED })
            .regex(/^[0-9]*$/, VALIDATE_NUMBER)
            .transform((val) => parseInt(val))
            .refine((val) => val >= 1, { message: VALIDATE_PASS_POINT })
            .refine((val) => val <= 100, { message: VALIDATE_PASS_POINT })
            .or(z.number().int(VALIDATE_NUMBER).min(1).max(100)),
          pass_note: z.string({ required_error: VALIDATION_FILED }).min(1),
          failure_note: z.string({ required_error: VALIDATION_FILED }).min(1),
        })
      ),
    })
    .optional()

  const {
    handleSubmit: handleSubmitSettingSection,
    control: controlSettingSection,
    setValue: setValueSettingSection,
  } = useForm({
    resolver: zodResolver(validationSettingSectionSchema),
  })

  const {} = useForm()
  const [sections, setSections] = useState<Section[]>([])
  const [listSectionSetting, setListSectionSetting] = useState<SettingCategory[]>([])
  // const [quizId, setQuizId] = useState<any>('')
  const [indexSetting, setIndexSetting] = useState<number>(-1)
  // const [editMode, setEditMode] = useState(true)

  const fetchListSection = async (id: string | undefined) => {
    setLoading(true)
    try {
      if (id) {
        const response = await EntranceApi.getListSection(id)
        setSections(response.data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const fetchListSectionSetting = async (id: string | undefined) => {
    try {
      if (id) {
        const response = await EntranceApi.getListSettingSection(id)
        setListSectionSetting(response.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (id) {
      fetchListSection(id)
      fetchListSectionSetting(id)
      // setEditMode(false)
    }
  }, [id])

  // useEffect(() => {
  //   setQuizId(id)
  // }, [])

  const [showLabel, setShowLabel] = useState(false)
  const handleToggleLabel = () => {
    setShowLabel(!showLabel)
  }

  const handleCancelUpdateSetting = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        fetchListSectionSetting(id)
        setIndexSetting(-1)
      },
    })
  }

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  // const [fileEditorNotPass, setFileEditorNotPass] = useState<Array<{id: string}>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  // const textEditorFilesNotPass =
  //   fileEditorNotPass?.map((data) => {
  //     return {id: data?.id}
  //   }) || []

  const submitSetting = async (setting: any, id: string) => {
    const submitSection = setting
    const passPointNumber = parseInt(submitSection.pass_point, 10)
    const passPoint = isNaN(passPointNumber) ? 0 : passPointNumber
    let settingSection: any = {
      pass_point: passPoint,
      pass_note: submitSection.pass_note,
      failure_note: submitSection.failure_note,
      text_editor_files: textEditorFiles,
    }
    const res = await EntranceApi.UpdateSettingSection({ id, data: { ...settingSection } })
    if (res) {
      toast.success('Setting saved successfully!')
      setIndexSetting(-1)
    }
  }

  // const onEditSetting = (e: number) => setIndexSetting(e)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const onEditSection = (e: number) => {
    setIsEdit(!isEdit)
    setIndexSetting(e)
  }

  // const HandleEditSections = (indexSection: any) => {
  //   setIndexSetting(indexSection)
  // }

  const [categoryState, setCategorySate] = useState<any>()

  const navigate = useNavigate()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        navigate(PageLink.ENTRANCE_TEST_LIST)
      },
    })
  }

  return (
    <>
      <Processbar
        step={step}
        setNewStep={setStep}
        onCancel={handleCancel}
        onClick={() => navigate(PageLink.ENTRANCE_TEST_LIST)}
        cancelButtonCaption={TITLE_OPTIONS_ENTRANCE.listEntrance}
        className='sapp-mw-700px'
      />
      <div className='px-6 sapp-pb-24px'>
        {contextHolder}
        {listSectionSetting.length > 0 && (
          <>
            {listSectionSetting.map((setting: SettingCategory, idx) => {
              return (
                <div key={setting.id}>
                  <Row>
                    <Col>
                      <MenuTabsSection
                        index={idx}
                        toggleLabelCallback={handleToggleLabel}
                        confirm={confirm}
                        typeofSection={setting.question_category.name}
                        SectionName={setting.question_category.name}
                        onDeleteSection={() => {}}
                        setEditAble={onEditSection}
                        isEdit={indexSetting !== idx}
                        id={listSectionSetting[idx]?.id}
                        setCategorySate={setCategorySate}
                        hiddenDelete
                        tabs={[
                          {
                            id: 'tab1',
                            title: setting.question_category.name,
                            content: (
                              <SettingEntrance
                                control={controlSettingSection}
                                editMode={indexSetting !== idx}
                                index={idx}
                                setting={listSectionSetting[idx]}
                                setValue={setValueSettingSection}
                                id={listSectionSetting[idx]?.id}
                                setFileEditor={setFileEditor}
                                setFileEditorNotPass={setFileEditor}
                                listInfo={listInfo?.state?.condition}
                              />
                            ),
                          },
                        ]}
                        loading={loading}
                        isOnUsed={false}
                        setAddSection={undefined}
                        categoryState={categoryState}
                        section={undefined}
                        setValue={setValueSettingSection}
                      >
                        {indexSetting === idx && (
                          <div className='d-flex border-0 justify-content-end sapp-pt-24px'>
                            <SAPPDialogButtonsCancelSubmit
                              type='button'
                              cancelClick={handleCancelUpdateSetting}
                              cancelButtonCaption='Cancel'
                              okButtonCaption='Save'
                              okOnClick={handleSubmitSettingSection((setting) => {
                                submitSetting(setting.setting[idx], listSectionSetting[idx].id)
                              })}
                              className='justify-content-between d-flex'
                              loading={loading}
                            />
                          </div>
                        )}
                      </MenuTabsSection>
                    </Col>
                  </Row>
                </div>
              )
            })}
          </>
        )}
      </div>
    </>
  )
}

export default Settings
