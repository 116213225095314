import EditorReader from 'src/components/base/editor/EditorReader'
import GradeIcon from '../GradeIcon'
import { GradingDetail, GradingStatus } from 'src/type/grade'
import { COMMENTS } from 'src/constants/grade'
import { PREFIX, formatGrade } from 'src/utils/grade'

interface DetailProps {
  item: GradingDetail
  onEdit: (item: GradingDetail) => void
  onDelete: (item: GradingDetail) => void
  gradeStatus: string
  index: string
}

const Detail = ({ item, onEdit, onDelete, gradeStatus, index }: DetailProps) => {
  const handleScrollToHighlight = (e: React.MouseEvent) => {
    const begin = e?.currentTarget.getAttribute('data-begin')
    const target = e?.currentTarget
    if (begin === '0') {
      const downloadElement = document.querySelector(
        `#topic_aswer_file_${index} .sapp-download-file`
      )
      if (downloadElement) {
        downloadElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    } else if (begin) {
      const spanId = `#${PREFIX}${begin}`
      const spanElement = document.querySelector(`#editor_area_${index} ${spanId}`)
      if (spanElement) {
        spanElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }

    setTimeout(() => {
      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }, 1000)
  }

  return (
    <div
      className='grade-comment-detail comment-grade'
      key={Number(item?.begin) + Number(item?.end)}
      data-begin={item?.begin}
      data-end={item?.end}
      onClick={handleScrollToHighlight}
      tabIndex={0}
    >
      {item.comment && (
        <div className='comment'>
          <label>{COMMENTS.Comment}:</label>
          <EditorReader className='content overflow-auto' text_editor_content={item?.comment} />
        </div>
      )}
      {item?.grade || item?.grade === 0 ? (
        <label className='grade overflow-auto'>
          {COMMENTS.Grade}: <span>{formatGrade(item?.grade)}</span>
        </label>
      ) : (
        <></>
      )}
      {gradeStatus !== GradingStatus.FINISHED && (
        <div className='edit-delete'>
          <div className='edit' onClick={() => onEdit(item)}>
            <GradeIcon iconType='notepad-edit' />
          </div>
          <div className='delete' onClick={() => onDelete(item)}>
            <GradeIcon iconType='trash' />
          </div>
        </div>
      )}
    </div>
  )
}

export default Detail
