export const CLASS_USER_TYPES = {
  NORMAL: 'NORMAL', // Bình thường
  RESERVED: 'RESERVED', // Bảo lưu
  REASSIGNED: 'REASSIGNED', // Học lại vì bảo lưu
  RETOOK: 'RETOOK', // Trượt
  RETAKING: 'RETAKING', // Học lại vì trượt
  MOVED_OUT: 'MOVED_OUT', // Chuyển ra khỏi lớp
  MOVED_IN: 'MOVED_IN', // Chuyển vào lớp
  TRANSFERED_TO: 'TRANSFERED_TO', // Đã chuyển nhượng
  TRANSFERRED: 'BE_TRANSFERRED', // Được chuyển nhượng
  CANCELED: 'CANCELED', // Hủy học
}

export const CLASS_USER_TYPE_BADGE = {
  // NORMAL: {
  //   label: 'Bình thường',
  //   color: '',
  // },
  RESERVED: {
    label: 'Bảo lưu',
    color: 'reserved',
  },
  REASSIGNED: {
    label: 'Học lại vì bảo lưu',
    color: 'reassigned',
  },
  RETOOK: {
    label: 'Trượt',
    color: '',
  },
  RETAKING: {
    label: 'Học lại vì trượt',
    color: 'reassigned',
  },
  MOVED_OUT: {
    label: 'Chuyển ra khỏi lớp',
    color: '',
  },
  MOVED_IN: {
    label: 'MOVED_IN',
    color: '',
  },
  TRANSFERED_TO: {
    label: 'Chuyển nhượng',
    color: 'transfered',
  },
  BE_TRANSFERRED: {
    label: 'Được chuyển nhượng',
    color: 'transfered',
  },
  CANCELED: {
    label: 'Hủy học',
    color: '',
  },
}
