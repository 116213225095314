import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { KTCardBody } from 'src/_metronic/helpers'
import { CommentAPI } from 'src/apis/comments'
import LoadingTable from 'src/common/LoadingTable'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import SappTable from 'src/components/base/SappTable'
import ActionCell from 'src/components/base/action/ActionCell'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { MOCKUP_HEADER, PageLink } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { ISupportGroupList } from 'src/type/comment'
import { cleanParamsAPI } from 'src/utils'

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  groupList: ISupportGroupList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: (
    text: string,
    sort_type: string,
    course_category_id: string,
    subject_id: string,
    department_id: string,
    fromDate?: string | Date,
    toDate?: string | Date,
    dateField?: string
  ) => {
    text: string
    course_category_id: string
    subject_id: string
    department_id: string
    sort_type: string
    fromDate?: string | Date
    toDate?: string | Date
    dateField?: string
  }
  queryParams: {
    text: string
    sort_type: string
    course_category_id: string
    subject_id: string
    department_id: string
    page_index: number
    page_size: number
    fromDate?: string | Date
    toDate?: string | Date
  }
  checkedList: string[]
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, memorize?: boolean) => void
  isCheckedAll: boolean
}

const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Group name',
    className: 'min-w-275px pe-5 ',
  },
  {
    label: 'Program',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Subject',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Department',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Suppoters',
    className: 'min-w-50px pe-5',
  },
  {
    label: 'Date',
    className: 'min-w-150px pe-5',
  },
]

const SupporterGroupTable = ({
  fetchList,
  filterParams,
  getParams,
  isCheckedAll,
  loading,
  groupList,
  queryParams,
  setLoading,
  toggleCheckAll,
  handleChangeParams,
}: IProps) => {
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.text?.trim() ?? '',
      queryParams.sort_type ?? '',
      queryParams.course_category_id ?? '',
      queryParams.subject_id ?? '',
      queryParams.department_id ?? '',
      queryParams.fromDate ?? '',
      queryParams.toDate ?? ''
    )
  )

  const { setValue } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    setLoading(true)
    fetchList(page_index || 1, page_size || 10, filterParams)
  }, [])

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchList(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  const handleDeleteConfirm = (id?: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        try {
          setLoading(true)
          await CommentAPI.deleteSupportGroup(id ?? '')
          toast.success('Delete Supporter Group successfully !')
          fetchList(1, 10)
        } catch (error) {}
      },
    })
  }

  const handleEdit = (id: string) => {
    navigate(`/support-group/${id}`)
  }

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={groupList?.data || []}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((header, i) => (
              <LoadingTable key={header} headers={headers} />
            ))}
          </>
        ) : (
          <>
            {groupList?.data?.map((group, index: number) => {
              return (
                <tr key={group.id}>
                  <td>
                    {index +
                      1 +
                      (groupList?.metadata?.page_index - 1) * groupList?.metadata?.page_size}
                  </td>
                  <td className='text-start'>
                    <Link
                      className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                      to={`${PageLink.LIST_SUPPORTER_GROUP}/${group.id}`}
                    >
                      {group?.name}
                    </Link>
                  </td>
                  <td className='text-start'>{group?.course_category?.name ?? '--'}</td>
                  <td className='text-start'>{group?.subject?.name ?? '--'}</td>
                  <td className='text-start'>{group?.department?.name ?? '--'}</td>
                  <td className='text-start'>{group?.supporters?.length ?? 0}</td>
                  <td className='text-start'>
                    <DatetimeColumn updated_at={group?.updated_at} created_at={group?.created_at} />
                  </td>
                  <td className='text-end sapp-absolute-column'>
                    <ActionCell customWidth='w-150px'>
                      <div className='menu-item px-3' onClick={() => handleEdit(group.id)}>
                        <div className='menu-link px-3'>Edit</div>
                      </div>
                      <div className='menu-item px-3' onClick={() => handleDeleteConfirm(group.id)}>
                        <div className='menu-link px-3'>Delete</div>
                      </div>
                    </ActionCell>
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>

      <PagiantionSAPP
        currentPage={groupList?.metadata?.page_index || 1}
        pageSize={groupList?.metadata?.page_size || 10}
        totalItems={groupList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export { SupporterGroupTable }
