import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import PageLayouts from 'src/components/layout/PageLayouts'
import { ITabs } from 'src/type'
import { replaceValueAll } from 'src/utils/string'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import useChecked from 'src/hooks/use-checked'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import { EntranceApi } from 'src/apis/entrance-test'
import { TITLE_OPTIONS_ENTRANCE } from 'src/constants/entrancetest'
import { FILTER_SELECTALL_SORTBY, PageLink, SORT_BY } from 'src/constants'
import { LANG_ENTRANCE_TEST, LANG_SIDEBAR } from 'src/constants/lang'
import { z } from 'zod'
import ParticipantTable from 'src/pages/entrance-test/participant-management/ParticipantTable'
import { IETest, IParticipantInTest, IParticipantInTestList } from 'src/type/entrancetest'
import {
  PARTICIPANT_LEVEL,
  PARTICIPANT_SOURCE,
  PARTICIPANT_STATUS,
} from 'src/components/courses/shared/consts'
import AddParticipant from '../../../components/entrance-test/participant/AddParticipant'
import ImportUploadModal from 'src/components/base/ImportUploadModal'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: 'Entrance Tests',
  },
  {
    link: '',
    title: TITLE_OPTIONS_ENTRANCE.participantList,
  },
]
const fieldNames = ['text', 'status', 'level', 'source', 'sortType', 'fromDate', 'toDate']
const initialValues = {
  text: '',
  status: '',
  level: '',
  source: '',
  sortType: '',
  fromDate: '',
  toDate: '',
}
const ParticipantEntranceTest = () => {
  const { id } = useParams()
  const [participantList, setParticipantList] = useState<IParticipantInTestList>()
  const [open, setOpen] = useState<boolean>(false)
  const [openImport, setOpenImport] = useState<boolean>(false)
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(search)
  const [dateField, setDateField] = useState<string>('updated_at')
  const [entranceTest, setEntranceTest] = useState<IETest>()

  const queryParams = {
    text: searchParams.get('text') ?? '',
    status: searchParams.get('status') ?? '',
    level: searchParams.get('level') ?? '',
    source: searchParams.get('source') ?? '',
    sortType: searchParams.get('sortType') as string,
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,

    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const validationSchema = z.object({
    text: z.string().optional(),
    status: z.string().optional(),
    level: z.string().optional(),
    source: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  const { control, getValues, reset, setValue } = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      text: getValues('text') ?? '',
      status: replaceValueAll(getValues('status')) ?? '',
      level: replaceValueAll(getValues('level')) ?? '',
      source: replaceValueAll(getValues('source')) ?? '',
      sortType: replaceValueAll(getValues('sortType')) ?? '',
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      page_index: currenPage,
      page_size: size,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    text: queryParams.text?.trim() ?? '',
    status: queryParams.status ?? '',
    level: queryParams.level ?? '',
    source: queryParams.source ?? '',
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
  })

  const getParams = (
    text: string,
    status: string,
    level: string,
    source: string,
    sortType: string,
    fromDate?: string | Date,
    toDate?: string | Date
  ) => ({
    text,
    status,
    level,
    source,
    sortType,
    fromDate,
    toDate,
    dateField,
  })

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<IParticipantInTest>(
    participantList?.data
  )

  const getQuiz = async () => {
    if (!id) return
    const res = await EntranceApi.getEntranceTestDetail(id)
    setEntranceTest(res.data)
  }

  const fetchParticipantList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      if (!id) return
      const res = await EntranceApi.getParticipantEntranceTest({
        id,
        page_index: currentPage || 1,
        page_size: pageSize || 10,
        params: {
          ...(params ?? {}),
          filter_type: 'SELECTED',
        },
      })
      setParticipantList(res.data)
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  /**
   * handle Filter By params
   */
  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('text') ?? '',
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('level')),
        replaceValueAll(getValues('source')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : ''
      )
    )

    setLoading(true)
    fetchParticipantList(1, queryParams.page_size || 10, cleanedParams)
    handleChangeParams(1, queryParams.page_size || 10)
  }
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName as keyof typeof initialValues])
    })
    setDateField('')
    setLoading(true)
    fetchParticipantList(1, 10)
  }

  useEffect(() => {
    getQuiz()
    fetchParticipantList(1, 10)
  }, [])

  return (
    <PageLayouts
      pageTitle={`Participant List of ${entranceTest?.name ?? ''}`}
      breadcrumbs={breadcrumbs}
    >
      <div className='card'>
        <div className='px-9 border-0 pt-6'>
          <div className=''>
            <div className='row'>
              {/* begin:: Search */}
              <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
                <div className='card-title justify-content-center mx-0'>
                  <HookFormTextField
                    control={control}
                    name='text'
                    placeholder='Search participant name'
                    defaultValue={queryParams?.text}
                    onSubmit={onSubmit}
                    isListScreen
                  />
                </div>
              </div>
              <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
                <HookFormSelectAntd
                  name='status'
                  placeholder='Status'
                  control={control}
                  defaultValue={queryParams?.status ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {PARTICIPANT_STATUS.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
                <HookFormSelectAntd
                  name='level'
                  placeholder='Level'
                  control={control}
                  defaultValue={queryParams?.level ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {PARTICIPANT_LEVEL.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
                <HookFormSelectAntd
                  name='source'
                  placeholder='Source'
                  control={control}
                  defaultValue={queryParams?.source ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {PARTICIPANT_SOURCE.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  name='sortType'
                  placeholder={SORT_BY}
                  control={control}
                  defaultValue={queryParams?.sortType ?? ''}
                  classNameHeight='sapp-h-40'
                >
                  {FILTER_SELECTALL_SORTBY.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParams?.fromDate}
                  isListScreen
                />
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParams?.toDate}
                  isListScreen
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6 sapp-mh-auto w-100'>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
            <div className='d-flex gap-3'>
              <ButtonIconPrimary
                iconName='file-up'
                title='Import'
                onClick={() => setOpenImport(true)}
                size='small'
                iconType='outline'
              />
              <ButtonIconPrimary
                iconName='plus'
                onClick={() => setOpen(true)}
                title={LANG_ENTRANCE_TEST.addParticipant}
                size='small'
              />
            </div>
          </div>
        </div>
        <ParticipantTable
          participantList={participantList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchParticipantList={fetchParticipantList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
        <ImportUploadModal
          open={openImport}
          setOpen={setOpenImport}
          quizId={id ?? ''}
          title='Import Participant'
          templateFile='./ENTRANCE_PARTICIPANT_IMPORT_TEMPLATE.xlsx'
          type='TEST_PARTICIPANT'
        ></ImportUploadModal>
        <AddParticipant
          open={open}
          setOpen={setOpen}
          id={id ?? ''}
          fetchParticipantPage={() => fetchParticipantList(1, 10)}
        />
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(ParticipantEntranceTest)
