import { useUserContext } from 'src/context/UserProvider'
import ActionCellEntrance from '../base/actionEntranceTest/ActionCellEntrance'
import { CODE_ADMIN, TITLE_QUIZ_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import { useNavigate } from 'react-router-dom'

interface IProps {
  Action: any
  data: any
}
const EntranceAction = ({ Action, data }: IProps) => {
  const { profileMe } = useUserContext()
  const allowRenderEditTest = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_QUIZ_GR.EDIT_QUIZ || TITLE_QUIZ_GR.PUBLIC_SCORE_DETAIL) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <>
      {allowRenderEditTest && (
        <ActionCellEntrance>
          <div className='menu-item px-3' onClick={() => Action('Edit', data)}>
            <div className='menu-link px-3'>Edit</div>
          </div>

          <div className='menu-item px-3' onClick={() => Action('participant-list', data)}>
            <div className='menu-link px-3'>Participant List</div>
          </div>

          <div className='menu-item px-3' onClick={() => Action('add', data)}>
            <div className='menu-link px-3' onClick={() => Action('add-participant', data)}>
              Add Participants
            </div>
          </div>
        </ActionCellEntrance>
      )}
    </>
  )
}
export default EntranceAction
