import React from 'react'
import toast from 'react-hot-toast'

import ModalImport from 'src/components/base/upload-file/ModalImport'

interface IModalImportStaffProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalImportClasses = ({open, setOpen}: IModalImportStaffProps) => {
  const beforeUpload = (file: File) => {
    const isExcel =
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    const isCSV = file.type === 'text/csv'
    if (!isExcel && !isCSV) {
      toast.error('You can only upload Excel (.xlsx) or CSV (.csv) files!')
      return false
    }
    if (file.size > 5 * 1024 * 1024) {
      toast.error('The file is too large! It must be less than or equal to 5 MB!')
      return false
    }
    return true
  }
  return (
    <div>
      <ModalImport
        open={open}
        setOpen={setOpen}
        title='Import class'
        templateFile='./IMPORT_CLASS_TEMPLATE.xlsx'
        templateFileName='filemau.xlsx'
        uploadUrl={`classes/import`}
        beforeUpload={(file) => beforeUpload(file)}
        type='CLASS'
      ></ModalImport>
    </div>
  )
}
export default ModalImportClasses
