import { Link } from 'react-router-dom'
import { IButtonProps } from 'src/type'

const ButtonDanger = ({
  title,
  onClick,
  className = '',
  link,
  loading,
  type = 'button',
  bg,
  disabled,
  isListScreen = false,
}: IButtonProps) => {
  if (link)
    return (
      <Link
        to={link}
        className={`${className} btn btn-sm btn-${bg ?? 'danger'} align-self-center ${
          isListScreen ? 'sapp-h-45px' : 'sapp-h-40'
        }`}
      >
        {title}
      </Link>
    )

  return (
    <button
      disabled={disabled || loading}
      type={type}
      className={`${className} btn btn-${bg ?? 'danger'} ${
        isListScreen ? 'sapp-h-45px' : 'sapp-h-40'
      }`}
      onClick={onClick}
    >
      {loading ? (
        <span className='indicator-progress d-block'>
          Please wait...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      ) : (
        <span className='indicator-label'>{title}</span>
      )}
    </button>
  )
}

export default ButtonDanger
