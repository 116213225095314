export type ICertificatesTemplateIcons =
  | 'minus'
  | 'plus'
  | 'text-color'
  | 'text-bold'
  | 'text-italic'
  | 'text-underline'
  | 'text-line-through'
  | 'upper-case'
  | 'text-align'
  | 'bullets-numbering'
  | 'line-paragraph-spacing'
  | 'animation-left'
  | 'animation-right'
  | 'opacity'
  | 'copy-style'
  | 'lock'
  | 'image'
type Props = {
  icon: ICertificatesTemplateIcons
  isOpacity?: boolean
  className?: string
  color?: string
}

const CertificatesTemplateIcons = ({icon, isOpacity, className, color}: Props) => {
  const fillColor = isOpacity ? '#0D1216' : '#24313D'
  const fillOpacity = isOpacity ? '0.4' : '1'
  return (
    <div className={className}>
      {(() => {
        switch (icon) {
          case 'minus':
            return (
              <svg
                width='13'
                height='12'
                viewBox='0 0 13 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M2.5 6C2.5 5.80109 2.57902 5.61032 2.71967 5.46967C2.86032 5.32902 3.05109 5.25 3.25 5.25H9.75C9.94891 5.25 10.1397 5.32902 10.2803 5.46967C10.421 5.61032 10.5 5.80109 10.5 6C10.5 6.19891 10.421 6.38968 10.2803 6.53033C10.1397 6.67098 9.94891 6.75 9.75 6.75H3.25C3.05109 6.75 2.86032 6.67098 2.71967 6.53033C2.57902 6.38968 2.5 6.19891 2.5 6Z'
                  fill={fillColor}
                />
              </svg>
            )
          case 'plus':
            return (
              <svg
                width='13'
                height='12'
                viewBox='0 0 13 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M5.75 9.25C5.75 9.44891 5.82902 9.63968 5.96967 9.78033C6.11032 9.92098 6.30109 10 6.5 10C6.69891 10 6.88968 9.92098 7.03033 9.78033C7.17098 9.63968 7.25 9.44891 7.25 9.25V6.75H9.75C9.94891 6.75 10.1397 6.67098 10.2803 6.53033C10.421 6.38968 10.5 6.19891 10.5 6C10.5 5.80109 10.421 5.61032 10.2803 5.46967C10.1397 5.32902 9.94891 5.25 9.75 5.25H7.25V2.75C7.25 2.55109 7.17098 2.36032 7.03033 2.21967C6.88968 2.07902 6.69891 2 6.5 2C6.30109 2 6.11032 2.07902 5.96967 2.21967C5.82902 2.36032 5.75 2.55109 5.75 2.75V5.25H3.25C3.05109 5.25 2.86032 5.32902 2.71967 5.46967C2.57902 5.61032 2.5 5.80109 2.5 6C2.5 6.19891 2.57902 6.38968 2.71967 6.53033C2.86032 6.67098 3.05109 6.75 3.25 6.75H5.75V9.25Z'
                  fill={fillColor}
                />
              </svg>
            )

          case 'text-color':
            return (
              <div>
                <svg
                  width='20'
                  height='21'
                  viewBox='0 0 20 21'
                  fill={color}
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g opacity='0.7'>
                    <rect x='0.5' y='1' width='19' height='19' rx='3.5' stroke='#212529' />
                  </g>
                </svg>

                <svg
                  width='10'
                  height='7'
                  viewBox='0 0 10 7'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='ms-2'
                >
                  <path
                    d='M5 6.50005L0 1.50005L0.7 0.800049L5 5.10005L9.3 0.800049L10 1.50005L5 6.50005Z'
                    fill='#212529'
                  />
                </svg>
              </div>
            )
          case 'text-bold':
            return (
              <svg
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M11.0801 8.71997H15.5201C17.5501 8.71997 19.0201 9.01997 19.9301 9.58997C20.8501 10.16 21.3001 11.08 21.3001 12.34C21.3001 13.19 21.1001 13.89 20.7001 14.44C20.3001 14.98 19.7701 15.31 19.1001 15.42V15.52C20.0101 15.72 20.6601 16.1 21.0601 16.65C21.4601 17.21 21.6601 17.95 21.6601 18.85C21.6601 20.16 21.1901 21.18 20.2601 21.91C19.1358 22.6958 17.7795 23.0798 16.4101 23H11.0801V8.71997ZM14.1101 14.38H15.8601C16.6801 14.38 17.2801 14.25 17.6501 14C18.0101 13.74 18.2001 13.32 18.2001 12.74C18.2001 12.19 18.0001 11.8 17.6001 11.56C17.0076 11.2807 16.3537 11.1568 15.7001 11.2H14.1001V14.38H14.1101ZM14.1101 16.78V20.5H16.0801C16.9101 20.5 17.5301 20.34 17.9201 20.02C18.3201 19.7 18.5201 19.22 18.5201 18.56C18.5201 17.37 17.6701 16.78 15.9801 16.78H14.1101Z'
                  fill={fillColor}
                  fillOpacity={fillOpacity}
                />
              </svg>
            )
          case 'text-italic':
            return (
              <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} fill='none'>
                <path
                  fill={fillColor}
                  fillOpacity={fillOpacity}
                  fillRule='evenodd'
                  d='m18.73 10.5-3.67 11H18l-.3 1.5H10l.3-1.5h2.81l3.68-11H14l.3-1.5H22l-.3 1.5h-2.97Z'
                  clipRule='evenodd'
                />
              </svg>
            )
          case 'text-underline':
            return (
              <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} fill='none'>
                <path
                  fill={fillColor}
                  fillOpacity={fillOpacity}
                  clipRule='evenodd'
                  fillRule='evenodd'
                  d='M10 25.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 1 1 0 1.5h-10.5a.75.75 0 0 1-.75-.75Zm9.754-7.244V9h1.528v8.95c0 1.574-.476 2.807-1.424 3.703-.948.896-2.253 1.347-3.92 1.347-1.667 0-2.952-.454-3.862-1.356-.904-.902-1.358-2.145-1.358-3.733V9h1.528v9.025c0 1.168.32 2.072.966 2.704.646.632 1.592.945 2.83.945 1.183 0 2.1-.313 2.746-.945.646-.638.966-1.548.966-2.723Z'
                />
              </svg>
            )
          case 'text-line-through':
            return (
              <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} fill='none'>
                <path
                  fill={fillColor}
                  fillOpacity={fillOpacity}
                  clipRule='evenodd'
                  fillRule='evenodd'
                  d='M11.35 19.508c0 1.263.43 2.249 1.291 2.957.861.708 2.01 1.063 3.445 1.063 1.436 0 2.571-.355 3.407-1.063.836-.708 1.254-1.636 1.254-2.785 0-.885-.205-1.611-.614-2.18H22V16H10.432v1.5h7.175c.388.185.688.367.9.544.492.408.737.957.737 1.646 0 .753-.27 1.362-.813 1.828-.542.46-1.324.689-2.345.689-1.02 0-1.815-.227-2.383-.68-.56-.453-.842-1.126-.842-2.019v-.23H11.35v.23ZM12.35 15h2.918c-.667-.268-1.147-.523-1.44-.765-.474-.396-.71-.916-.71-1.56 0-.715.233-1.28.7-1.694.465-.415 1.192-.622 2.181-.622.983 0 1.723.242 2.22.727.498.485.747 1.117.747 1.895v.21h1.512v-.21c0-1.148-.405-2.093-1.215-2.833-.804-.74-1.892-1.11-3.264-1.11-1.372 0-2.447.348-3.225 1.043-.772.69-1.158 1.573-1.158 2.651 0 .948.245 1.704.734 2.268Z'
                />
              </svg>
            )
          case 'upper-case':
            return (
              <svg
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill={fillColor}
                  fillOpacity={fillOpacity}
                  clipRule='evenodd'
                  fillRule='evenodd'
                  d='M12.7699 23L12.4799 21.63H12.4099C11.9299 22.23 11.4499 22.64 10.9699 22.85C10.4999 23.07 9.89988 23.18 9.17988 23.18C8.22988 23.18 7.47988 22.93 6.93988 22.44C6.39988 21.94 6.12988 21.24 6.12988 20.34C6.12988 18.39 7.67988 17.37 10.7899 17.28L12.4299 17.23V16.63C12.4299 15.87 12.2599 15.31 11.9299 14.95C11.6099 14.59 11.0899 14.41 10.3799 14.41C9.57988 14.41 8.66988 14.66 7.64988 15.15L7.20988 14.04C8.21082 13.497 9.33117 13.2118 10.4699 13.21C11.6199 13.21 12.4699 13.46 13.0199 13.97C13.5699 14.48 13.8499 15.3 13.8499 16.43V23H12.7699ZM9.46988 21.97C10.3799 21.97 11.0999 21.72 11.6099 21.22C12.1299 20.72 12.3899 20.02 12.3899 19.13V18.26L10.9299 18.32C10.065 18.2932 9.20664 18.4786 8.42988 18.86C7.90988 19.18 7.64988 19.68 7.64988 20.36C7.64988 20.88 7.80988 21.28 8.12988 21.56C8.44988 21.83 8.89988 21.97 9.46988 21.97ZM25.1499 23L23.5499 18.91H18.3999L16.8199 23H15.3099L20.3899 10.1H21.6499L26.6999 23H25.1499ZM23.0899 17.57L21.5899 13.59C21.3999 13.09 21.1999 12.46 20.9899 11.73C20.8699 12.29 20.6899 12.91 20.4399 13.59L18.9399 17.57H23.0899Z'
                />
              </svg>
            )
          case 'text-align':
            return (
              <svg xmlns='http://www.w3.org/2000/svg' width={48} height={28} fill='none'>
                <path
                  fill='#222F3E'
                  fillRule='evenodd'
                  d='M9 7h14c.6 0 1 .4 1 1s-.4 1-1 1H9a1 1 0 0 1 0-2Zm0 4h8c.6 0 1 .4 1 1s-.4 1-1 1H9a1 1 0 0 1 0-2Zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1H9a1 1 0 0 1 0-2Zm0-4h14c.6 0 1 .4 1 1s-.4 1-1 1H9a1 1 0 0 1 0-2Z'
                  clipRule='evenodd'
                />
                <g clipPath='url(#a)'>
                  <path
                    fill='#222F3E'
                    fillOpacity={0.5}
                    d='M39.7 11.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2l-4 4.4c-.3.3-.9.3-1.2 0l-4.3-4.4a.8.8 0 0 1 0-1.2c.3-.3.8-.3 1.1 0L36 15l3.7-3.8Z'
                  />
                </g>
                <defs>
                  <clipPath id='a'>
                    <path fill='#fff' d='M30.93 9h10v10h-10z' />
                  </clipPath>
                </defs>
              </svg>
            )
          case 'bullets-numbering':
            return (
              <svg
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill={fillColor}
                  fillOpacity={fillOpacity}
                  clipRule='evenodd'
                  fillRule='evenodd'
                  d='M15.75 9.25H23.25C23.4489 9.25 23.6397 9.32902 23.7803 9.46967C23.921 9.61032 24 9.80109 24 10C24 10.1989 23.921 10.3897 23.7803 10.5303C23.6397 10.671 23.4489 10.75 23.25 10.75H15.75C15.5511 10.75 15.3603 10.671 15.2197 10.5303C15.079 10.3897 15 10.1989 15 10C15 9.80109 15.079 9.61032 15.2197 9.46967C15.3603 9.32902 15.5511 9.25 15.75 9.25ZM15.75 15.25H23.25C23.4489 15.25 23.6397 15.329 23.7803 15.4697C23.921 15.6103 24 15.8011 24 16C24 16.1989 23.921 16.3897 23.7803 16.5303C23.6397 16.671 23.4489 16.75 23.25 16.75H15.75C15.5511 16.75 15.3603 16.671 15.2197 16.5303C15.079 16.3897 15 16.1989 15 16C15 15.8011 15.079 15.6103 15.2197 15.4697C15.3603 15.329 15.5511 15.25 15.75 15.25ZM15.75 21.25H23.25C23.4489 21.25 23.6397 21.329 23.7803 21.4697C23.921 21.6103 24 21.8011 24 22C24 22.1989 23.921 22.3897 23.7803 22.5303C23.6397 22.671 23.4489 22.75 23.25 22.75H15.75C15.5511 22.75 15.3603 22.671 15.2197 22.5303C15.079 22.3897 15 22.1989 15 22C15 21.8011 15.079 21.6103 15.2197 21.4697C15.3603 21.329 15.5511 21.25 15.75 21.25ZM10 12C9.46957 12 8.96086 11.7893 8.58579 11.4142C8.21071 11.0391 8 10.5304 8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8C10.5304 8 11.0391 8.21071 11.4142 8.58579C11.7893 8.96086 12 9.46957 12 10C12 10.5304 11.7893 11.0391 11.4142 11.4142C11.0391 11.7893 10.5304 12 10 12ZM10 18C9.46957 18 8.96086 17.7893 8.58579 17.4142C8.21071 17.0391 8 16.5304 8 16C8 15.4696 8.21071 14.9609 8.58579 14.5858C8.96086 14.2107 9.46957 14 10 14C10.5304 14 11.0391 14.2107 11.4142 14.5858C11.7893 14.9609 12 15.4696 12 16C12 16.5304 11.7893 17.0391 11.4142 17.4142C11.0391 17.7893 10.5304 18 10 18ZM10 24C9.46957 24 8.96086 23.7893 8.58579 23.4142C8.21071 23.0391 8 22.5304 8 22C8 21.4696 8.21071 20.9609 8.58579 20.5858C8.96086 20.2107 9.46957 20 10 20C10.5304 20 11.0391 20.2107 11.4142 20.5858C11.7893 20.9609 12 21.4696 12 22C12 22.5304 11.7893 23.0391 11.4142 23.4142C11.0391 23.7893 10.5304 24 10 24Z'
                />
              </svg>
            )
          case 'line-paragraph-spacing':
            return (
              <svg xmlns='http://www.w3.org/2000/svg' width={48} height={28} fill='none'>
                <path
                  fill='#222F3E'
                  d='M25 7a1.001 1.001 0 1 1 .1 2H17a1.001 1.001 0 0 1-.1-2H25Zm0 4a1.001 1.001 0 1 1 .1 2H17a1.001 1.001 0 0 1-.1-2H25Zm0 4a1.001 1.001 0 1 1 .1 2H17a1.001 1.001 0 0 1-.1-2H25Zm0 4a1.001 1.001 0 1 1 .1 2H17a1.001 1.001 0 0 1-.1-2H25ZM11 5.6l3.7 3.7a1 1 0 0 1-1.3 1.5h-.1L12 9.3v9.2l1.3-1.3a1 1 0 0 1 1.3 0h.1c.4.4.4 1 0 1.3v.1L11 22.4l-3.7-3.7a1 1 0 0 1 1.3-1.5h.1l1.3 1.5V9.4l-1.3 1.3a1 1 0 0 1-1.3 0h-.1a1 1 0 0 1 0-1.3v-.1L11 5.6Z'
                />
                <g clipPath='url(#a)'>
                  <path
                    fill='#222F3E'
                    fillOpacity={0.5}
                    d='M39.7 11.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2l-4 4.4c-.3.3-.9.3-1.2 0l-4.3-4.4a.8.8 0 0 1 0-1.2c.3-.3.8-.3 1.1 0L36 15l3.7-3.8Z'
                  />
                </g>
                <defs>
                  <clipPath id='a'>
                    <path fill='#fff' d='M30.93 9h10v10h-10z' />
                  </clipPath>
                </defs>
              </svg>
            )
          case 'animation-left':
            return (
              <svg
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M6.0923 8.78198C5.11518 9.28843 4.27125 10.0181 3.629 10.9119C2.98675 11.8056 2.56429 12.8382 2.3959 13.9258C2.22751 15.0134 2.31794 16.1254 2.65985 17.1715C3.00176 18.2176 3.58551 19.1683 4.36373 19.9466C5.14195 20.7248 6.09269 21.3085 7.1388 21.6504C8.18491 21.9923 9.29689 22.0828 10.3845 21.9144C11.4721 21.746 12.5047 21.3235 13.3984 20.6813C14.2921 20.039 15.0219 19.1951 15.5283 18.218C14.7816 18.6054 13.9708 18.8541 13.1353 18.952C12.098 19.9539 10.7087 20.5082 9.26659 20.4957C7.82451 20.4832 6.44504 19.9047 5.4253 18.885C4.40555 17.8652 3.82712 16.4858 3.81459 15.0437C3.80206 13.6016 4.35643 12.2123 5.3583 11.175C5.4583 10.32 5.7133 9.51298 6.0923 8.78198Z'
                  fill='#0D1216'
                  fillOpacity='0.3'
                />
                <path
                  d='M15.3101 15.25C18.7618 15.25 21.5601 12.4518 21.5601 9C21.5601 5.54822 18.7618 2.75 15.3101 2.75C11.8583 2.75 9.06006 5.54822 9.06006 9C9.06006 12.4518 11.8583 15.25 15.3101 15.25Z'
                  stroke='#0D1216'
                  strokeWidth='1.5'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M9.0923 5.78198C8.11518 6.28843 7.27125 7.01813 6.629 7.91186C5.98675 8.8056 5.56429 9.83817 5.3959 10.9258C5.22751 12.0134 5.31794 13.1254 5.65985 14.1715C6.00176 15.2176 6.58551 16.1683 7.36373 16.9466C8.14195 17.7248 9.09269 18.3085 10.1388 18.6504C11.1849 18.9923 12.2969 19.0828 13.3845 18.9144C14.4721 18.746 15.5047 18.3235 16.3984 17.6813C17.2921 17.039 18.0219 16.1951 18.5283 15.218C17.7816 15.6054 16.9708 15.8541 16.1353 15.952C15.098 16.9539 13.7087 17.5082 12.2666 17.4957C10.8245 17.4832 9.44504 16.9047 8.4253 15.885C7.40555 14.8652 6.82712 13.4858 6.81459 12.0437C6.80206 10.6016 7.35643 9.21229 8.3583 8.17498C8.4583 7.32098 8.7133 6.51298 9.0923 5.78198Z'
                  fill='#0D1216'
                  fillOpacity='0.6'
                />
              </svg>
            )
          case 'animation-right':
            return (
              <svg
                width='15'
                height='15'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect x='3.87988' y='4' width='7.74' height='7.74' rx='3.87' fill='#FFB800' />
                <rect
                  opacity='0.153627'
                  x='0.859863'
                  y='0.97998'
                  width='13.77'
                  height='13.78'
                  rx='6.885'
                  fill='#FFB800'
                  fillOpacity={fillOpacity}
                />
              </svg>
            )
          case 'opacity':
            return (
              <svg xmlns='http://www.w3.org/2000/svg' width={33} height={32} fill='none'>
                <path
                  fill='#0D1216'
                  fillRule='evenodd'
                  d='M7.87 6h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1Zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Z'
                  clipRule='evenodd'
                />
                <path
                  fill='#0D1216'
                  fillRule='evenodd'
                  d='M15.87 6h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1Zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Z'
                  clipRule='evenodd'
                  opacity={0.45}
                />
                <path
                  fill='#0D1216'
                  fillRule='evenodd'
                  d='M23.87 6h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1Zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Z'
                  clipRule='evenodd'
                  opacity={0.15}
                />
                <path
                  fill='#0D1216'
                  fillRule='evenodd'
                  d='M11.87 10h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Z'
                  clipRule='evenodd'
                  opacity={0.7}
                />
                <path
                  fill='#0D1216'
                  fillRule='evenodd'
                  d='M19.87 10h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm0 8h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Z'
                  clipRule='evenodd'
                  opacity={0.3}
                />
              </svg>
            )
          case 'copy-style':
            return (
              <svg xmlns='http://www.w3.org/2000/svg' width={33} height={32} fill='none'>
                <path
                  fill='#0D1216'
                  fillRule='evenodd'
                  d='M20.87 7.5h-11a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5ZM9.87 6a2 2 0 0 0-2 2v1.5a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-.25h.5a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-.75.75h-5.75a2.25 2.25 0 0 0-2.25 2.25v1.563A2 2 0 0 0 13.87 19v5a2 2 0 0 0 2 2h.5a2 2 0 0 0 2-2v-5a2 2 0 0 0-1.5-1.937V15.5a.75.75 0 0 1 .75-.75h5.75a2.25 2.25 0 0 0 2.25-2.25V10a2.25 2.25 0 0 0-2.25-2.25h-.515A2 2 0 0 0 20.87 6h-11Zm7 13a.5.5 0 0 0-.5-.5h-.5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h.5a.5.5 0 0 0 .5-.5v-5Z'
                  clipRule='evenodd'
                />
              </svg>
            )
          case 'lock':
            return (
              <svg xmlns='http://www.w3.org/2000/svg' width={33} height={32} fill='none'>
                <g clipPath='url(#a)'>
                  <path
                    fill='#0D1216'
                    fillRule='evenodd'
                    d='M12.595 8.59A4.5 4.5 0 0 1 21.37 10v2h-10.5a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2v-2a6 6 0 0 0-11.7-1.88.75.75 0 0 0 1.426.47h-.001ZM10.37 14a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v9a.501.501 0 0 1-.5.5h-12a.501.501 0 0 1-.5-.5v-9Zm5.75 4.327-.002 3.032a.75.75 0 1 0 1.5.002l.002-3.035a1.864 1.864 0 0 0 1.027-1.683c0-1.026-.796-1.857-1.778-1.857s-1.777.832-1.777 1.857c0 .746.42 1.39 1.028 1.685Z'
                    clipRule='evenodd'
                  />
                </g>
                <defs>
                  <clipPath id='a'>
                    <path fill='#fff' d='M4.87 4h24v24h-24z' />
                  </clipPath>
                </defs>
              </svg>
            )
          case 'image':
            return (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                fill='none'
                viewBox='0 0 18 18'
              >
                <path
                  fill='#222F3E'
                  d='M2 12.7l3.3-3.2c.3-.3.7-.3 1 0L9 12l4.1-4c.3-.4.8-.4 1 0l2 1.9V2H2v10.7zM2 15v1h3l2.8-2.9-2-2L2 14.9v.1zm14-3l-2.5-2.4-6.4 6.5H16V12zM1 0h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H1a1 1 0 01-1-1V1c0-.6.4-1 1-1zm6 8a2 2 0 100-4 2 2 0 000 4z'
                ></path>
              </svg>
            )
          default:
            break
        }
      })()}
    </div>
  )
}

export default CertificatesTemplateIcons
