import React from 'react'
import { Row, Col } from 'antd'

interface FormLayoutProps {
  children: React.ReactNode
}

const ListFilterLayout: React.FC<FormLayoutProps> = ({ children }) => {
  const flattenedChildren = React.Children.toArray(children) // Flatten children

  return (
    <Row gutter={[16, 16]}>
      {flattenedChildren.map((child, index) => (
        <Col key={index} xs={24} sm={12} xl={6}>
          {child}
        </Col>
      ))}
    </Row>
  )
}

export default ListFilterLayout
