import React, { Dispatch, SetStateAction, useEffect } from 'react'
// import StockReport from 'src/components/dashboard/StockReport'
// import Application from 'src/components/my-profile/overview-profile/Application'
// import ProductDelivery from 'src/components/my-profile/overview-profile/ProductDelivery'
// import TopSelling from 'src/components/my-profile/overview-profile/TopSelling'
import { IStudentDetail } from 'src/type/students'
import StudentProfileDetail from './StudentProfileDetail'

interface IProps {
  studentDetail: IStudentDetail | undefined
  loading: boolean
  setStudentDetail: Dispatch<SetStateAction<any>>
}

const OverviewStudentProfile = ({ studentDetail, loading, setStudentDetail }: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <StudentProfileDetail
        studentDetail={studentDetail}
        loading={loading}
        setStudentDetail={setStudentDetail}
      />
      {/* <div className='row gy-5 g-xl-10 mt-5'> */}
      {/* <ProductDelivery /> */}
      {/* <StockReport /> */}
      {/* </div> */}
    </>
  )
}

export default OverviewStudentProfile
