import React, { Dispatch, SetStateAction } from 'react'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_GR, TITLE_STAFF_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import { PageLink } from 'src/constants'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import ButtonIconSecondary from 'src/components/ui/button-icon-secondary/ButtonIconSecondary'
interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  onClick: () => void
  titleImport?: string
  titleExport?: string
  titleAdd: string
  link?: string
  onClickAdd?: () => void
  loading: boolean
  notImport?: boolean
  location?: any
}

const SAPPActionButton = ({
  link,
  onClick,
  setOpen,
  titleAdd,
  titleExport,
  titleImport,
  onClickAdd,
  loading,
  notImport,
  location,
}: IProps) => {
  const { profileMe } = useUserContext()
  const allowRenderExport = profileMe?.roles?.some(
    (role: Role) =>
      (role.permissions?.includes(TITLE_STAFF_GR.GET_EXPORT_STAFF) &&
        location === PageLink.STAFFS) ||
      (role.permissions?.includes(TITLE_GR.GET_EXPORT_DATA_USER_GR) &&
        location === PageLink.STUDENTS) ||
      (role.permissions?.includes(TITLE_GR.GET_EXPORT_DATA_USER_GR) &&
        location === PageLink.TEACHERS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderImport = profileMe?.roles?.some(
    (role: Role) =>
      (role.permissions?.includes(TITLE_STAFF_GR.GET_IMPPORT_STAFF) &&
        location === PageLink.STAFFS) ||
      (role.permissions?.includes(TITLE_GR.IMPORT_USER_GR) && location === PageLink.STUDENTS) ||
      (role.permissions?.includes(TITLE_GR.IMPORT_USER_GR) && location === PageLink.TEACHERS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderCreate = profileMe?.roles?.some(
    (role: Role) =>
      (role.permissions?.includes(TITLE_STAFF_GR.CREATE_STAFF) && location === PageLink.STAFFS) ||
      (role.permissions?.includes(TITLE_GR.CREATE_USER_GR) && location === PageLink.STUDENTS) ||
      (role.permissions?.includes(TITLE_GR.CREATE_USER_GR) && location === PageLink.TEACHERS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <div className='row justify-content-xl-end mt-xl-0 mt-lg-3'>
      <div className='d-flex px-0 justify-content-xl-end'>
        <ButtonIconSecondary
          iconName={'file-up'}
          title={titleImport ?? 'Import'}
          size='small'
          disabled={!allowRenderImport}
          onClick={() => setOpen(true)}
          iconType='outline'
          className='me-3'
        />
        <ButtonIconSecondary
          iconName={'file-down'}
          title={titleExport ?? 'Export'}
          size='small'
          disabled={!allowRenderExport}
          onClick={onClick}
          iconType='outline'
          className='me-3'
        />
        <ButtonIconPrimary
          iconName={'plus'}
          title={titleAdd}
          loading={loading}
          disabled={!allowRenderCreate}
          onClick={onClickAdd}
          size='small'
        />
      </div>
    </div>
  )
}
export default SAPPActionButton
