import { uniqueId } from 'lodash'
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form'
import { VALIDATION_ANSWERS_MINLENGTH, VALIDATION_ONE_TRUE_ANSWER } from '../consts'
import { ANSWER_CORRECT_TYPE } from '../interfaces'
interface IGroup {
  groupId: string
  answers: { answer: string; answerId: string; is_correct?: string }[]
}

export const generateGroups = <T extends IGroup>(
  question: string,
  groups: T[],
  isCorrect?: boolean
): T[] | undefined => {
  const questionElement = document.createElement('div')
  questionElement.innerHTML = question

  const elements = questionElement.querySelectorAll('.question-content-tag')
  let ids = Array.from(elements, (element) => {
    return element.id
  }) as string[]

  const fillerGroups = groups.filter((e) => ids.includes(e.groupId))
  const groupsMatches = ids
    ?.filter((e) => !fillerGroups.some((g) => g.groupId === e))
    .map((e) => {
      return {
        groupId: e,
        answers: [
          {
            answer: '',
            answerId: uniqueId('answer_'),
            is_correct: isCorrect ? ANSWER_CORRECT_TYPE.T : ANSWER_CORRECT_TYPE.F,
          },
          {
            answer: '',
            answerId: uniqueId('answer_'),
            is_correct: isCorrect ? ANSWER_CORRECT_TYPE.T : ANSWER_CORRECT_TYPE.F,
          },
        ],
      }
    }) as T[]

  const sortedGroups = [...fillerGroups, ...(groupsMatches ?? [])].sort((a, b) => {
    return ids.indexOf(a.groupId) - ids.indexOf(b.groupId)
  })

  return sortedGroups
}

export const generateGroupsDragDrop = (question: string): { numberOfVacant: number } => {
  const questionElement = document.createElement('div')
  questionElement.innerHTML = question

  const elements = questionElement.querySelectorAll('.question-content-tag')

  let ids = Array.from(elements, (element) => {
    return element.id
  }) as string[]
  return {
    numberOfVacant: ids?.length ?? 0,
  }
}

export const inValidAnswers = <T extends { is_correct?: ANSWER_CORRECT_TYPE }>(
  answers: T[],
  checkEvery?: string,
  minLength = 2
) => {
  if (!answers?.length || answers.length < minLength) {
    return VALIDATION_ANSWERS_MINLENGTH(minLength)
  }
  switch (checkEvery) {
    case 'NO':
      break
    case 'T':
      if (answers.every((e: T) => e.is_correct === ANSWER_CORRECT_TYPE.T)) {
        return { ...VALIDATION_ONE_TRUE_ANSWER, message: 'Please select at least one wrong answer' }
      }
      break
    case 'F':
      if (answers.every((e: T) => e.is_correct === ANSWER_CORRECT_TYPE.F)) {
        return {
          ...VALIDATION_ONE_TRUE_ANSWER,
          message: 'Please select at least one correct answer',
        }
      }
      break
    case 'ONE_TRUE':
      if (answers.filter((e: T) => e.is_correct === ANSWER_CORRECT_TYPE.T).length !== 1) {
        return VALIDATION_ONE_TRUE_ANSWER
      }
      break
    case 'TOW_TRUE':
      const correctAnswers = answers.filter((e: T) => e.is_correct === ANSWER_CORRECT_TYPE.T).length
      if (correctAnswers < 1 || correctAnswers > 2) {
        return {
          ...VALIDATION_ONE_TRUE_ANSWER,
          message: 'Please select one or two correct answers',
        }
      }
      break
    case 'TRUE_FALSE':
      if (answers.filter((e: T) => e.is_correct === ANSWER_CORRECT_TYPE.T).length !== 1) {
        return {
          ...VALIDATION_ONE_TRUE_ANSWER,
          message: 'Please select one correct and one wrong answer',
        }
      }
      break
    default:
      if (
        answers.every((e: T) => e.is_correct === ANSWER_CORRECT_TYPE.T) ||
        answers.every((e: T) => e.is_correct === ANSWER_CORRECT_TYPE.F)
      ) {
        return {
          ...VALIDATION_ONE_TRUE_ANSWER,
          message: 'Please select at least one correct and at least one wrong answer',
        }
      }
      return
  }
  return
}

export const validateAnswers = <T extends { is_correct?: ANSWER_CORRECT_TYPE }>({
  setError,
  clearErrors,
  answers,
  path,
  checkEvery,
  minLength,
}: {
  setError: UseFormSetError<any>
  clearErrors: UseFormClearErrors<any>
  answers?: T[]
  path: string
  checkEvery?: string
  minLength?: number
}) => {
  if (!answers) {
    return
  }
  const isInValidAnswers = inValidAnswers<T>(answers, checkEvery, minLength)
  if (isInValidAnswers) {
    setError(path, isInValidAnswers)
  } else {
    clearErrors(path)
  }
}
