import { ExamAPI } from 'src/apis/exams'
import { IExamPost, IResponse } from 'src/type'
import ExamForm from '../ExamForm/ExamForm'

const ExamCreate = () => {
  const createExam = async (payload: IExamPost): Promise<IResponse<any>> => {
    return ExamAPI.create(payload)
  }

  return <ExamForm submitAction={createExam} />
}

export default ExamCreate
