import React, {useEffect, useState} from 'react'
import PopupConfirm from 'src/common/PopupConfirm'
import {MenuComponent} from 'src/_metronic/assets/ts/components'
import ActionCell from 'src/components/base/action/ActionCell'
import {useUserContext} from 'src/context/UserProvider'
import {CODE_ADMIN, TITLE_ROLE_GR} from 'src/constants/permission'
import {Role} from 'src/type'

interface IProps {
  id: string
  staffId: string | undefined
  deleteStaff: (id: string, data: any) => void | any
}

export const StaffActions = ({id, staffId, deleteStaff}: IProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(false)
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItem = async () => {
    deleteStaff(staffId as string, [id])
    setOpenModalDelete(false)
  }
  const {profileMe} = useUserContext()
  const allowRenderEditRoles = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_ROLE_GR.EDIT_ROLES) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <>
      {allowRenderEditRoles && (
        <ActionCell customWidth='w-200px'>
          <div className='menu-item px-3' onClick={() => setOpenModalDelete(true)}>
            <div className='menu-link px-3'>Delete</div>
          </div>
        </ActionCell>
      )}
      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClick={deleteItem}
        body='Bạn có chắc chắn muốn xóa không?'
      />
    </>
  )
}
