// Function to remove empty filters
export const removeEmptyKey = <T extends Record<string, any>>(filters: T): T => {
  return Object.keys(filters)
    .filter((key) => {
      const value = filters[key]
      return value !== undefined && value !== '' && value !== null && value !== 'null' // Remove undefined and empty strings
    })
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: filters[key],
      }
    }, {} as T)
}
