import { useNavigate } from 'react-router-dom'
import { PageLink } from 'src/constants'
import SappModal from '../SappModal'
import { ICompleteModal } from './ModalImport'

type Props = {
  completeModal: ICompleteModal
  setOpen?: React.Dispatch<React.SetStateAction<ICompleteModal>>
  handleClose: () => void
  type?: 'STUDENT' | 'TEACHER' | 'STAFF' | 'CLASS' | 'CLASS_STUDENT' | 'TEST_PARTICIPANT' | 'EXAM'
  link?: string | undefined
}

const ModalImportComplete = ({ completeModal, handleClose, type, link }: Props) => {
  const navigate = useNavigate()
  return (
    <SappModal
      open={completeModal.open}
      handleClose={handleClose}
      title='Import Log'
      handleSubmit={() => {
        type === 'CLASS_STUDENT'
          ? navigate(`/logs/detail/${type}/${completeModal.id}`)
          : navigate(`${PageLink.IMPORT_LOG}/detail/${type}/${completeModal.id}${link ? link : ''}`)
      }}
      centered={true}
      dialogClassName='mw-550px'
      cancelButtonCaption='Close'
      okButtonCaption='View log'
    >
      <div className='d-flex flex-stack'>
        <div className='d-flex flex-stack flex-row-fluid d-grid gap-2'>
          <div className=''>
            <span className='text-gray-400 fw-semibold fs-7 d-block text-start ps-0'>Source:</span>
          </div>
          <div className='d-flex align-items-center'>
            <span className='text-gray-800 fw-bold fs-6 me-3 d-block text-truncate mw-350px text-wrap'>
              {completeModal.fileName}
            </span>
          </div>
        </div>
      </div>
      {/* end:: Source */}
      <div className='separator separator-dashed my-3'></div>
      {/* start:: Data */}
      <div className='d-flex flex-stack'>
        <div className='d-flex flex-stack flex-row-fluid d-grid gap-2'>
          <div className=''>
            <span className='text-gray-400 fw-semibold fs-7 d-block text-start ps-0'>Data:</span>
          </div>
          <div className='d-flex align-items-center'>
            <span className='text-gray-800 fw-bold fs-6 me-3 d-block'>
              {completeModal.totalRows ?? 0}
            </span>
          </div>
        </div>
      </div>
      {/* end:: Data */}
      <div className='separator separator-dashed my-3'></div>
      {/* start:: Successfully */}
      <div className='d-flex flex-stack'>
        <div className='d-flex flex-stack flex-row-fluid d-grid gap-2'>
          <div className=''>
            <span className='text-gray-400 fw-semibold fs-7 d-block text-start ps-0'>
              Successful:
            </span>
          </div>
          <div className='d-flex align-items-center'>
            <span className='text-gray-800 fw-bold fs-6 me-3 d-block'>
              {(completeModal.totalRows ?? 0) - (completeModal.errorRows ?? 0)}
            </span>
          </div>
        </div>
      </div>
      {/* end:: Successfully */}
      <div className='separator separator-dashed my-3'></div>
      {/* start:: Error */}
      <div className='d-flex flex-stack'>
        <div className='d-flex flex-stack flex-row-fluid d-grid gap-2'>
          <div className=''>
            <span className='text-gray-400 fw-semibold fs-7 d-block text-start ps-0'>Error:</span>
          </div>
          <div className='d-flex align-items-center'>
            <span className='text-gray-800 fw-bold fs-6 me-3 d-block'>
              {completeModal.errorRows ?? 0}
            </span>
          </div>
        </div>
      </div>
    </SappModal>
  )
}

export default ModalImportComplete
