import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { EntranceApi } from 'src/apis/entrance-test'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { PageLink, VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED } from 'src/constants'
import { TITLE_OPTIONS_ENTRANCE } from 'src/constants/entrancetest'
import { useConfirm } from 'src/hooks/use-confirm'
import { z } from 'zod'
import SappLabel from 'src/components/base/label/SappLabel'
import LoadingInput from 'src/components/base/LoadingInput'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { debounce, isNaN, isUndefined } from 'lodash'
import { SubjectAPI } from 'src/apis/subject'
import { ISubject, ISubjectList } from 'src/type/subject'
import { IETest } from 'src/type/entrancetest'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { PROGRAM, SUBJECT_CODE } from 'src/type/courses'

const TestInfo = ({
  handleGotoStep,
  listInfo,
  setStep,
  step,
}: {
  handleGotoStep: (id: number) => void
  listInfo: IETest | undefined
  setStep: Dispatch<SetStateAction<any>>
  step: any
}) => {
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const location = useLocation()
  const { id } = useParams()

  const validationInformationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, { message: VALIDATION_FILED })
      .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
    course_category_id: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, { message: VALIDATION_FILED }),
    subject_id: z
      .string({ required_error: VALIDATION_FILED })
      .trim()
      .min(1, { message: VALIDATION_FILED }),
    hour: z
      .string({ required_error: VALIDATION_FILED })
      .transform((val) => parseInt(val))
      .refine(
        (val) => {
          if (!getValues('minutes') && val < 0) {
            if (!val) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        },
        { message: VALIDATION_FILED }
      )
      .optional(),
    minutes: z
      .string({ required_error: VALIDATION_FILED })
      .transform((val) => parseInt(val))
      .refine(
        (val) => {
          if (!getValues('hour') && val < 0) {
            if (!val) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        },
        { message: VALIDATION_FILED }
      )
      .optional(),
  })

  const {
    handleSubmit: handleSubmitInformation,
    control: controlInformation,
    setValue: setValueInformation,
    watch,
    setError,
  } = useForm({
    resolver: zodResolver(validationInformationSchema),
  })

  const { getValues } = useForm()

  const { Option } = Select
  const [categoryTest, setCategoryTest] = useState<[]>([])
  const [editMode, setEditMode] = useState(true)

  const fetchCategory = async () => {
    try {
      const response = await EntranceApi.getCategory({ page_index: 1, page_size: 50 })
      const _categoryTest = response.data
      setCategoryTest(_categoryTest.course_categories)
    } catch (error) {}
  }

  const newCategory = categoryTest?.map((category: any) => ({
    label: category.name,
    value: category.id,
  }))
  const programCFA = newCategory.find((item) => item.label === PROGRAM.CFA)
  const isSelectCFA = watch('course_category_id') === programCFA?.value

  useEffect(() => {
    fetchCategory()
  }, [])

  const handleCancelInformation = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        navigate(`${PageLink.ENTRANCE_TEST_LIST + (location.search || '')}`)
      },
    })
  }

  const onSubmit = async (data: any) => {
    const hour = isNaN(data.hour) ? 0 : parseInt(data.hour, 10) || 0
    const minutes = isNaN(data.minutes) ? 0 : parseInt(data.minutes, 10) || 0

    if (!data.hour && !data.minutes) {
      setError('minutes', { message: VALIDATION_FILED })
      setLoading(false)
      return
    }

    if ((data.hour === 0 && data.minutes === 0) || (isNaN(data.hour) && isNaN(data.minutes))) {
      setError('minutes', { message: VALIDATION_FILED })
      setLoading(false)
      return
    }

    setLoading(true)
    const totalMinutes = hour * 60 + minutes
    let request: any = {
      name: data.name,
      course_category_id: data.course_category_id,
      subject_id: data.subject_id,
      quiz_timed: totalMinutes,
      description: '',
      quiz_question_mode: 'ALL',
      // status: 'PUBLISHED',
      is_published: true,
      is_public: true,
      password: '',
      is_graded: true,
      grading_method: 'AUTO',
      multiple_choice_multiplier: 100,
      essay_multiplier: 0,
      required_percent_score: 0,
      minimum_score: 0,
      is_limited: true,
      limit_count: 1,
      is_tutor: false,
      setting: {},
      quiz_question_type: 'MULTIPLE_CHOICE',
      quiz_type: 'ENTRANCE_TEST',
      grading_preference: 'AFTER_EACH_QUESTION',
      quiz_for: 'ENTRANCE_TEST',
    }

    try {
      if (id === 'undefined') {
        const res = await EntranceApi.createEntranceTest({ data: { ...request } })
        if (res) {
          setEditMode(false)
          navigate(`/entrance-test/list-entrance-test/edit/${res.data.id}`)
          toast.success('Test created successfully!')
        }
      } else if (id && id !== 'undefined') {
        const res = await EntranceApi.updateEntranceTest(id, { data: { ...request } })
        if (res) {
          setEditMode(false)
          // navigate(`/entrance-test/list-entrance-test/edit/${res.data.id}`)
          toast.success('Test saved successfully!')
        }
      }
      handleGotoStep(1)
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id !== 'undefined' && id && !isUndefined(listInfo)) {
      const fetchData = async (id: string) => {
        setLoading(true)
        try {
          const totalMinutes = parseInt(listInfo?.quiz_timed, 10)
          const hours = Math.floor(totalMinutes / 60)
          const mins = totalMinutes % 60
          setValueInformation('name', listInfo?.name)
          setValueInformation('subject_id', listInfo?.subject_id)
          setValueInformation('course_category_id', listInfo?.course_category_id)
          setValueInformation('hour', hours.toString())
          setValueInformation('minutes', mins.toString())
          // Dùng khi vừa vào màn edit, cần get list subject theo course_category_id hiện tại
          if (watch('course_category_id')) {
            await getSubjects({ params: { course_category_id: watch('course_category_id') } })
          }
        } catch (error) {
        } finally {
          setLoading(false)
        }
      }
      fetchData(id)
      setEditMode(false)
    }
  }, [id, listInfo])

  /**
   * @description state lưu giá trị của subject
   */
  const [subjects, setSubjects] = useState<ISubjectList>()
  const requestOngoingRef = useRef(false)

  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await SubjectAPI.getSubjects(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
    code: subject.code,
  }))

  if (isSelectCFA) {
    // Nếu người dùng chọn program là CFA thì gán trực tiếp subject là CFA Level I
    const cFALevel1 = subjectCourse?.find(({ code }) => code === SUBJECT_CODE.CFA_LEVEL_1)
    cFALevel1 && setValueInformation('subject_id', cFALevel1.value)
  }

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.subjects.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  const getSubjects = async ({ params }: any) => {
    const resMentor = await fetchSubjects(1, 20, params)
    setSubjects(resMentor?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    if (watch('course_category_id')) {
      getSubjects({ params: { name: e, course_category_id: watch('course_category_id') } })
    }
  }, 500)

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValueInformation('subject_id', '')
    if (watch('course_category_id') !== 'all') {
      await getSubjects({ params: { course_category_id: watch('course_category_id') } })
    }
  }

  const disableEntrance = listInfo?.state?.condition

  const isEntranceTestDetail = id !== 'undefined'

  return (
    <>
      <Processbar
        step={step}
        setNewStep={setStep}
        onCancel={handleCancelInformation}
        onClick={handleSubmitInformation((e) => {
          onSubmit(e)
        })}
        showCancel
        className='sapp-mw-700px'
      />
      <div className='px-6 pb-10 pt-0'>
        {contextHolder}
        <div className='h-xl-100'>
          <HookFormTextField
            label={TITLE_OPTIONS_ENTRANCE.testname}
            required
            control={controlInformation}
            name='name'
            disabled={!disableEntrance?.can_edit_entrance_test?.name && isEntranceTestDetail}
            skeleton={loading}
          />
          <Row className='mt-10'>
            <Col md={!isSelectCFA ? 6 : 12}>
              <div className='position-relative'>
                <HookFormSelectAntd
                  required
                  control={controlInformation}
                  name='course_category_id'
                  placeholder=' '
                  label={TITLE_OPTIONS_ENTRANCE.course_category_id}
                  onChange={handleCourseCategoryChange}
                  skeleton={loading}
                  disabled={
                    !disableEntrance?.can_edit_entrance_test?.course_category_id &&
                    isEntranceTestDetail
                  }
                >
                  {newCategory?.map((item: any) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
            </Col>
            <Col md={6} className='mb-10'>
              <div className='position-relative'>
                {loading ? (
                  <LoadingInput />
                ) : (
                  !isSelectCFA && (
                    <HookFormSelectAntd
                      control={controlInformation}
                      name='subject_id'
                      label='Subject'
                      required
                      onSearch={(e: any) => {
                        if (e === undefined) {
                          return
                        }
                        debounceSearchSubject(e)
                      }}
                      handleNextPage={(e: any) =>
                        handleNextPageSubject({
                          name: e,
                          course_category_id: watch('course_category_id'),
                        })
                      }
                      showSearch
                      classNameHeight='sapp-h-45px'
                      loading={loading}
                      disabled={
                        !disableEntrance?.can_edit_entrance_test?.subject_id && isEntranceTestDetail
                      }
                    >
                      {subjectCourse?.map((subject) => (
                        <Option key={subject.value} value={subject.value}>
                          {subject.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  )
                )}
              </div>
            </Col>
          </Row>
          {!loading && (
            <Col md={6}>
              <SappLabel label={TITLE_OPTIONS_ENTRANCE.quiz_timed} required />
            </Col>
          )}
          <Row>
            <Col md={6} className='mb-8'>
              <div className='position-relative'>
                <HookFormTextField
                  required
                  control={controlInformation}
                  name='hour'
                  placeholder=' '
                  type='number'
                  postFix={<div className='px-2'>h</div>}
                  disabled={
                    !disableEntrance?.can_edit_entrance_test?.quiz_timed && isEntranceTestDetail
                  }
                  skeleton={loading}
                />
              </div>
            </Col>
            <Col md={6} className='mb-10'>
              <div className='position-relative'>
                <HookFormTextField
                  required
                  control={controlInformation}
                  name='minutes'
                  placeholder=' '
                  type='number'
                  postFix={<div className='px-2'>m</div>}
                  disabled={
                    !disableEntrance?.can_edit_entrance_test?.quiz_timed && isEntranceTestDetail
                  }
                  skeleton={loading}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default TestInfo
