import React, { useRef, useState } from 'react'
import HookFormTextField from '../base/textfield/HookFormTextField'
import { Control } from 'react-hook-form'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import { DEFAULT_SELECT_ALL, FILTER_SELECTALL_SORTBY } from 'src/constants'
import { Select } from 'antd'
import { OPTIONS_OF_STATUS } from 'src/constants/classes'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { CoursesAPI } from 'src/apis/courses'
import { debounce } from 'lodash'
import { EventAPI } from 'src/apis/events'
const { Option } = Select
import { UserExportHelper } from 'src/helper/export'
import ButtonIcon from '../base/button/ButtonIcon'
import { KTIcon } from 'src/_metronic/helpers'

interface IProps {
  control: Control
  onSubmit: () => void
  watch: any
  setValue: any
  handleResetFilter: () => void
  loadingTable: boolean
}

const HeaderEventParticipant = ({
  control,
  onSubmit,
  setValue,
  watch,
  handleResetFilter,
  loadingTable,
}: IProps) => {
  const [courseCategory, setCourseCategory] = useState<any>([])
  const [subjects, setSubjects] = useState<any>()
  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await EventAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }
  const getSubjects = async ({ params }: any) => {
    const resMentor = await fetchSubjects(1, 20, params)
    setSubjects(resMentor?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    if (watch('course_category_id')) {
      getSubjects({ params: { name: e, course_category_id: watch('course_category_id') } })
    }
  }, 500)

  const fetchCourseCategory = async () => {
    try {
      const res = await CoursesAPI.getCategory({ page_index: 1, page_size: 10 })
      setCourseCategory(res?.data)
      if (watch('course_category_id') && watch('course_category_id') !== 'all') {
        await getSubjects({ params: { course_category_ids: watch('course_category_id') } })
      }
    } catch (error) {}
  }
  const requestOngoingRef = useRef(false)
  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.subjects.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('event_id', '')
    if (watch('course_category_id') !== 'all') {
      await getSubjects({ params: { course_category_id: watch('course_category_id') } })
    }
  }

  return (
    <>
      <div className='px-9 border-0 pt-6'>
        <div className='row'>
          {/* begin:: Search */}
          <div className='col-xl-3 col-sm-4'>
            <div className='card-title justify-content-center mx-0 mt-0'>
              <HookFormTextField
                control={control}
                name='text'
                placeholder='Search'
                // defaultValue={queryParams?.name}
                onSubmit={onSubmit}
                isListScreen
              />
            </div>
          </div>
          <div className='col-xl-3 col-sm-4'>
            <HookFormSelectAntd
              control={control}
              name='course_category_id'
              placeholder='Program'
              // defaultValue={queryParams?.course_category_id ?? ''}
              showSearch
              classNameHeight='sapp-h-40'
              onChange={handleCourseCategoryChange}
              onFocus={async () => {
                // if (!mailActions) {
                await fetchCourseCategory()
                // return
                // }
              }}
            >
              {courseCategory?.course_categories?.map((course_category_ids: any) => (
                <Option key={course_category_ids.id} value={course_category_ids.id}>
                  {course_category_ids.name}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
          <div className='col-xl-3 col-sm-4'>
            <HookFormSelectAntd
              control={control}
              name='event_id'
              placeholder='Event'
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                debounceSearchSubject(e)
              }}
              handleNextPage={(e: any) =>
                handleNextPageSubject({
                  name: e,
                  course_category_id: watch('course_category_id'),
                })
              }
              showSearch
              classNameHeight='sapp-h-40'
              // loading={loading}
              // defaultValue={queryParams?.subject_id ?? ''}
            >
              {subjects?.events?.map((subject: any) => (
                <Option key={subject.id} value={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
          <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort by'
              control={control}
              size='large'
              // defaultValue={queryParams?.sortType ?? ''}
              classNameHeight='sapp-h-40'
            >
              {FILTER_SELECTALL_SORTBY.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
          {/* <div className='col-xl-3 col-sm-4 mt-5'>
            <HookFormSelectAntd
              name='status'
              placeholder='Status'
              control={control}
              size='large'
              // defaultValue={queryParams?.status ?? ''}
              // defaultValue={searchParams.get('status') as string}
              classNameHeight='sapp-h-40'
            >
              {OPTIONS_OF_STATUS.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div> */}
          {/* <div className='col-xl-3 col-sm-4 mt-5'>
            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              // defaultValue={queryParams?.fromDate}
              isListScreen
            />
          </div>
          <div className='col-xl-3 col-sm-4 mt-5'>
            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              // defaultValue={queryParams?.toDate}
              isListScreen
            />
          </div> */}
        </div>
      </div>
      <div className='card-header border-0 pt-6 px-9'>
        <div className='d-flex'>
          <SAPPFIlterButton
            titleReset='Reset'
            titleSubmit='Search'
            okClick={onSubmit}
            resetClick={handleResetFilter}
            disabled={loadingTable}
            loading={loadingTable}
          />
        </div>
      </div>
    </>
  )
}

export default HeaderEventParticipant
