import {AxiosResponse, CancelTokenSource} from 'axios'
import {IResource} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import {fetcher, getBaseUrl, request} from 'src/services/request'
import {IMeta, IResponse, IResponseMeta} from 'src/type'

interface IResponseResourceData<T> {
  meta: IMeta
  resources: T[]
}
interface IResponseResourceMeta<T> extends IResponseMeta {
  data: IResponseResourceData<T>
}

export class ResourcesAPI {
  static processStreamVideo(): Promise<IResponseResourceMeta<number>> {
    return fetcher(`/resources/process/stream-video`, {method: 'POST'})
  }
  static getList(params: any): Promise<IResponseResourceMeta<IResource>> {
    return fetcher(`/resources`, {params})
  }
  static getUrl(resource_id: string): Promise<IResponse<any>> {
    return fetcher(`/resources/${resource_id}`, {method: 'GET'})
  }
  static delete(resource_id: string): Promise<IResponse<any>> {
    return fetcher(`/resources/${resource_id}`, {method: 'DELETE'})
  }
  static getFileFromResource(
    resource_id: string,
    source: CancelTokenSource
  ): Promise<IResponse<any>> {
    return fetcher(`/resources/${resource_id}`, {method: 'GET', cancelToken: source.token})
  }

  /**
   * @static
   * @description Download file từ resource
   * @param {number} fileSize
   * @param {{files: {name: string; file_key: string}}} {files}
   * @memberof ResourcesAPI
   */
  static downloadFile = async (data: {files: {name: string; file_key: string}[]}) => {
    try {
      const responseToken: AxiosResponse<{
        data: string
        success: boolean
      }> = await request('resources/get-token-download', {
        method: 'POST',
        data,
      })
      if (responseToken?.data?.success) {
        const link = document.createElement('a')
        link.href = `${getBaseUrl()}/resources/download?token=${responseToken?.data?.data}`
        link.download = data.files[0].name
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {}
  }
}
