import {Dispatch, SetStateAction} from 'react'
import ActionCell from '../base/action/ActionCell'
import {IResource} from 'src/type/resource'
import {useUserContext} from 'src/context/UserProvider'
import {CODE_ADMIN, TITLE_RESOURCES_GR} from 'src/constants/permission'
import {Role} from 'src/type'

interface IProps {
  resource: IResource
  confirm: any
  deleteResource: (id: string[]) => void
  setResourceId: Dispatch<SetStateAction<string>>
  setNewFolder: (value: boolean) => void
  handleEdit: any
  handleDownload: (resources: IResource[]) => void
  handleMoveToFolder: (resource: IResource) => void
  handleEditResourceVideo: (resource: IResource) => void
}

const ResourceAction = ({
  resource,
  confirm,
  deleteResource,
  setResourceId,
  setNewFolder,
  handleEdit,
  handleDownload,
  handleMoveToFolder,
  handleEditResourceVideo,
}: IProps) => {
  const handleDeleteResource = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: () => deleteResource([resource.id]),
    })
  }
  const {profileMe} = useUserContext()
  const allowRenderDownload = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.DOWNLOAD_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderViewuUploadFolder = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.VIEW_UPLOAD_FOLDER_RESOURCES) ||
      role.permissions?.includes(TITLE_RESOURCES_GR.UPDATE_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderRemoveResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.REMOVE_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderUpdate = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.UPDATE_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <>
      <ActionCell customWidth='w-150px'>
        {resource.resource_type === 'FILE' && allowRenderDownload && (
          <div className='menu-item px-3' onClick={() => handleDownload([resource])}>
            <div className='menu-link px-3'>Download File</div>
          </div>
        )}
        {allowRenderUpdate && (
          <div
            className='menu-item px-3'
            onClick={() => {
              setResourceId(resource.id)
              setNewFolder(true)
              handleEdit(resource.id)
            }}
          >
            <div className='menu-link px-3'>Rename</div>
          </div>
        )}
        {allowRenderViewuUploadFolder && allowRenderUpdate && (
          <div className='menu-item px-3' onClick={() => handleMoveToFolder(resource)}>
            <div className='menu-link px-3'>Move To Folder</div>
          </div>
        )}
        {allowRenderRemoveResources && (
          <div className='menu-item px-3' onClick={handleDeleteResource}>
            <div className='menu-link px-3'>Delete</div>
          </div>
        )}
        {resource?.suffix_type === 'VIDEO' && allowRenderUpdate && (
          <div className='menu-item px-3' onClick={() => handleEditResourceVideo(resource)}>
            <div className='menu-link px-3'>Edit</div>
          </div>
        )}
      </ActionCell>
    </>
  )
}

export default ResourceAction
