import { useEffect, useState } from 'react'
import ModalImportStudent from '../user-edit-modal/ModalImportStudent'
import { PageLink } from 'src/constants'
import { UserExportHelper } from 'src/helper/export'
import SAPPActionButton from 'src/common/SAPPActionButton'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import { useNavigate } from 'react-router-dom'

interface IProps {
  searchTerm: string | null
  sortGender: string | null
  sortSortBy: string | null
  sortStatus: string | null
  // sortTypeofExam: string | null
  // sortListofExam: string | null
  fromDate: string | null
  toDate: string | null
  location?: any
}

const UsersListToolbar = ({
  searchTerm,
  sortGender,
  sortSortBy,
  sortStatus,
  toDate,
  fromDate,
  location,
}: IProps) => {
  const [openUpload, setOpenUpload] = useState(false)
  const [loading, setLoading] = useState(false)

  const exportStudent = async () => {
    setLoading(true)
    try {
      await UserExportHelper.exportToXlsx(
        sortGender,
        sortStatus,
        searchTerm,
        sortSortBy,
        fromDate,
        toDate
      )
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const [isLocation, setIsLocation] = useState<string>()
  useEffect(() => {
    setIsLocation(location.pathname)
  }, [location.pathname])
  const navigate = useNavigate()
  const handleAddStudent = () => {
    navigate(PageLink.CREATE_STUDENTS)
  }
  const { profileMe } = useUserContext()
  const allowRenderImport = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.CREATE_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <div className='sapp-height-list--grouping'>
      <SAPPActionButton
        onClick={exportStudent}
        setOpen={setOpenUpload}
        titleAdd='Create'
        loading={loading}
        onClickAdd={handleAddStudent}
        notImport={!allowRenderImport}
        location={isLocation}
      />
      <ModalImportStudent open={openUpload} setOpen={setOpenUpload} />
    </div>
  )
}

export { UsersListToolbar }
