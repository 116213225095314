import React from 'react'
import styles from './ButtonPrimary.module.scss'
import { Link } from 'react-router-dom'
import { IButtonProps } from 'src/type/button'

const ButtonPrimary = ({
  title,
  size = 'large',
  disabled,
  loading,
  link = '',
  onClick,
  type = 'button',
  className = '',
}: IButtonProps) => {
  const heightClass = size === 'large' ? `sapp-h-45px  ${styles['sapp-lh-16']}` : `sapp-h-40`

  if (link)
    return (
      <Link to={link} className={`btn ${heightClass} ${styles['sapp-btn--primary']} ${className}`}>
        {title}
      </Link>
    )

  return (
    <button
      disabled={disabled || loading}
      className={`btn ${heightClass} ${styles['sapp-btn--primary']} ${className}`}
      onClick={onClick && onClick}
      type={type}
    >
      {loading ? (
        <span className={`indicator-progress sapp-d-content`}>
          <span className='spinner-border spinner-border-sm align-middle'></span>
        </span>
      ) : (
        <>{title}</>
      )}
    </button>
  )
}

export default ButtonPrimary
