import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import CaseStudyComponent, {
  CaseStudyChildRef,
} from 'src/components/courses/course-detail/case-study/Index'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'

const CaseStudy = () => {
  const childRef = useRef<CaseStudyChildRef>(null)
  const params = useParams()
  return (
    <CreateEditLayout
      pageTitle={`${params?.id ? 'Edit' : 'Create'} Case Study`}
      onSave={() => childRef?.current?.onSubmit()}
      onCancel={() => childRef.current?.handleCancel()}
    >
      {params.courseSectionId && params.courseId && (
        <>
          <CaseStudyComponent
            courseSectionId={params.courseSectionId}
            courseId={params.courseId}
            id={params.id}
            ref={childRef}
          ></CaseStudyComponent>
        </>
      )}
    </CreateEditLayout>
  )
}

export default CaseStudy
