import {fetcher} from 'src/services/request'
import {IRolesAll} from 'src/type/staffs.'
import {IResponse} from 'src/type'
import {IRolesList} from 'src/type/roles'

export class RolesAPI {
  static get(page_index: number, page_size: number, params?: Object): Promise<IRolesAll> {
    return fetcher(`roles?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getListRoles({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IRolesList>> {
    return fetcher(`roles?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static detail({id}: {id: string}): Promise<IResponse<any>> {
    return fetcher(`/roles/${id}`, {method: 'GET'})
  }

  static permissionsDetail(): Promise<IResponse<any>> {
    return fetcher(`/permissions`, {method: 'GET'})
  }

  static delete = (id: string | number) => {
    return fetcher(`roles/${id}`, {
      method: 'DELETE',
    })
  }

  static addRole(param?: Object): Promise<IResponse<any>> {
    return fetcher(`/roles`, {
      method: 'POST',
      data: param,
    })
  }

  static editRole(param?: Object): Promise<IResponse<any>> {
    return fetcher(`/roles`, {
      method: 'PATCH',
      data: param,
    })
  }

  static getListStaffs({
    role_id,
    page_index,
    page_size,
    params,
  }: {
    role_id: string
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`/roles/${role_id}/staffs?page_index=${page_index}&page_size=${page_size}`, {
      method: 'GET',
      params: params,
    })
  }

  static addStaffs(role_id: string | number, data: any): Promise<IResponse<any>> {
    return fetcher(`/roles/${role_id}/staffs`, {
      method: 'POST',
      data,
    })
  }

  static deleteStaffs(role_id: string | number, data: any): Promise<IResponse<any>> {
    return fetcher(`/roles/${role_id}/staffs`, {
      method: 'DELETE',
      data,
    })
  }
}
