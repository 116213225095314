import {Control, Controller} from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import './HookFormRadioImageGroup.scss'
import {Skeleton} from 'antd'

interface IHookFormRadioImageGroupProps {
  name: string
  control: Control<any>
  defaultValue?: unknown
  options: Array<{
    label?: {
      normal: string
      selected: string
      text?: string
    }
    value: string
  }>
  direction?: 'horizontal' | 'vertical'
  separator?: boolean
  skeleton?: boolean
  labelClass?: string
  labelClassChecked?: string
}

const HookFormRadioImageGroup = ({
  name,
  control,
  defaultValue,
  options,
  direction,
  skeleton,
  labelClass = '',
  labelClassChecked = '',
}: IHookFormRadioImageGroupProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field, fieldState: {error}}) => (
        <>
          <div
            className={`${
              direction === 'horizontal' ? 'd-flex justify-content-between flex-wrap' : ''
            } sapp-radio-image-group`}
          >
            {options.map((option, index) => {
              const checked = option.value === field.value
              return (
                <label className='sapp-form-check' key={option.value ?? index}>
                  <input
                    {...field}
                    className={`sapp-form-check-input`}
                    type='radio'
                    value={option.value}
                    checked={option.value === field.value}
                  />
                  {!skeleton ? (
                    <>
                      <div className='sapp-form-check-label'>
                        {checked ? (
                          <div className='sapp-radio-item'>
                            <img src={option.label?.selected} alt='img' />
                          </div>
                        ) : (
                          <div className='sapp-radio-item'>
                            <img src={option.label?.normal} alt='img' />
                          </div>
                        )}
                      </div>
                      <p
                        className={`${
                          checked
                            ? `text-primary ${labelClassChecked ? labelClassChecked : 'fw-bold'}`
                            : `text-gray-500 ${labelClass ? labelClass : 'fw-semibold'}`
                        } sapp-font-radio-image text-center mt-3 mb-0`}
                      >
                        {option.label?.text}
                      </p>
                    </>
                  ) : (
                    <Skeleton.Input active size='large'></Skeleton.Input>
                  )}
                </label>
              )
            })}
          </div>
          <ErrorMessage>{error?.message}</ErrorMessage>
        </>
      )}
    />
  )
}

export default HookFormRadioImageGroup
