import { useState } from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import TableUsers from './tableUsers'
import { toast } from 'react-hot-toast'
import { RolesAPI } from 'src/apis/roles'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'

interface IProps {
  open: boolean
  setOpen: any
  id: string
  functionCallBack: () => void
  roleData?: any
}
const AddStaff = ({ open, setOpen, id, functionCallBack, roleData }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [userState, setUserState] = useState<any>()

  const getParams = (
    text: string,
    role_code: string,
    status: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    condition_type?: string,
    dateField?: string
  ) => ({
    text,
    role_code,
    status,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    condition_type,
    dateField,
  })
  const queryParams = {
    text: '',
    role_code: '',
    status: 'ACTIVE',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }

  const fetchListStaffs = async (page_index?: number, page_size?: number, params?: Object) => {
    try {
      const response = await RolesAPI.getListStaffs({
        role_id: id as string,
        page_index: page_index || 1,
        page_size: page_size || 10,
        params: params,
      })
      setUserState(response?.data)
      setLoading(true)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const { checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll } =
    useChecked<any>(userState?.staffs ?? undefined)

  const { confirm, contextHolder } = useConfirm()

  const addStaffsInRole = async (data?: any) => {
    setLoading(true)
    try {
      const res = await RolesAPI.addStaffs(id, { staff_ids: data })
      if (res) {
        toast.success('Add Successfully!')
        await functionCallBack()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
    toggleCheckAll(false)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: DESCRIPTION_POPUPCONFIRM,
      onClick: handleClose,
    })
  }

  const handleSubmit = async () => {
    setOpen(false)
    await addStaffsInRole(checkedList)
    toggleCheckAll(false)
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Add Member</div>
          <div className='d-flex justify-content-between align-items-center'>
            {listDataChecked.length > 0 && (
              <div className='fw-medium fs-6 text-primary me-8'>
                {listDataChecked.length} Selected
              </div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-semibold sapp-fs-14 me-0 btn-sapp-filter me-3 btn btn-primary'
              classNameSubmit='fw-semibold sapp-fs-14 me-5'
            />
          </div>
        </div>
        <div className='px-7'>
          <TableUsers
            userState={userState}
            loading={loading}
            setLoading={setLoading}
            fetchListUsers={fetchListStaffs}
            getParams={getParams}
            queryParams={queryParams}
            checkedList={checkedList}
            toggleCheck={toggleCheck}
            toggleCheckAll={toggleCheckAll}
            isCheckedAll={isCheckedAll}
            id={id}
            roleData={roleData}
          />
        </div>
      </SappModal>
    </>
  )
}
export default AddStaff
