import PageLayouts from 'src/components/layout/PageLayouts'
import {QuestionProviderProvider} from 'src/components/question-bank/shared/providers/QuestionProvider'
import FormTopic from 'src/components/question-bank/topic/FormTopic'

import {PageLink} from 'src/constants'
import {LANG_SIDEBAR} from 'src/constants/lang'
import {ITabs} from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: ``,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: PageLink.TOPICS,
    title: LANG_SIDEBAR.topicList,
  },
  {
    link: PageLink.TOPIC,
    title: LANG_SIDEBAR.topic,
  },
]

const Topic = () => {
  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.topic} breadcrumbs={breadcrumbs}>
      <QuestionProviderProvider>
        <FormTopic></FormTopic>
      </QuestionProviderProvider>
    </PageLayouts>
  )
}

export default Topic
