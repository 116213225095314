import { Select } from 'antd'
import { isEmpty } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTable } from 'react-table'
import { KTCardBody } from 'src/_metronic/helpers'
import { UsersAPI } from 'src/apis/user'
import LoadingTable from 'src/common/LoadingTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseDataPagination,
} from 'src/components/user-list/components/core/QueryResponseProvider'
import CodeUser from 'src/components/user-management/CodeUser'
import UserCell from 'src/components/user-management/UserCell'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import { MOCKUP_HEADER, STATUS_FORM, STUDENT_PROFILE } from 'src/constants'
import { CODE_ADMIN, TITLE_GR, TITLE_STAFF_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusUser, Role } from 'src/type'
import { IPaginationUser } from 'src/type/students'
import { formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { usersColumns } from './columns/_columns'
import { UserActionsCell } from './columns/UserActionsCell'
const { Option } = Select

const UsersTable = ({
  handleChangeParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  fromDate,
  toDate,
  textSearch,
  sortStatus,
  sortGender,
  pageSize,
  currentPage,
  // filterClass,
  // sortExamType,
  // sortExamList,
  sortType,
}: IPaginationUser) => {
  const { isLoading, refetch } = useQueryResponse()
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { rows } = useTable({ columns, data })
  const pagination = useQueryResponseDataPagination()
  const { updateState } = useQueryRequest()
  const { confirm, contextHolder } = useConfirm()

  // TODO: reset lại table về mặc định nếu không call API
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(fromDate as Date)
  const dateQueryToDate = getDateInfo(toDate as Date)

  const existFilter =
    !isEmpty(textSearch) ||
    !isEmpty(sortGender) ||
    !isEmpty(sortStatus) ||
    !isEmpty(fromDate) ||
    !isEmpty(toDate) ||
    !isEmpty(sortType)
  // !isEmpty(filterClass) ||
  // !isEmpty(sortExamType) ||
  // !isEmpty(sortExamList)

  useEffect(() => {
    updateState({
      page_size: pageSize,
      page_index: currentPage,
      text: textSearch,
      gender: sortGender,
      status: sortStatus,
      fromDate: fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      toDate: toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      sortType: sortType,
      // class: filterClass,
      // examList: sortExamList,
      // examType: sortExamType,
      dateField: existFilter ? 'updated_at' : '',
    })
  }, [])

  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderEditStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderResetPassword = hasPermission(
    profileMe?.roles,
    TITLE_GR.PUT_RESET_PASSWORD_USER_GR
  )
  const allowRenderResetPasswordStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.RESET_PASSWORD_STAFF
  )
  const allowRenderBlock = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderBlockUser = hasPermission(profileMe?.roles, TITLE_GR.REMOVE_USER_GR)
  const allowRenderBlockStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderEditEmail = hasPermission(profileMe?.roles, TITLE_GR.PUT_CHANGE_EMAIL_USER_GR)
  const allowRenderEditEmailStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.CHANGE_EMAIL_STAFF
  )
  const allowRenderViewProfile = hasPermission(profileMe?.roles, TITLE_GR.VIEW_USER_GR)
  const allowRenderViewProfileStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.GET_STAFF)
  const allowRenderAction =
    allowRenderEdit ||
    allowRenderBlockUser ||
    allowRenderEditStaff ||
    allowRenderResetPassword ||
    allowRenderResetPasswordStaff ||
    allowRenderBlock ||
    allowRenderBlockStaff ||
    allowRenderEditEmail ||
    allowRenderEditEmailStaff ||
    allowRenderViewProfile ||
    allowRenderViewProfileStaff

  // Using validate for input
  const { control, watch, setValue } = useForm<any>({
    mode: 'onChange',
  })

  const changeStatusUser = (id: string, status: any) => {
    UsersAPI.updateUserStatus({ id, status })
      .then(() => toast.success('Update Successfully!'))
      .catch((error) => {
        // if (error?.response?.data?.error?.code === '403|000000') {
        //   toast.error(DENIED_PERMISSIONS)
        // }
      })
  }

  const hanleChangeStatus = (id: string, value: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: () =>
        changeStatusUser(
          id,
          EStatusUser.ACTIVE === value
            ? EStatusUser.ACTIVE
            : EStatusUser.INACTIVE === value
            ? EStatusUser.INACTIVE
            : EStatusUser.BLOCKED
        ),
      onClose: () => cancel(true),
    })
  }

  const handleChange = (name: string, value: string, id: string) => {
    if (watch(name)) {
      hanleChangeStatus(id, value)
    }
  }

  useEffect(() => {
    rows
      ?.map((staff: any) => ({
        label: staff?.status?.toLocaleLowerCase(),
        value: staff?.status,
      }))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, rows])

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    updateState({
      page_size: page_size,
      page_index: page_index,
      text: textSearch ?? '',
      gender: sortGender ?? '',
      status: sortStatus ?? '',
      // class: filterClass ?? '',
      fromDate: fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      toDate: toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      // examType: sortExamType,
      // examList: sortExamList,
      sortType: sortType,
      dateField: existFilter ? 'updated_at' : 'created_at',
    })
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  return (
    <KTCardBody className='py-4'>
      {contextHolder}
      <SappTable
        headers={[
          { label: 'student id' },
          { label: 'user' },
          { label: 'username' },
          { label: 'phone' },
          { label: 'status' },
          { label: 'date' },
        ]}
        loading={isLoading}
        data={rows}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll)
        }}
      >
        {isLoading ? (
          <>
            {MOCKUP_HEADER.map((_header, i) => (
              <LoadingTable key={i} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {rows?.map((user: any, index: any) => {
              const userOriginal = user?.original
              const isChecked = checkedList.includes(userOriginal?.id)
              const userContact = userOriginal?.user_contacts?.find(
                (contact: any) => contact.is_default
              )

              return (
                <tr key={userOriginal.id ?? index}>
                  <td className='min-w-50px'>
                    <SAPPCheckbox
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(userOriginal.id)
                      }}
                    />
                  </td>
                  <td className='min-w-125px'>
                    <CodeUser code={userOriginal.hubspot_contact_id} />
                  </td>
                  <td className='min-w-275px'>
                    <UserInfoCell
                      user={userOriginal}
                      linkViewProfile={`${STUDENT_PROFILE}/${userOriginal.id}/overview`}
                    />
                  </td>
                  <td className='min-w-150px'>
                    <UserCell data={userOriginal.username} />
                  </td>
                  <td className='min-w-175px'>
                    <UserCell data={userOriginal.user_contacts?.[0]?.phone} />
                  </td>
                  <td className='min-w-175px'>
                    <HookFormSelectAntd
                      size='large'
                      name={`status${index}`}
                      control={control}
                      dropdownStyle={{ maxWidth: 200 }}
                      placeholder='Status'
                      filterOption={true}
                      disabled={!allowRenderEdit}
                      defaultValue={userOriginal?.status}
                      onChange={(selectedValue: unknown) => {
                        return handleChange(
                          `status${index}`,
                          selectedValue as string,
                          user?.original?.id
                        )
                      }}
                    >
                      {STATUS_FORM.map((status) => (
                        <Option key={status.label} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </td>
                  <td className='min-w-250px'>
                    <DatetimeColumn
                      updated_at={userOriginal.updated_at}
                      created_at={userOriginal.created_at}
                    />
                  </td>
                  {allowRenderAction && (
                    <td className='text-center sapp-absolute-column'>
                      <UserActionsCell
                        id={userOriginal.id}
                        status={userOriginal.status}
                        email={userContact?.email}
                      />
                    </td>
                  )}
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={pagination?.data?.metadata?.page_index || 1}
        pageSize={pagination?.data?.metadata?.page_size || 10}
        totalItems={pagination?.data?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export { UsersTable }
