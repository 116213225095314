import {ColorPicker, Divider} from 'antd'

import './HookFormColorPicker.scss'
import {useRef, useState} from 'react'
import {Color} from 'antd/es/color-picker'
import {Control, Controller} from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
interface IProps {
  name: string
  control: Control<any>
  defaultValue?: string
  className?: string
  onChange?: (heX?: string) => void
  disabledAlpha?: boolean
}

const HookFormColorPicker = ({
  name,
  control,
  defaultValue,
  className,
  onChange,
  disabledAlpha = true,
}: IProps) => {
  const [recentColor, setRecentColor] = useState<(string | Color)[]>([])
  const color = useRef<string | undefined>(defaultValue)
  const handleSetRecentColor = (color: string) => {
    setRecentColor((e) => [...new Set([...e, color])])
  }
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field, fieldState: {error}}) => (
        <div>
          <ColorPicker
            value={field.value}
            defaultValue={field.value}
            onOpenChange={(open) => {
              if (!open) {
                if (color.current) {
                  field.onChange(color.current)
                  onChange && onChange(color.current)
                  handleSetRecentColor(color.current)
                } else {
                  field.onChange(undefined)
                  onChange && onChange(undefined)
                }
              }
            }}
            onChangeComplete={(value: Color) => {
              color.current = value?.toHexString() || defaultValue || field.value
            }}
            destroyTooltipOnHide
            className={`sapp-color-picker-input ${className}`}
            rootClassName='sapp-color-picker'
            styles={{
              popupOverlayInner: {
                width: 468 + 24,
              },
            }}
            disabledAlpha={disabledAlpha}
            presets={[
              {
                label: 'Recommended',
                colors: [
                  '#000000',
                  '#000000E0',
                  '#000000A6',
                  '#00000073',
                  '#00000040',
                  '#00000026',
                  '#0000001A',
                  '#00000012',
                  '#0000000A',
                  '#00000005',
                  '#F5222D',
                  '#FA8C16',
                  '#FADB14',
                  '#8BBB11',
                  '#52C41A',
                  '#13A8A8',
                  '#1677FF',
                  '#2F54EB',
                  '#722ED1',
                  '#EB2F96',
                  '#F5222D4D',
                  '#FA8C164D',
                  '#FADB144D',
                  '#8BBB114D',
                  '#52C41A4D',
                  '#13A8A84D',
                  '#1677FF4D',
                  '#2F54EB4D',
                  '#722ED14D',
                  '#EB2F964D',
                ],
              },
              {
                label: 'Recent',
                colors: recentColor,
              },
            ]}
            panelRender={(_, {components: {Picker, Presets}}) => (
              <div
                className='custom-panel'
                style={{
                  display: 'flex',
                  width: 468,
                }}
              >
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <Presets />
                </div>
                <Divider
                  type='vertical'
                  style={{
                    height: 'auto',
                  }}
                />
                <div
                  style={{
                    width: 234,
                  }}
                >
                  <Picker />
                </div>
              </div>
            )}
          />
          <ErrorMessage>{error?.message}</ErrorMessage>
        </div>
      )}
    />
  )
}

export default HookFormColorPicker
