import React from 'react'

const NotData = ({className}: {className?: string | undefined}) => {
  return (
    <div
      className={`${
        className ?? ''
      } text-gray-600 fw-semibold d-flex text-center w-100 align-content-center justify-content-center mt-10 `}
    >
      No matching records found
    </div>
  )
}

export default NotData
