import { zodResolver } from '@hookform/resolvers/zod'
import { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { CaseStudyAPI } from 'src/apis/case-study'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { PageLink, VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { IStory } from 'src/type/story'
import { mergeImageToEditor } from 'src/utils/upload'
import { z } from 'zod'
import { ATTEMPT, GRADING_PREFERENCE, PASS_POINT } from '../../shared/consts'
import ChooseStoriesDrawer from './ChooseStoriesDrawer'
import CaseStudyTable from './StoriesTable'
import SappLabel from 'src/components/base/label/SappLabel'
import { CaseStudy } from 'src/type/case-study'
import { QuizCondition } from 'src/type/quiz'
import { useCourseContext } from 'src/context/CourseProvider'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'

interface IFormCaseStudy {
  name: string
  status: string
  description: string
  // duration: {
  //   hours: string
  //   mins: string
  // }
  gradingPreference: string
  // passPoint: {
  //   type: string
  //   value: string
  // }
  grading_method?: string
  // attempt: {
  //   type: string
  //   value: string
  // }
  emptyStory?: any
}
const defaultValues = {
  name: '',
  description: '',
  duration: {
    hours: '',
    mins: '',
  },
  gradingPreference: GRADING_PREFERENCE.all.value,
  passPoint: {
    type: PASS_POINT.auto.value,
    value: '',
  },
  attempt: {
    type: ATTEMPT.unlimited.value,
    value: '',
  },
}

type Props = {
  id?: string
  courseId: string
  courseSectionId: string
}

export interface CaseStudyChildRef {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
  handleCancel: () => void
}
const CaseStudyComponent = forwardRef<CaseStudyChildRef, Props>(
  ({ id, courseId, courseSectionId }, ref) => {
    const { confirm, contextHolder } = useConfirm()
    const navigate = useNavigate()

    const [story, setStory] = useState<IStory>()
    const [openChooseQuestion, setOpenChooseQuestion] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [skeleton, setSkeleton] = useState<boolean>(false)
    const [defaultEditor, setDefaultEditor] = useState<any>()
    const { setIdChild } = useCourseContext()
    const [quizCondition, setQuizCondition] = useState<QuizCondition>(new QuizCondition())
    const validationSchema = z
      .object({
        name: z
          .string({ required_error: VALIDATION_FILED })
          .trim()
          .min(1, VALIDATION_FILED)
          .max(1000, VALIDATE_FILED_MAX_LENGTH('Case Study name', 1000)),
        description: z.string().min(1, VALIDATION_FILED),
        gradingPreference: z
          .string({ required_error: VALIDATION_FILED })
          .trim()
          .min(1, VALIDATION_FILED),
      })
      .refine(
        () => {
          if (!story) {
            return false
          } else {
            return true
          }
        },
        {
          message: 'Case Study is required',
          path: ['emptyStory'],
        }
      )
    const {
      handleSubmit,
      control,
      setValue,
      formState: { errors, isSubmitted },
      trigger,
    } = useForm<IFormCaseStudy>({
      resolver: zodResolver(validationSchema),
      mode: 'onSubmit',
      defaultValues,
    })

    // const watchPassPoint = watch('passPoint')
    // const watchAttempt = watch('attempt')

    useEffect(() => {
      if (isSubmitted) {
        trigger('emptyStory')
      }
    }, [story])

    useLayoutEffect(() => {
      ;(async () => {
        if (id) {
          setSkeleton(true)
          try {
            const response = await CaseStudyAPI.getById(id)
            if (response.success) {
              convertResultToFormValues(response.data)
            }
          } catch (error) {
            navigate(`${PageLink.COURSE_FULL}/${courseId}`)
          } finally {
            setSkeleton(false)
          }
        }
      })()
    }, [])

    const convertResultToFormValues = (response: CaseStudy) => {
      if (response?.state) {
        setQuizCondition(response.state.condition)
      }
      const result: IFormCaseStudy = {
        name: response.name,
        status: response.status,
        description: response.description,
        gradingPreference: response.grading_preference,
      }

      Object.entries(result || {}).forEach(async ([key, value]) => {
        if (key !== 'description') {
          setValue(key as any, value)
        } else {
          const dataDes = await mergeImageToEditor(value, [])
          setDefaultEditor(dataDes)
          setValue('description', dataDes)
        }
      })
      if (response.case_study_story) {
        setStory(response.case_study_story)
      }
    }

    const handleChooseQuestions = () => {
      setOpenChooseQuestion(true)
    }

    /**
     * @description state này để lưu data của file sau khi update từ editor
     */
    const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

    /**
     * @description state này để lấy id của file sau khi update từ editor
     */
    const textEditorFiles =
      fileEditor?.map((data) => {
        return { id: data?.id }
      }) || []

    const onSubmit = handleSubmit(async (data: IFormCaseStudy) => {
      const request: any = {
        name: data.name.trim(),
        description: data.description?.trim() || '',
        status: data,
        // quiz_timed: Number(data.duration.hours) * 60 + Number(data.duration.mins),
        is_published: true,
        grading_preference: 'AFTER_ALL_QUESTIONS',
        // required_percent_score:
        //   data.passPoint.type === PASS_POINT.auto.value ? 50 : Number(data.passPoint.value),
        // grading_method: data.passPoint.type,
        is_limited: false,
        // ...(data.attempt.type === ATTEMPT.limited.value && {
        //   limit_count: Number(data.attempt.value) || 0,
        // }),
        case_study_story_id: story?.id!,
        course_section_id: courseSectionId,
        course_id: courseId,
        text_editor_files: textEditorFiles,
      }

      // let response: any
      setLoading(true)
      try {
        if (id) {
          const response = (await CaseStudyAPI.update(id, { ...request })) as any
          setIdChild(response?.data?.course_section?.id)
        } else {
          const response = (await CaseStudyAPI.create(request)) as any
          setIdChild(response?.data?.course_section_id)
        }
        // if (response.success) {
        toast.success('Case study saved successfully!')
        navigate(PageLink.COURSE_FULL + '/' + courseId + '?page=1')
        // }
      } finally {
        setLoading(false)
      }
    })

    /**
     * @description Trở về màn course
     */
    const handleCancel = () => {
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body: ['Bạn có các thay đổi chưa lưu.', 'Bạn có muốn quay lại trang Course Content?'],
        onClick: () => navigate(PageLink.COURSE_FULL + '/' + courseId + '?page=1'),
        isMoreDescription: true,
      })
    }

    useImperativeHandle(ref, () => {
      return {
        onSubmit,
        handleCancel,
      }
    })

    return (
      <div className='sapp-modal--content'>
        {contextHolder}
        <div>
          <div className='mb-10'>
            {/*start:: Name */}
            <div className='mb-10'>
              <HookFormTextField
                control={control}
                name='name'
                label='Case Study Name'
                required
                skeleton={skeleton}
                onSubmit={onSubmit}
              ></HookFormTextField>
            </div>
            {/*end:: Name */}

            {/*start:: Description */}
            <div className='mb-10'>
              <HookFormEditor
                control={control}
                name='description'
                label='Mô tả'
                skeleton={skeleton}
                math={true}
                defaultValue={defaultEditor}
                height={600}
                required
                disabled={!quizCondition.can_edit_quiz}
                resourceLocation={RESOURCE_LOCATION.COURSE}
                object_id={id ? id : undefined}
                setDataFile={setFileEditor}
              />
            </div>
            {/*end:: Description */}

            <SappLabel label='Case Study' required />
            <CaseStudyTable
              error={errors?.['emptyStory'] as any}
              title='A new integrated case study is part of a range of innovations to the ACCA Qualification'
              story={story}
              setStory={setStory}
              handleChooseStories={() => handleChooseQuestions()}
              confirm={confirm}
              disabled={!quizCondition.can_edit_quiz}
            />
          </div>
        </div>

        <ChooseStoriesDrawer
          story={story}
          setStory={setStory}
          open={openChooseQuestion}
          setOpen={setOpenChooseQuestion}
        ></ChooseStoriesDrawer>
      </div>
    )
  }
)

export default CaseStudyComponent
