import { useState } from 'react'
import { format } from 'date-fns'
import SappBaseTable from 'src/common/SappBaseTable'
import { IClassQuiz, IClassQuizes } from 'src/type/classes'
import { convertQuizType } from 'src/utils'
import ActionCell from 'src/components/base/action/ActionCell'
import ModalTestQuizDetail from './ModalTestQuizDetail'
import { isNull } from 'lodash'
import SappTooltip from 'src/common/SappTooltip'
import AccessTimeModal from './AccessTimeModal'
import { ClassesApi } from 'src/apis/classes'
import toast from 'react-hot-toast'
import { IAccessTime } from './TableTestQuizDetail'

const headers = [
  {
    label: '#',
  },
  {
    label: 'Test Name',
    className: 'min-w-350px',
  },
  {
    label: 'Type',
    className: 'min-w-150px',
  },
  {
    label: 'Mode',
    className: 'min-w-100px',
  },
  {
    label: 'Thời gian mở',
    className: 'min-w-150px',
  },
  {
    label: 'Thời hạn nộp',
    className: 'min-w-150px',
  },
  {
    label: 'Đã làm',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Manual Grading',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Đã chấm',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Thời hạn chấm',
    className: 'min-w-150px',
  },
  {
    label: '',
  },
]

interface IProps {
  quizDetail: IClassQuiz | undefined
  loading: boolean
  fetchTestQuiz: (page_index: number, page_size: number, params?: Object) => void
  filterTestQuiz: Object
  isDuration: boolean
}

const TableTestQuiz = ({
  quizDetail,
  loading,
  fetchTestQuiz,
  filterTestQuiz,
  isDuration,
}: IProps) => {
  const defaultAccessTime = {
    isOpen: false,
    id: '',
    defaultValue: {
      start_time: new Date(),
      end_time: new Date(),
    },
  }
  const [openResult, setOpenResult] = useState(false)
  const [openAccessTime, setOpenAccessTime] = useState<IAccessTime>(defaultAccessTime)
  const [quizId, setQuizId] = useState({
    id: '',
    name: '',
    grading_method: 'AUTO',
  })
  const handleCloseAccessTime = () => setOpenAccessTime(defaultAccessTime)

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchTestQuiz(page_index || 1, page_size || 10, filterTestQuiz)
  }

  const handleOpenQuizDetail = (quiz: IClassQuizes) => {
    setOpenResult(true)
    setQuizId({
      id: quiz.id,
      name: quiz?.quiz?.name,
      grading_method: quiz?.quiz?.grading_method,
    })
  }

  const handleDeleteAccessTime = async (id: string) => {
    try {
      await ClassesApi.deleteAccessTimeTestQuiz({ id })
      await fetchTestQuiz(1, 10)
      handleCloseAccessTime()
      toast.success('Update Access Time successfully!')
    } catch (err) {}
  }

  const updateAccessTime = async (data: { start_time: Date; end_time: Date }) => {
    try {
      await ClassesApi.updateAccessTimeTestQuiz({ id: openAccessTime.id, data })
      await fetchTestQuiz(1, 10)
      handleCloseAccessTime()
      toast.success('Update Access Time successfully!')
    } catch (err) {}
  }

  return (
    <SappBaseTable
      hasCheck={false}
      headers={headers}
      data={quizDetail?.class_quizzes}
      loading={loading}
      // currentPage={quizDetail?.metadata?.page_index || 1}
      // pageSize={quizDetail?.metadata?.page_size || 10}
      totalItems={quizDetail?.metadata?.total_records || 10}
      handlePaginationChange={handlePaginationChange}
      dataResponse={quizDetail}
    >
      {quizDetail?.class_quizzes?.map((quiz, index) => {
        return (
          <tr className='border-0' key={quiz?.id}>
            <td>{index + 1}</td>
            <td className='cursor-pointer' onClick={() => handleOpenQuizDetail(quiz)}>
              <SappTooltip title={quiz?.quiz?.name} link='#' />
            </td>
            <td>{convertQuizType(quiz?.quiz?.quiz_type)}</td>
            <td>{quiz?.mode ?? '--'}</td>
            <td>
              {quiz?.start_time ? format(new Date(quiz?.start_time), 'HH:mm dd/MM/yyyy') : '--'}
            </td>
            <td>{quiz?.end_time ? format(new Date(quiz?.end_time), 'HH:mm dd/MM/yyyy') : '--'}</td>
            <td className='text-center'>{quiz?.total_attempts}</td>
            <td className='text-center'>
              {quiz?.quiz?.grading_method === 'AUTO'
                ? 'No'
                : isNull(quiz?.quiz?.grading_method)
                ? '--'
                : 'Yes'}
            </td>
            <td className='text-center'>{`${
              quiz.quiz?.grading_method === 'MANUAL'
                ? `${quiz?.total_grading_attempts}/${quiz?.total_attempts}`
                : '--'
            }`}</td>
            <td>
              {quiz?.due_date_grade
                ? format(new Date(quiz?.due_date_grade), 'HH:mm dd/MM/yyyy')
                : '--'}
            </td>
            <td className='text-end sapp-absolute-column sapp-classes-column'>
              <ActionCell>
                <div className='menu-item px-3' onClick={() => handleOpenQuizDetail(quiz)}>
                  <div className='menu-link px-3'>Result</div>
                </div>
                {isDuration && (
                  <>
                    <div
                      className='menu-item px-3'
                      onClick={() => {
                        setOpenAccessTime({
                          ...defaultAccessTime,
                          isOpen: true,
                          id: quiz?.id,
                          defaultValue: {
                            start_time: quiz.start_time ? new Date(quiz.start_time) : new Date(),
                            end_time: quiz.end_time ? new Date(quiz.end_time) : new Date(),
                          },
                        })
                      }}
                    >
                      <div className='menu-link px-3'>Edit Access Time</div>
                    </div>
                    <div
                      className={`menu-item px-3 ${
                        !quiz.start_time && quiz.end_time
                          ? 'user-select-none pe-none opacity-50'
                          : ''
                      }`}
                      onClick={() => handleDeleteAccessTime(quiz?.id)}
                    >
                      <div className='menu-link px-3'>Delete Access Time</div>
                    </div>
                  </>
                )}
              </ActionCell>
            </td>
          </tr>
        )
      })}
      <ModalTestQuizDetail
        open={openResult}
        setOpen={setOpenResult}
        quiz_id={quizId}
        startDate={quizDetail?.class_instance?.started_at}
        endDate={quizDetail?.class_instance?.finished_at}
        isDuration={isDuration}
      />
      <AccessTimeModal
        open={openAccessTime}
        onClose={() => setOpenAccessTime(defaultAccessTime)}
        onSaveTime={updateAccessTime}
        startDate={quizDetail?.class_instance?.started_at}
        endDate={quizDetail?.class_instance?.finished_at}
      />
    </SappBaseTable>
  )
}

export default TableTestQuiz
