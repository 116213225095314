import React from 'react'

const SappHeaderDetail = ({
  title,
  titleBreadCrumbs,
  showIcon,
  handleCancel,
}: {
  title: string
  titleBreadCrumbs?: string | undefined
  showIcon?: boolean | undefined
  handleCancel?: () => void | undefined
}) => {
  return (
    <div className='card-header align-items-center'>
      <div className='card-title m-0'>
        {titleBreadCrumbs && (
          <div className='d-flex' style={{ color: '#99A1B7' }}>
            <div className='fw-bold m-0 sapp-fs-18 me-2 cursor-pointer' onClick={handleCancel}>
              {titleBreadCrumbs}
            </div>
            /
          </div>
        )}
        <div className='fw-bold m-0 text-gray-800 sapp-fs-18 ms-2'>{title}</div>
      </div>
      {showIcon && (
        <div
          className='btn btn-sm btn-icon btn-active-color-primary btn-icon_close'
          onClick={handleCancel}
        >
          <i className='ki-outline ki-cross fs-1' />
        </div>
      )}
    </div>
  )
}

export default SappHeaderDetail
