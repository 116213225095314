import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import LoadingTable from 'src/common/LoadingTable'
import { useForm } from 'react-hook-form'
import {
  cleanParamsAPI,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  handleParticipantLevel,
} from 'src/utils'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { replaceValueAll } from 'src/utils/string'
import { IParticipantInTest, IParticipantInTestList } from 'src/type/entrancetest'
import { AddParticipantFilter } from 'src/components/entrance-test/participant/AddParticipantFilter'

type IParams = (
  text: string,
  sortType: string,
  fromDate?: any,
  toDate?: any,
  dateField?: string
) => {
  text: string
  sortType: string
  fromDate?: string | Date
  toDate?: string | Date
  dateField?: string
}

export interface IQueryParams {
  text: string
  sortType: string
  fromDate: string
  toDate: string
  page_index: number
  page_size: number
}

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  participantList: IParticipantInTestList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchParticipants: (currentPage?: number, pageSize?: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: IParams
  queryParams: IQueryParams
  checkedList: string[]
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, memorize?: boolean) => void
  isCheckedAll: boolean
}

// define headers
const headers = [
  {
    label: 'STUDENT ID',
    className: 'min-w-150px',
  },
  {
    label: 'STUDENT NAME',
    className: 'min-w-200px',
  },
  {
    label: 'EMAIL',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'LEVEL',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'PHONE',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'DATE',
    className: 'min-w-100px pe-5',
  },
]

const AddParticipantEventTable = ({
  participantList,
  loading,
  setLoading,
  fetchParticipants,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
}: IProps) => {
  const initialValues = {
    text: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    dateField: '',
  }
  const [searchParams, setSearchParams] = useState<any>(initialValues)

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(searchParams.fromDate)
  const dateQueryToDate = getDateInfo(searchParams.toDate)

  const showSearchParams =
    searchParams.text || searchParams.sortType || searchParams.fromDate || searchParams.toDate

  const fieldNames = ['text', 'sortType', 'fromDate', 'toDate']

  // Using validate for input

  const useFormProp = useForm()

  const { getValues, reset, setValue } = useFormProp

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim(),
        searchParams?.sortType,
        searchParams.fromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        searchParams.toDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : '',
        showSearchParams ? 'updated_at' : 'created_at'
      )
    )
    fetchParticipants(page_index, page_size, cleanedParams)
  }
  useEffect(() => {
    reset()
    setLoading(true)
    fetchParticipants(1, 10)
  }, [])

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName as keyof typeof initialValues])
    })
    const cleanedParams = cleanParamsAPI(getParams('', '', '', '', 'created_at'))
    setSearchParams(initialValues)
    setLoading(true)
    fetchParticipants(1, 10, cleanedParams)
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        replaceValueAll(getValues('text')?.trim()),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )
    setSearchParams(() => {
      return cleanedParams
    })
    setLoading(true)
    fetchParticipants(1, queryParams?.page_size || 10, { ...cleanedParams })
  }

  return (
    <div className='card-body py-5'>
      <AddParticipantFilter
        onSubmit={onSubmit}
        handleResetFilter={handleResetFilter}
        loading={loading}
        useFormProp={useFormProp}
        queryParams={queryParams}
      />
      <SappTable
        headers={headers}
        loading={loading}
        data={participantList?.data}
        isCheckedAll={isCheckedAll}
        hasCheckAll={true}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((header, i) => (
              <LoadingTable key={header} headers={[1, 2, 3, 4, 5]} />
            ))}
          </>
        ) : (
          <>
            {participantList?.data?.map((participant: IParticipantInTest, index: number) => {
              const isChecked = checkedList.includes(participant.id)
              const level = handleParticipantLevel(
                participant,
                participantList?.quiz?.course_category?.name
              )
              return (
                <tr key={participant.id}>
                  <td>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(participant.id!)
                      }}
                    />
                  </td>
                  <td className='text-start'>{participant?.hubspot_contact_id}</td>
                  <td className='text-start'>{participant?.detail?.full_name}</td>
                  <td className='text-start'>{participant?.user_contacts?.[0]?.email}</td>
                  <td className='text-start'>{level}</td>
                  <td className='text-start'>{participant?.user_contacts?.[0]?.phone}</td>
                  <td className='text-start'>
                    <DatetimeColumn
                      created_at={participant?.created_at}
                      updated_at={participant?.updated_at}
                    />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={participantList?.meta?.page_index || 1}
        pageSize={participantList?.meta?.page_size || 10}
        totalItems={participantList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default AddParticipantEventTable
