import { fetcher } from 'src/services/request'
import {
  ICommentRecommendation,
  IQuizAttemptGradingQuestion,
  IQuizAttemptInfoResponse,
  IResponse,
  QuestionGradingResponse,
} from 'src/type/grade'

export class GradeAPI {
  static getQuizAttemptInfo(id: string): Promise<IResponse<IQuizAttemptInfoResponse>> {
    return fetcher(`/quiz-attempt-gradings/${id}`)
  }

  static getQuizAttemptGradingQuestions(
    id: string,
    status?: string
  ): Promise<IResponse<IQuizAttemptGradingQuestion[]>> {
    const statusArray = status ? status.split(',') : []
    const statusParam =
      statusArray.length > 0
        ? `?${statusArray.map((s) => `status=${encodeURIComponent(s.trim())}`).join('&')}`
        : ''
    return fetcher(`/quiz-attempt-gradings/${id}/question-gradings${statusParam}`)
  }

  static updateQuizAttemptFeedback(
    id: string,
    data: { comment: string; recommendation: string }
  ): Promise<IResponse<ICommentRecommendation>> {
    return fetcher(`/quiz-attempt-gradings/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static finishGrading(id: string): Promise<IResponse<null>> {
    return fetcher(`/quiz-attempt-gradings/${id}/finish`, { method: 'PUT' })
  }

  static getQuizAttemptGradingsAnswer(id: string | undefined) {
    return fetcher(`/quiz-attempts/answers/${id}/grading`)
  }

  static getQuestionGrading(id: string): Promise<IResponse<QuestionGradingResponse>> {
    return fetcher(`/quiz-attempt-gradings/question_gradings/${id}`)
  }

  static gradeQuestion(
    id: string,
    data: {
      requirement_id: string | null
      begin: number
      end: number
      comment?: string
      grade?: number | null
    }
  ): Promise<IResponse<QuestionGradingResponse>> {
    return fetcher(`/quiz-attempt-gradings/question-gradings/${id}/grade`, {
      method: 'POST',
      data: data,
    })
  }

  static gradeQuestionByFile(
    id: string,
    data: {
      requirement_id: string | null
      grade: number | null
      grading_file: { file_key: string; file_name: string }
    }
  ): Promise<IResponse<QuestionGradingResponse>> {
    return fetcher(`/quiz-attempt-gradings/question-gradings/${id}/grade-by-file`, {
      method: 'POST',
      data: data,
    })
  }

  static deleteQuestion(
    id: string,
    data: {
      requirement_id: string | null
      begin: number
      end: number
    }
  ): Promise<IResponse<QuestionGradingResponse>> {
    return fetcher(`/quiz-attempt-gradings/question-gradings/${id}/ungrade`, {
      method: 'DELETE',
      data: data,
    })
  }

  static deleteQuestionByFile(
    id: string,
    requirementId: string | null
  ): Promise<IResponse<QuestionGradingResponse>> {
    const requestBody = {
      requirement_id: requirementId,
    }

    return fetcher(`/quiz-attempt-gradings/question-gradings/${id}/ungrade-by-file`, {
      method: 'DELETE',
      data: requestBody,
    })
  }
}
