import withAuthComponents from 'src/components/auth/with-auth-components'
import FolderList from 'src/components/file-manager/FolderList'
import PageLayouts from 'src/components/layout/PageLayouts'
import {PageLink} from 'src/constants'
import {ITabs} from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.RESOURCE_MANAGER}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.RESOURCE_MANAGER}`,
    title: 'Resource Manager',
  },
]

const ResourceManager = () => {
  return (
    <PageLayouts pageTitle='Resource Manager' breadcrumbs={breadcrumbs}>
      <div className=''>
        <FolderList></FolderList>
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ResourceManager)
