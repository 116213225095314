import React from 'react'
import toast from 'react-hot-toast'

import ModalImport from 'src/components/base/upload-file/ModalImport'
import {useQueryResponse} from 'src/components/user-list/components/core/QueryResponseProvider'

interface IModalImportStudentProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  id?: string | undefined
  getClassStudents?: (page_index?: number | undefined, page_size?: number | undefined) => void | any
}

const ModalImportClassStudent = ({
  open,
  setOpen,
  id,
  getClassStudents,
}: IModalImportStudentProps) => {
  const {refetch} = useQueryResponse()

  const beforeUpload = (file: File) => {
    const isExcel =
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    const isCSV = file.type === 'text/csv'
    if (!isExcel && !isCSV) {
      toast.error('You can only upload Excel (.xlsx) or CSV (.csv) files!')
      return false
    }
    if (file.size > 5 * 1024 * 1024) {
      toast.error('The file is too large! It must be less than or equal to 5 MB!')
      return false
    }
    return true
  }
  return (
    <div>
      <ModalImport
        open={open}
        setOpen={setOpen}
        title='Import student'
        templateFile='/CLASS_STUDENT_IMPORT_TEMPLATE.xlsx'
        templateFileName='filemau.xlsx'
        uploadUrl={`classes/${id}/import-student`}
        type='CLASS_STUDENT'
        beforeUpload={(file) => beforeUpload(file)}
        refetch={refetch}
        getData={getClassStudents}
      ></ModalImport>
    </div>
  )
}
export default ModalImportClassStudent
