import { useEffect, useState } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import { Link } from 'react-router-dom'
import { TEACHER_PROFILE } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { ClassesApi } from 'src/apis/classes'
import { cleanParamsAPI } from 'src/utils'
import SappTable from 'src/components/base/SappTable'
import LoadingTable from 'src/common/LoadingTable'
import AddTeacherModal from '../add-teacher/AddTeacherModal'
import { IPart, ITeacherClass, ITeacherClassList } from 'src/type/teacher'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const headers = [
  {
    label: 'Teacher Name',
    key: 'student',
    className: 'fs-7 fw-bold min-w-150px',
  },
  {
    label: 'Phone',
    key: 'phone',
    className: 'fs-7 fw-bold min-w-100px',
  },
  {
    label: 'Email',
    key: 'email',
    className: 'min-w-100px',
  },
  {
    label: 'Be Long To Section',
    key: 'level',
    className: 'min-w-100px',
  },
]

interface IProps {
  title: string
  classId: string
  fetchClass: () => void
  loadTeacher: boolean
}

const ListLecture = ({ title, classId, fetchClass, loadTeacher }: IProps) => {
  const [teacherList, setTeacherList] = useState<ITeacherClassList>()
  const { contextHolder, confirm } = useConfirm()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const getParams = (text: string) => ({
    text,
  })

  const initialValues = {
    text: '',
  }
  const [searchParams, setSearchParams] = useState(initialValues)

  const getClassLectures = async (page_index?: number, page_size?: number, params?: Object) => {
    setLoading(true)
    setTimeout(async () => {
      try {
        const options = { ...params, to_add: false }
        const response = await ClassesApi.getTeacherInClass({
          id: classId,
          page_index: page_index || 1,
          page_size: page_size || 10,
          params: options,
        })

        if (response) {
          setTeacherList(response.data)
        }
      } catch (error) {
        // do nothing
      } finally {
        setLoading(false)
      }
    }) // Delay of 150 milliseconds
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassLectures(page_index, page_size, cleanedParams)
  }

  useEffect(() => {
    getClassLectures().catch((error) => {
      // do nothing
    })
  }, [loadTeacher])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedParams = {
      text: e.target.value.toLowerCase(),
    }
    setSearchParams(() => {
      return cleanedParams
    })
  }

  const handleSearchActive = () => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassLectures(1, teacherList?.metadata.page_size || 10, cleanedParams)
  }

  return (
    <>
      {contextHolder}
      <div className='mb-5 mb-xl-10 flex'>
        <div className='py-8 px-10 '>
          <div
            className={`d-flex justify-content-between align-items-center gap-5 flex-wrap ${
              teacherList?.metadata.total_records ? ' mb-5' : ''
            }`}
          >
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search teacher'}
                smallSearch={true}
                onSubmit={handleSearchActive}
                isListScreen
              ></Search>
              <ButtonPrimary
                title='Search'
                onClick={() => {
                  handleSearchActive()
                }}
                className='ms-4'
                size='small'
              />
            </div>
            <div className='d-flex justify-content-between'>
              <ButtonIconPrimary
                iconName={'plus'}
                title={'Add/Edit Teacher'}
                onClick={() => setOpenModal(true)}
                size='small'
              />
            </div>
          </div>
          <h5 className='fw-bold fs-6 mt-8'>
            {title} ({teacherList?.metadata.total_records || 0})
          </h5>
          <div className='card card-flush border-gray-300 mt-3 sapp-overflow-y-layout'>
            <div className='container'>
              <div className='row px-4 py-8 sapp-overflow-y-layout'>
                <SappTable
                  headers={headers}
                  loading={loading}
                  data={teacherList?.data}
                  isCheckedAll={false}
                  hasCheck={false}
                  onChange={() => {}}
                >
                  {false ? (
                    <>
                      {headers.map((header, i) => (
                        <LoadingTable key={header.label} headers={headers} />
                      ))}
                    </>
                  ) : (
                    <>
                      {teacherList?.data?.map((data: ITeacherClass, idx: number) => {
                        return (
                          <tr className='border-0' key={data?.lecture?.id ?? idx}>
                            <td>
                              <Link
                                className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                                to={`${TEACHER_PROFILE}/${data?.lecture?.id}/overview`}
                              >
                                {data?.lecture?.detail?.full_name}
                              </Link>
                            </td>
                            <td>{data?.lecture?.user_contacts?.[0]?.phone}</td>
                            <td>{data?.lecture?.user_contacts?.[0]?.email}</td>
                            <td className='fs-6 lh-1 fw-semibold sapp-item-column'>
                              <div className='d-flex flex-column gap-2'>
                                {data?.parts?.map((part: IPart) => (
                                  <div className='sapp-text-truncate-2 text-break' key={part.id}>
                                    {part?.name}
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  )}
                </SappTable>
                <PagiantionSAPP
                  currentPage={teacherList?.metadata?.page_index || 1}
                  pageSize={teacherList?.metadata?.page_size || 10}
                  totalItems={teacherList?.metadata?.total_records}
                  handlePaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
          <AddTeacherModal
            classId={classId}
            open={openModal}
            setOpen={setOpenModal}
            // data={teacherList}
            getClassLectures={getClassLectures}
            fetchClass={fetchClass}
          ></AddTeacherModal>
        </div>
      </div>
    </>
  )
}

export default ListLecture
