import {IMeta} from '.'

export interface INotificationGroup {
  created_at: Date
  id: string
  name: string
  updated_at: Date
  users: Array<{id: string}>
}

export interface INotificationGroupAll {
  groups: INotificationGroup[]
  meta: IMeta
}

export interface INotification {
  id?: string
  status?: string
  title: string
  type: string
  action: string
  send_time?: any
  send_finish_time?: string | Date
  content: string
  groups: {
    id: string
    name: string
  }[]
  files?: {
    resource_id: string
    name: string
  }[]
  typeNoti?: string
  typeEmail?: string
  errorType?: string
  typePinned?: string
}
export interface INotificationReq {
  id?: string
  status?: string
  title: string
  type: string
  action: string
  send_finish_time?: string | Date
  content: string
  groups: string[]
  files?: {
    resource_id: string
    name: string
  }[]
}
interface Resource {
  id: string
  name: string
  file_key: string
  stream_url: string | null
  cloudflare_video_id: string | null
  status: string
}

interface Attachment {
  id: string
  created_at: string
  updated_at: string
  deleted_at: string | null
  dom_id: string | null
  type: string
  object_id: string
  resource_id: string
  resource: Resource
}

export type NotificationStatus = 'SENT' | 'CANCEL' | 'RETRIEVE' | 'TIMER' | 'SHOWING' | 'ENDED'
export interface INotificationRes {
  status: NotificationStatus
  id?: string
  title: string
  type: string
  action: string
  send_time: string
  send_finish_time: string
  content: string
  groups: {id: string; name: string}[]
  files?: Attachment[]
}

export enum NOTIFICATION_ACTION {
  TIMER = 'TIMER',
  IMMEDIATE = 'IMMEDIATE',
}

export enum NOTIFICATION_TYPE {
  ALL = 'ALL',
  NOTIFICATION = 'NOTIFICATION',
  EMAIL = 'EMAIL',
  PINNED = 'PINNED',
}
