import { fetcher } from 'src/services/request'
import { IExamPost, IExamDetail, IResponse, ExamListResponse } from 'src/type'

export class ExamAPI {
  static getList({ params }: { params?: Object }): Promise<IResponse<ExamListResponse>> {
    return fetcher(`/examination`, {
      params: params,
    })
  }

  static getDetail({ id }: { id: string }): Promise<IResponse<IExamDetail>> {
    return fetcher(`/examination/${id}`, {
      method: 'GET',
    })
  }

  static create(data: any): Promise<IResponse<any>> {
    return fetcher('examination', {
      method: 'POST',
      data: data,
    })
  }

  static edit({ id, data }: { id: string; data: IExamPost }): Promise<IResponse<any>> {
    return fetcher(`/examination/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static delete(exam_ids: string[]): Promise<IResponse<any>> {
    return fetcher('examination', {
      method: 'DELETE',
      data: {
        exam_ids,
      },
    })
  }
}
