import { useState } from 'react'
import { getAPIUser } from 'src/apis/user'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import { IStudents, IStudentList } from 'src/type/students'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import TableUsers from 'src/components/classes/add-user/tableUsers'
import { ClassesApi } from 'src/apis/classes'
import { useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'

interface IProps {
  open: boolean
  setOpen: any
  type: 'STUDENT'
  getClassStudents: () => void
}
const ModalAddStudentsInClass = ({ open, setOpen, type, getClassStudents }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [userState, setUserState] = useState<IStudentList>()
  const { id } = useParams()
  const getParams = (
    text: string,
    status: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string
  ) => ({
    text,
    status,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })

  const queryParams = {
    text: '',
    status: 'ACTIVE',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }

  const fetchListStudents = (
    type: 'STUDENT',
    page_index?: number,
    page_size?: number,
    params?: any
  ) => {
    if (typeof type === 'string') {
      ;(async () => {
        try {
          const response = await getAPIUser({
            page_index: page_index || 1,
            page_size: page_size || 10,
            type: type,
            params: {
              ...params,
              class: id,
              selectType: 'NOT_SELECTED',
            },
          })
          setUserState(response?.data)
          setLoading(true)
        } catch (error) {
        } finally {
          setLoading(false)
        }
      })()
    }
  }

  const addStudents = async (data?: Object) => {
    const res = await ClassesApi.addStudentInClass(data)
    const invalidStudent = res?.data?.invalid_student
    if (invalidStudent.length > 0) {
      invalidStudent.forEach((errorCode: any) => {
        // Hiển thị thông báo tương ứng với mỗi mã lỗi
        toast.error(`${errorCode?.error_message}: ${errorCode?.students?.join(', ')}`)
      })
    }
  }

  const { checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll } =
    useChecked<IStudents>(userState?.users ?? undefined)

  const { confirm, contextHolder } = useConfirm()

  const handleClose = () => {
    setOpen(false)
    toggleCheckAll(false)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: DESCRIPTION_POPUPCONFIRM,
      onClick: handleClose,
    })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const objectStudentMore = {
        user: checkedList,
        classId: id,
      }
      setOpen(false)

      await addStudents(objectStudentMore)
      await getClassStudents()
      toggleCheckAll(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Add Student</div>
          <div className='d-flex justify-content-between align-items-center'>
            {listDataChecked.length > 0 && (
              <div className='fw-bold fs-6 text-primary me-8'>
                {listDataChecked.length} Selected
              </div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              // loading={loading}
            />
          </div>
        </div>
        <div className='px-7'>
          <TableUsers
            userState={userState}
            loading={loading}
            setLoading={setLoading}
            handleChangeParams={() => {}}
            fetchListUsers={fetchListStudents}
            filterParams={{}}
            getParams={getParams}
            queryParams={queryParams}
            checkedList={checkedList}
            toggleCheck={toggleCheck}
            toggleCheckAll={toggleCheckAll}
            isCheckedAll={isCheckedAll}
            type={type}
          />
        </div>
      </SappModal>
    </>
  )
}
export default ModalAddStudentsInClass
