import { useState, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { ClassesApi } from 'src/apis/classes'

const useGetMentor = (id: string | undefined) => {
  const [mentors, setMentors] = useState<any>({ metadata: {}, data: [] })
  const [loading, setLoading] = useState(false)

  const fetchListUsers = useCallback(
    async (pageIndex = 1, pageSize = 10, params = {}) => {
      const option = { ...params }

      try {
        const res = await ClassesApi.getMentors({
          class_id: id,
          page_index: pageIndex,
          page_size: pageSize,
          params: option,
        })
        return res
      } catch (error) {
        console.error('Error fetching users:', error)
        return { data: { data: [], metadata: {} } }
      }
    },
    [id]
  )

  const handleNextPageMentor = useCallback(
    async (params = {}) => {
      setLoading(true)
      try {
        const { metadata } = mentors
        const totalPages = metadata?.total_pages
        const pageIndex = metadata?.page_index || 1
        const pageSize = metadata?.page_size || 10

        if (totalPages && pageIndex < totalPages) {
          const res = await fetchListUsers(pageIndex + 1, pageSize, params)
          setMentors((prevCourses: any) => ({
            metadata: res?.data?.metadata || prevCourses.metadata,
            data: prevCourses.data.concat(res?.data?.data || []),
          }))
        }
      } finally {
        setLoading(false)
      }
    },
    [mentors, fetchListUsers]
  )

  const getMentors = useCallback(
    async (params = {}) => {
      setLoading(true)
      try {
        const res = await fetchListUsers(1, 20, params)
        setMentors(res?.data || { metadata: null, data: [] })
      } finally {
        setLoading(false)
      }
    },
    [fetchListUsers]
  )

  const debounceSearchCourse = useCallback(
    debounce((searchText) => {
      getMentors({ text: searchText })
    }, 500),
    [getMentors]
  )

  return {
    mentors,
    loading,
    handleNextPageMentor,
    getMentors,
    debounceSearchCourse,
  }
}

export default useGetMentor
