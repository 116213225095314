import React, {Dispatch, SetStateAction, useEffect} from 'react'
// import StockReport from 'src/components/dashboard/StockReport'
// import Application from 'src/components/my-profile/overview-profile/Application'
// import ProductDelivery from 'src/components/my-profile/overview-profile/ProductDelivery'
// import TopSelling from 'src/components/my-profile/overview-profile/TopSelling'
import {IStudentDetail} from 'src/type/students'
import StaffProfileDetail from './StaffProfileDetail'

interface IProps {
  staffDetail: IStudentDetail | undefined
  loading: boolean
  setStaffDetail: Dispatch<SetStateAction<any>>
}

const OverviewStaffProfile = ({staffDetail, loading, setStaffDetail}: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <StaffProfileDetail
        staffDetail={staffDetail}
        loading={loading}
        setStaffDetail={setStaffDetail}
      />
      <div className='row gy-5 g-xl-10'>
        {/* <TopSelling /> */}
        {/* <Application /> */}
      </div>
      {/* <div className='row gy-5 g-xl-10'> */}
      {/* <ProductDelivery /> */}
      {/* <StockReport /> */}
      {/* </div> */}
    </>
  )
}

export default OverviewStaffProfile
