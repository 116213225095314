import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { GradeAPI } from 'src/apis/grade'
import { DEFAULT_ATTEMPT_DATA, STATUS_ALL } from 'src/constants/grade'
import { LANG_GRADE_PAGE } from 'src/constants/lang'
import { IAttemptAnswerData, IFilterTabProps, IQuizAttemptInfo } from 'src/type/grade'
import FilterOptions from './FilterOptions'
import TabSlides from './TabSlides'
import DropDownActions from './DropDownActions'
import clsx from 'clsx'
import { convertFractionalScore, formatGrade } from 'src/utils/grade'

interface IAttemptInfoProps {
  quizInfo: IQuizAttemptInfo
  essayScores: number
  attemptAnswerData: IAttemptAnswerData
  handleChangeAttemptData: (data: IAttemptAnswerData) => void
  setOpenRecommendation: Dispatch<SetStateAction<boolean>>
  filteredTabs: IFilterTabProps[]
  setFilteredTabs: Dispatch<SetStateAction<IFilterTabProps[]>>
}

const AttemptInfo = ({
  quizInfo,
  essayScores,
  attemptAnswerData,
  handleChangeAttemptData,
  setOpenRecommendation: setOpenRecommendation,
  filteredTabs,
  setFilteredTabs,
}: IAttemptInfoProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openGrid, setOpenGrid] = useState<boolean>(false)
  const [selectedFilter, setSelectedFilter] = useState<string[]>([])

  // Handle filter change
  const handleFilterChange = (value: string[]) => {
    setSelectedFilter(value)
  }

  // Show attempt detail on selected tab
  const onChangeQuestionAttempt = (data: IFilterTabProps) => {
    handleChangeAttemptData({
      id: data?.id || '',
      attempt_answers: data?.attempt_answers || [],
      is_present: true,
      index: data?.index || 0,
      question_id: data?.question_id || '',
    })
  }

  // Get questions API
  const getQuizAttemptQuestions = async () => {
    try {
      setIsLoading(true)
      const res = await GradeAPI.getQuizAttemptGradingQuestions(
        quizInfo?.gradeId || '',
        selectedFilter.join(',')
      )

      if (res.success && res.data) {
        let currentAttemptData = attemptAnswerData.id ? attemptAnswerData : DEFAULT_ATTEMPT_DATA
        let isPresent = false

        const filteredTabs = res.data.map((question) => {
          // Init status of the item
          const status = question.graded
            ? question.is_correct
              ? STATUS_ALL.GRADED.value
              : STATUS_ALL.INCORRECT.value
            : STATUS_ALL.UNGRADED.value
          const id = (question.attempt_answers && question.attempt_answers[0]?.id) || ''
          const attempt_answers = question.attempt_answers.map((item) => item.id)
          const question_id = (question && question?.id) || ''

          if (id === attemptAnswerData.id) {
            currentAttemptData.id = id
            currentAttemptData.attempt_answers = attempt_answers
            isPresent = true
          }

          return {
            id: id,
            status: status,
            attempt_answers: attempt_answers,
            index: question.index,
            question_id: question_id,
          }
        })

        currentAttemptData.is_present = isPresent

        if (!currentAttemptData.id && filteredTabs.length) {
          currentAttemptData = {
            id: filteredTabs[0].id,
            attempt_answers: filteredTabs[0].attempt_answers,
            index: filteredTabs[0].index,
            is_present: true,
            question_id: filteredTabs[0].question_id,
          }
        }

        setFilteredTabs(filteredTabs)
        handleChangeAttemptData({ ...currentAttemptData })
      } else {
        console.error(res)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    quizInfo.gradeId && getQuizAttemptQuestions()
  }, [quizInfo, selectedFilter])

  return (
    <>
      <div className='grade-header d-flex justify-content-between grade-bg-2 grade-px-24 grade-py-8'>
        <div className='text-white fs-4 grade-lh-27'>{quizInfo.quizName}</div>
        <div className='text-white fs-4 grade-lh-27'>
          {LANG_GRADE_PAGE.total}:{' '}
          <strong>
            {convertFractionalScore(quizInfo?.multipleChoiceScores + (essayScores || 0))}
          </strong>{' '}
          {`= `}
          {LANG_GRADE_PAGE.multipleChoice}:{' '}
          <strong>{formatGrade(quizInfo?.multipleChoiceScores)}</strong> {`+ `}
          {LANG_GRADE_PAGE.constructed}: <strong>{formatGrade(essayScores) ?? '_ _'}</strong>
        </div>
        <DropDownActions
          openGrid={openGrid}
          setOpenGrid={setOpenGrid}
          setOpenRecommendation={setOpenRecommendation}
          status={quizInfo?.status || ''}
        />
      </div>
      <div className={clsx('sapp-tabslides-wrapper', { open: openGrid })}>
        <TabSlides
          isLoading={isLoading}
          currentTabId={attemptAnswerData?.id || ''}
          filteredTabs={filteredTabs}
          openGrid={openGrid}
          handleChangeTab={onChangeQuestionAttempt}
        />
        <FilterOptions
          selectedFilter={selectedFilter}
          openGrid={openGrid}
          onFilterChange={handleFilterChange}
        />
      </div>
    </>
  )
}

export default AttemptInfo
