import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import {StaffListWrapper} from 'src/components/staff-list/StaffList'
import {PageLink} from 'src/constants'
import {ITabs} from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.STAFFS}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'List Staffs',
  },
]

const ListStaff = () => {
  return (
    <PageLayouts pageTitle='List Staffs' breadcrumbs={breadcrumbs}>
      <div className='card'>
        <StaffListWrapper />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ListStaff)
