import { useParams } from 'react-router-dom'
import PageLayouts from 'src/components/layout/PageLayouts'
import CreateUpdateGroup from 'src/components/supporter-group/CreateUpdateGroup'

const CreateUpdateSupporter = () => {
  const { id } = useParams()

  return (
    <PageLayouts
      pageTitle=''
      titleTab={`${id ? 'Update Group' : 'Create Group'}`}
      breadcrumbs={[]}
      backgroudColor='bg-white'
      showSideBar={false}
      removePadding={true}
      showFooter={false}
    >
      <CreateUpdateGroup />
    </PageLayouts>
  )
}
export default CreateUpdateSupporter
