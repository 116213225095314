import './Badge.scss'

type IBadge = {
  className?: string
  label?: string
}

function SAPPBadge({className, label}: IBadge) {
  return (
    <div className='align-items-center'>
      <span className={`badge badge-${className} fs-base px-4 fw-semibold sapp-bage`}>{label}</span>
    </div>
  )
}

export default SAPPBadge
