import { Helmet } from 'react-helmet'

interface IProps {
  title?: string
}

const SappHelmet = ({ title }: IProps) => {
  return (
    <Helmet>
      <title>{title || ''} - SAPP Academy</title>
      <meta
        name='description'
        content='Hệ thống Nền tảng Học và Thi trực tuyến được SAPP Academy xây dựng nhằm mục đích cung cấp trải nghiệm học tập hiện đại, cá nhân hóa, giúp học viên tối ưu kết quả học tập ACCA, CFA'
      />
      <meta name='keywords' content='sapp, lms, acca, ACCA, CFA, Big4, 3P, SAPP Academy' />
      <meta property='og:type' content='website' />
      <meta
        property='og:title'
        content='Hệ thống Quản lý học và thi ACCA, CFA trực tuyến SAPP Academy'
      />
      <meta property='og:site_name' content='lms.sapp.edu.vn' />
      <meta property='og:image' content='https://lms.sapp.edu.vn/images/sapp.svg' />
      <meta
        property='twitter:title'
        content='Hệ thống Quản lý học và thi ACCA, CFA trực tuyến SAPP Academy'
      />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@lms.sapp.edu.vn' />
      <meta name='twitter:image' content='https://lms.sapp.edu.vn/images/sapp.svg' />
      <meta name='twitter:image:src' content='https://lms.sapp.edu.vn/images/sapp.svg' />
      <meta
        name='twitter:description'
        content='Hệ thống Quản lý học và thi ACCA, CFA trực tuyến SAPP Academy'
      />
      <meta content='Hệ thống Nền tảng Học và Thi trực tuyến được SAPP Academy xây dựng nhằm mục đích cung cấp trải nghiệm học tập hiện đại, cá nhân hóa, giúp học viên tối ưu kết quả học tập ACCA, CFA' />
    </Helmet>
  )
}

export default SappHelmet
