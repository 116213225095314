import {useEffect, useRef, useState} from 'react'
import {ResourceAPI} from 'src/apis/resource-bank'
import LoadingTable from 'src/common/LoadingTable'
import useChecked from 'src/hooks/use-checked'
import {IResource, IResourceList, ISearchResources} from 'src/type/resource'
import SappModal from '../base/SappModal'
import SappTable from '../base/SappTable'
import PagiantionSAPP from '../base/pagination/PagiantionSAPP'
import SAPPRadio from '../base/radiobutton/SAPPRadio'
import {KTIcon} from 'src/_metronic/helpers'

type IProps = {
  openChoiceFolder?: {
    open: boolean
    id: string
    parentId?: string
  }
  handleCloseChoiceFolder: () => void
  handleSaveChoiceFolder: (checkedList: IResource[], resourceId?: string) => void
}

const MoveToFolderModal = ({
  openChoiceFolder,
  handleCloseChoiceFolder,
  handleSaveChoiceFolder,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [resourceList, setResourceList] = useState<IResourceList>()
  const {listDataChecked, checkedList, toggleCheck, toggleCheckAll} = useChecked<IResource>(
    resourceList?.resources
  )
  const parentRef = useRef<IResource>()

  const [parentList, setParentList] = useState<
    {
      name: string
      id: string
      parent_id: string
    }[]
  >([])

  useEffect(() => {
    if (openChoiceFolder?.open) {
      fetchGetResource({page_index: 1, page_size: 10, parent_id: null})
    }
    toggleCheckAll(false)
    setParentList([])
    setResourceList(undefined)
    parentRef.current = undefined
  }, [openChoiceFolder?.open])

  const fetchGetResource = async (
    {page_index, page_size, parent_id}: ISearchResources,
    resource?: IResource
  ) => {
    if (parent_id) {
      parentRef.current = resource
    }
    if (parent_id === undefined) {
      parent_id = resource?.id
    }
    try {
      setLoading(true)
      const response = await ResourceAPI.getResource({
        page_index,
        page_size,
        resource_type: 'FOLDER',
        parent_id: parent_id || null,
      })
      if (response.success) {
        setResourceList(response.data)
      }
    } finally {
      setLoading(false)
    }
  }
  const handlePaginationChange = (page_index: 1, page_size: 10) => {
    fetchGetResource({page_index, page_size, parent_id: parentList?.[0]?.id})
  }
  const handleSubmitChoiceFolder = () => {
    let parent: IResource[] = []
    if (!resourceList?.resources?.[0] && parentRef.current) {
      parent = [parentRef.current]
    } else {
      parent = listDataChecked
    }
    if (!parent?.[0] && parentRef.current) {
      parent = [parentRef.current]
    }
    handleSaveChoiceFolder(parent, openChoiceFolder?.id)
  }
  const handleMoveIntoFolder = async (resource: IResource) => {
    try {
      await fetchGetResource(
        {
          page_index: 1,
          page_size: 10,
          parent_id: resource.id,
        },
        resource
      )
      toggleCheckAll(false)
      const parentsResponse = await ResourceAPI.getResourceParents(resource.id)
      if (parentsResponse.success) {
        setParentList(parentsResponse.data)
      }
    } catch (error) {}
  }

  return (
    <div>
      <SappModal
        open={openChoiceFolder?.open}
        handleClose={handleCloseChoiceFolder}
        title={'Move To Folder'}
        handleSubmit={handleSubmitChoiceFolder}
        cancelButtonCaption='Cancel'
        okButtonCaption='Save'
        dialogClassName='mw-950px modal-dialog-centered'
        confirmOnclose
        disabled={parentList?.[0]?.id === openChoiceFolder?.parentId}
        confirmOnSubmit
        confirmSubmitMessage='Bạn có chắc chắn muốn di chuyển vào folder này?'
      >
        <SappTable
          headers={[
            {label: '', className: 'w-50px'},
            {
              label: 'NAME',
            },
          ]}
          loading={loading}
          data={resourceList?.resources}
          isCheckedAll={false}
          onChange={() => {}}
          hasCheckAll={false}
        >
          {loading ? (
            <>
              {/* {MOCKUP_HEADER.map((header, i) => ( */}
              <LoadingTable key={1} headers={[]} />
              <LoadingTable key={2} headers={[]} />
              <LoadingTable key={3} headers={[]} />
              {/* ))} */}
            </>
          ) : (
            <>
              {resourceList?.resources?.map((resource: any) => {
                const isChecked = checkedList.includes(resource.id)
                return (
                  <tr key={resource?.id}>
                    <td>
                      <SAPPRadio
                        checked={isChecked}
                        ktCheck={isChecked}
                        onChange={() => {
                          toggleCheckAll(false)
                          toggleCheck(resource.id)
                        }}
                        disabled={
                          resource.id === openChoiceFolder?.id ||
                          resource.id === openChoiceFolder?.parentId
                        }
                      />
                    </td>
                    <td>
                      {resource.resource_type === 'FOLDER' ? (
                        <KTIcon
                          iconName='folder'
                          iconType='outline'
                          className='sapp-fs-2.2rem text-primary me-4'
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                    {resource.id === openChoiceFolder?.id ? (
                      <td className='text-start min-w-350px text-gray-500'>{resource.name}</td>
                    ) : (
                      <td
                        className='text-start min-w-350px text-hover-primary sapp-cursor-pointer'
                        onClick={() => {
                          handleMoveIntoFolder(resource)
                        }}
                      >
                        {resource.name}
                      </td>
                    )}
                  </tr>
                )
              })}
            </>
          )}
        </SappTable>
        <PagiantionSAPP
          currentPage={resourceList?.meta?.page_index || 1}
          pageSize={resourceList?.meta?.page_size || 10}
          totalItems={resourceList?.meta?.total_records}
          handlePaginationChange={handlePaginationChange}
        />
      </SappModal>
    </div>
  )
}

export default MoveToFolderModal
