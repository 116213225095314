import { useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import { TEST_TYPE } from 'src/components/courses/shared/Interfaces'
import TestComponent, { TestChildRef } from 'src/components/courses/test/TestComponent'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'

const Test = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const params = useParams()
  const { state } = useLocation()
  let title = params?.editId ? 'Edit' : 'Create'
  switch (params.type?.toUpperCase()) {
    case TEST_TYPE.MID_TERM_TEST:
      title += ' MidTerm Test'
      break
    case TEST_TYPE.FINAL_TEST:
      title += ' Final Test'
      break
    case TEST_TYPE.TOPIC_TEST:
      title += ' Part/ Topic Test'
      break
    case TEST_TYPE.CHAPTER_TEST:
      title += ' Chapter/ Module Test'
      break
    case TEST_TYPE.MOCK_TEST:
      title += ' Mock Test'
      break
    default:
      break
  }

  const childRef = useRef<TestChildRef>(null)
  return (
    <CreateEditLayout
      pageTitle={title}
      onCancel={() => childRef?.current?.onCancel()}
      onSave={() => childRef?.current?.onSubmit()}
      loading={loading}
    >
      {((params.type && params.id && params.testFor) || params.editId) && (
        <TestComponent
          type={params.type as TEST_TYPE}
          id={params.id || ''}
          testFor={params.testFor || ''}
          editId={params.editId}
          editOnlyName={state?.onlyEditName}
          ref={childRef}
          setLoading={setLoading}
        />
      )}
    </CreateEditLayout>
  )
}

export default withAuthComponents(Test)
