export const monthsOfYear = Array.from({ length: 12 }, (_, i) => {
  const value = (i + 1).toString().padStart(2, '0') // '01', '02', etc.
  return { label: value, value }
})

export enum SUBJECT {
  ACCA = 'ACCA',
  CFA = 'CFA',
  CMA = 'CMA',
}
