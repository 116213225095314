import toast from 'react-hot-toast'

export const filterFilesInTextEditor = (html: string, files: any[]): any[] => {
  let temp = document.createElement('div')
  temp.innerHTML = html

  let result: any[] = []
  let media = temp.querySelectorAll('img, source, video[poster]')

  let fileMap: {[key: string]: any} = {}

  for (let file of files) {
    fileMap[file.dom_id] = file
  }

  for (let element of media) {
    let src =
      (element as HTMLSourceElement | HTMLVideoElement).getAttribute('src') ||
      (element as HTMLVideoElement).getAttribute('poster')
    if (src) {
      let file = fileMap[src]
      if (file) {
        result.push(file)
      }
    }
  }
  return result
}

export const mergeEditorToImage = (html: string, files: any[]): string => {
  const div = document.createElement('div')
  div.innerHTML = html

  const media = div.querySelectorAll('img, video source, video[poster]') as NodeListOf<
    HTMLImageElement | HTMLSourceElement | HTMLVideoElement
  >

  const urls = files.reduce((obj, file) => {
    if (file.url) {
      obj[file.url] = file
    }
    return obj
  }, {})
  for (const element of media) {
    const src = element.getAttribute('src') || element.getAttribute('poster')
    if (src) {
      const file = urls[src]
      if (file) {
        if (element.hasAttribute('src')) {
          element.setAttribute('src', file.dom_id)
        } else if (element.hasAttribute('poster')) {
          element.setAttribute('poster', file.dom_id)
        }
      }
    }
  }
  return div.innerHTML
}

export const mergeImageToEditor = async (data: string, files: any[]) => {
  // const div = document.createElement('div')
  // div.innerHTML = data || ''

  // const media = div.querySelectorAll('img, video source, video[poster]') as NodeListOf<
  //   HTMLImageElement | HTMLSourceElement | HTMLVideoElement
  // >
  // for (const element of media) {
  //   const src = element.getAttribute('resource_id')
  //   if (src && element.tagName === 'VIDEO') {
  //     const res = await ResourcesAPI.getUrl(src)
  //     if (element.hasAttribute('src')) {
  //       element.setAttribute('src', res.data.url || '')
  //     } else if (element.hasAttribute('poster')) {
  //       element.setAttribute('poster', res.data.thumbnail || '')
  //     }

  //     const source = element.querySelector('source')
  //     source?.setAttribute('resource_status', res.data?.status || '')
  //     if (source?.hasAttribute('src')) {
  //       source?.setAttribute('src', res.data.url || '')
  //     } else if (source?.hasAttribute('poster')) {
  //       source?.setAttribute('poster', res.data.thumbnail || '')
  //     }
  //   }
  // }
  // return div.innerHTML
  return data
}

type FileObject = {
  dom_id: string
  value: File
  contentType: string
}

type ReturnObject = {
  description: string
  listPayload: FileObject[]
  tempDescription: string
}

export const convertDescription = async (text: string, uuid: any): Promise<ReturnObject> => {
  let description: string = text
  let listPayload: FileObject[] = []
  let tempDescription: string = text

  let element: HTMLElement = document.createElement('div')
  element.innerHTML = text

  let blobs: NodeListOf<HTMLElement> = element.querySelectorAll("[src^='blob:'], [poster^='blob:']")

  if (blobs.length > 0) {
    for (let blob of blobs) {
      let blobUrl: string = blob.getAttribute('src') || blob.getAttribute('poster') || ''

      let blobId: string = `${Date.now()}-${uuid()}`

      if (blob.tagName === 'VIDEO') {
        blob.setAttribute('poster', blobId)
      } else {
        blob.setAttribute('src', blobId)
      }

      let response = await fetch(blobUrl)
      let buffer = await response.blob()
      let contentType: string = response.headers.get('Content-Type') || ''

      let file: File = new File([buffer], 'file', {type: contentType})

      let fileObject: FileObject = {dom_id: blobId, value: file, contentType}

      listPayload.push(fileObject)
    }

    description = element.innerHTML
  }

  return {description, listPayload, tempDescription}
}

export const validateFile = (
  file: any,
  acceptFiles?: {type?: string; size?: number}[],
  toastId?: string
): boolean => {
  const fileType = file.contentType || file.type
  const fileSize = file.size

  if (!acceptFiles) return true

  const acceptedTypes = acceptFiles.map((file) => file.type)

  if (acceptedTypes.length > 0) {
    if (
      !acceptedTypes.some((type) =>
        type?.endsWith('*') ? fileType.startsWith(type.split('/')[0]) : fileType === type
      )
    ) {
      toast.error(
        <span className='sapp-text-truncate-1'>
          File <span className='fw-semibold'>{file.name} </span> không được hỗ trợ.
        </span>,
        {...(toastId && {id: toastId})}
      )
      return false
    }
  }

  const maxFileSize =
    acceptFiles.find((acceptFile) =>
      acceptFile?.type?.endsWith('*')
        ? fileType.startsWith(acceptFile.type.split('/')[0])
        : fileType === acceptFile.type
    )?.size || 0

  if (maxFileSize > 0 && fileSize > maxFileSize) {
    toast.error(
      <span>
        File <span className='fw-semibold'>{file.name} </span> quá lớn.
      </span>,
      {...(toastId && {id: toastId})}
    )
    return false
  }

  return true
}
