import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { ITabs, Role } from 'src/type'
import withAuthComponents from 'src/components/auth/with-auth-components'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { KTIcon } from 'src/_metronic/helpers'
import { ResourcesAPI } from 'src/apis/resources'
import toast from 'react-hot-toast'
import { useState } from 'react'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_ADMIN_GR } from 'src/constants/permission'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.VIDEO}`,
    title: 'Video Management',
  },
]

const Video = () => {
  const [isProcessing, setIsProcessing] = useState(false)
  const handleReprocessVideo = async () => {
    try {
      const res = await ResourcesAPI.processStreamVideo()
      if (res.data) {
        setIsProcessing(true)
        setTimeout(() => {
          toast.success(`Reprocessing stream for ${res.data} video`)
          setIsProcessing(false)
        }, 3000)
      } else {
        toast.success('No data to be processed')
      }
    } catch (error) {}
  }

  // * Check phân quyền
  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderReprocess = hasPermission(
    profileMe?.roles,
    TITLE_ADMIN_GR.REPROCESS_LEARN_TRIAL_TICKET_FAIL ||
      TITLE_ADMIN_GR.REPROCESS_ENTRANCE_TEST_TICKET_FAIL
  )

  return (
    <PageLayouts pageTitle='Video Management' breadcrumbs={breadcrumbs}>
      {allowRenderReprocess && (
        <div className='row row-cols-1 row-cols-md-1 pt-10'>
          <div className='sapp-table-min-height'>
            <div className='card h-md-30'>
              <div className='card-body d-flex justify-content-between flex-center'>
                <div>
                  <h1>Reprocess video</h1>
                  <p>
                    Chức năng dùng để xử lý lại những video không được xử lý (status = CREATED) hoặc
                    những video bị lỗi (status = ERROR) <br></br>
                    Khi thực hiện sẽ lấy ra tất cả những video đang bị lỗi để đẩy cho video-service
                    xử lý lại
                  </p>
                </div>
                <div>
                  <ButtonIconPrimary
                    onClick={handleReprocessVideo}
                    iconName='arrows-circle'
                    title='Reprocess Video'
                    iconType='solid'
                    className='pe-3'
                    loading={isProcessing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </PageLayouts>
  )
}

export default withAuthComponents(Video)
