import React, {Dispatch, SetStateAction} from 'react'
// import EmailPreferences from 'src/components/my-profile/setting-profile/EmailPreferences'
// import Notifications from 'src/components/my-profile/setting-profile/Notifications'
import {IStudentDetail} from 'src/type/students'
import {useParams} from 'react-router-dom'
import StudentProfileSecurity from './SecurityProfile'

interface IProps {
  studentDetail: IStudentDetail | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setStudentDetail: Dispatch<SetStateAction<any>>
}

const SecurityStudent = ({studentDetail, setLoading, loading, setStudentDetail}: IProps) => {
  const {id} = useParams()

  return (
    <>
      <StudentProfileSecurity
        studentDetail={studentDetail}
        loading={loading}
        setStudentDetail={setStudentDetail}
        setLoading={setLoading}
        id={id}
      />
    </>
  )
}

export default SecurityStudent
