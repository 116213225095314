import { IUpdateStaff } from 'src/pages/staffs/CreateAndUpdateStaff'
import { fetcher, fetchFormData } from 'src/services/request'
import { IRolesAll } from 'src/type/staffs.'
import { VerifyEmailOtp } from './type'

//TÔDO: đang dùng API này với 1 API tương tự ở màn List Staff, check lại và sửa nố
export const getStaffAPI = (query: string) => {
  return fetcher(`/staffs?${query}`)
}

export class StaffAPI {
  static update({ id, data }: { id: string | undefined; data: IUpdateStaff }): Promise<IRolesAll> {
    return fetcher(`/staffs/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static updateRoles({
    id,
    role_ids,
  }: {
    id: string | undefined
    role_ids: Array<string>
  }): Promise<IRolesAll> {
    return fetcher(`/staffs/${id}/assign-permissions`, {
      method: 'PUT',
      data: {
        role_ids,
      },
    })
  }

  static blocked = async (id: Array<string> | string) => {
    return fetcher(`staffs/block`, {
      method: 'PATCH',
      data: {
        staffIds: id,
      },
    })
  }

  static resetPassword(id: string): Promise<void> {
    return fetcher(`staffs/${id}/reset-password`, {
      method: 'PATCH',
    })
  }

  static detail(id: string | undefined): Promise<any> {
    return fetcher(`/staffs/${id}`)
  }

  static forgotPassword(email: string): Promise<any> {
    return fetcher(`staffs/send-forgot-password-otp`, {
      method: 'POST',
      data: {
        type: 'FORGOT_PASSWORD',
        email,
      },
    })
  }

  static verifyEmailOTP(email: string, code: string): Promise<any> {
    return fetcher(`staffs/verify-email-otp`, {
      method: 'POST',
      data: {
        actor_type: 'STAFF',
        type: 'FORGOT_PASSWORD',
        code,
        email,
      },
    })
  }

  static changePassword(password: string, code: string, email: string): Promise<any> {
    return fetcher(`staffs/change-password`, {
      method: 'PATCH',
      data: {
        password,
        code,
        email,
      },
    })
  }

  static changeEmail(id: string | undefined, email: string, code: string): Promise<any> {
    return fetcher(`staffs/${id}/change-email`, {
      data: {
        email,
        code,
      },
      method: 'PATCH',
    })
  }

  static sendOTP(id: string | undefined): Promise<VerifyEmailOtp> {
    return fetcher(`staffs/${id}/send-change-email-otp`, {
      method: 'POST',
    })
  }
}

export const createStaffs = ({
  username,
  password,
  email,
  phone,
  full_name,
  status,
  roles,
  sex,
}: {
  username: string
  password: string
  email: string
  phone: number
  full_name: string
  status?: string
  roles: Array<{ id: string }>
  sex: string
}) => {
  return fetcher(`/staffs`, {
    method: 'POST',
    data: {
      username,
      password,
      email,
      phone,
      full_name,
      status,
      roles,
      sex,
    },
  })
}

export const uploadAvatarStaff = ({
  staffId,
  avatar,
}: {
  staffId: string | undefined
  avatar: File
}) => {
  const formData = new FormData()
  if (staffId) {
    formData.append('staffId', staffId)
  }
  formData.append('avatar', avatar)
  return fetchFormData({ url: `/staffs/detail/upload`, formData })
}

export const getAPIStaffs = (page_index: number, page_size: number | undefined, params: Object) => {
  return fetcher(`/staffs?page_index=${page_index}&page_size=${page_size}`, {
    params: params,
  })
}

export const getAPIStaffsExport = (
  page_index: number,
  page_size: number | undefined,
  params: Object
) => {
  return fetcher(`/staffs/export/data?page_index=${page_index}&page_size=${page_size}`, {
    params: params,
  })
}
