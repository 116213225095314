import { Dispatch, SetStateAction } from 'react'
import { Link } from 'react-router-dom'
import ItemProfile from 'src/components/base/ItemProfile'
import { FORMAT_DATETIME, PageLink } from 'src/constants'
import { IStudentDetail } from 'src/type/students'
import LoadingProfileDetail from './loading/LoadingProfileDetail'
import { format } from 'date-fns'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import HeaderTab from 'src/components/base/HeaderTab'

interface IProps {
  userDetail: IStudentDetail | undefined
  loading: boolean
  setProfile: Dispatch<SetStateAction<any>>
}

const ProfileDetail = ({ userDetail, loading }: IProps) => {
  return (
    <>
      {loading ? (
        <LoadingProfileDetail />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab
            title='Profile Details'
            titleButton='Edit Profile'
            link={PageLink.PROFILE_SETTING}
          />

          <div className='card-body p-9'>
            <ItemProfile title='Code' body={userDetail?.key} />
            <ItemProfile title={LANG_PLACEHOLDER.FULLNAME} body={userDetail?.detail?.full_name} />
            <ItemProfile title='Username' body={userDetail?.username} />
            <ItemProfile title='Email' body={userDetail?.detail?.email} />
            <ItemProfile title='Phone Number' body={userDetail?.detail?.phone} />
            <ItemProfile title='Role' roles={userDetail?.roles} />
            <ItemProfile title='Status' status={userDetail?.status} />
            <ItemProfile
              title='Updated At'
              body={
                userDetail?.updated_at
                  ? format(new Date(userDetail?.updated_at), FORMAT_DATETIME)
                  : ''
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileDetail
