import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import {StoryListWrapper} from 'src/components/story-list/StoryList'
import {PageLink} from 'src/constants'
import {ITabs} from 'src/type'
import {LANG_SIDEBAR} from 'src/constants/lang'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.STORY}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.STORY}`,
    title: `${LANG_SIDEBAR.listStories}`,
  },
]

const ListStory = () => {
  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.listStories} breadcrumbs={breadcrumbs}>
      <StoryListWrapper />
    </PageLayouts>
  )
}

export default withAuthComponents(ListStory)
