import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { StaffAPI } from 'src/apis/staffs'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import Card from 'src/components/base/Card'
import RenderStaffProfile from 'src/components/staff-profile/RenderStaffProfile'
import { PageLink, STAFF_PROFILE } from 'src/constants'
import { ITabs, Role } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import { divide, round } from 'lodash'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN } from 'src/constants/permission'

const StaffProfile = () => {
  const { id } = useParams()

  const breadcrumbs: ITabs[] = [
    {
      link: `${STAFF_PROFILE}/${id}/overview`,
      title: 'LMS',
    },
    {
      link: `${PageLink.STAFFS}`,
      title: 'List Staffs',
    },
    {
      link: `${PageLink.AUTH_LOGIN}`,
      title: 'Staff Profile',
    },
  ]

  const [loading, setLoading] = useState(true)
  const [staffDetail, setStaffDetail] = useState<IStudentDetail>()

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${STAFF_PROFILE}/${id}/overview`,
      title: 'Overview',
    },
    {
      link: `${STAFF_PROFILE}/${id}/setting`,
      title: 'Setting',
    },
    // {
    //   link: `${STAFF_PROFILE}/${id}/security`,
    //   title: 'Security',
    // },
    // {
    //   link: `${STAFF_PROFILE}/${id}/activity`,
    //   title: 'Activity',
    // },
    // {
    //   link: `${STAFF_PROFILE}/${id}/billing`,
    //   title: 'Billing',
    // },
    // {
    //   link: `${STAFF_PROFILE}/${id}/statements`,
    //   title: 'Statements',
    // },
    // {
    //   link: `${STAFF_PROFILE}/${id}/referrals`,
    //   title: 'Referrals',
    // },
    // {
    //   link: `${STAFF_PROFILE}/${id}/logs`,
    //   title: 'Logs',
    // },
  ]

  const getStaffDetail = async () => {
    setLoading(true)
    try {
      const res = await StaffAPI.detail(id)
      setStaffDetail(res?.data)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!loading) return
    getStaffDetail()
  }, [loading])

  const inputFields = [
    staffDetail?.key,
    staffDetail?.detail?.full_name,
    staffDetail?.username,
    staffDetail?.detail?.email,
    staffDetail?.detail?.phone,
    staffDetail?.status,
    staffDetail?.roles,
    staffDetail?.files?.[0]?.file_url,
  ]

  const countNonEmptyFields = () => {
    return inputFields.filter((input) => input !== undefined).length
  }
  const { profileMe } = useUserContext()

  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  // const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  // const filteredTabs = allowRenderEdit ? tabs : tabs.filter((tab) => tab.title !== 'Setting')

  return (
    <PageLayouts pageTitle='Staff Profile' breadcrumbs={breadcrumbs}>
      <Card
        userDetail={staffDetail}
        tabs={tabs}
        loading={loading}
        progress={round(divide(countNonEmptyFields(), inputFields.length), 2)}
      />
      <RenderStaffProfile
        staffDetail={staffDetail}
        setLoading={setLoading}
        loading={loading}
        setStaffDetail={setStaffDetail}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(StaffProfile)
