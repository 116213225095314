import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react'
import SappDrawer from 'src/components/base/SappDrawer'
import { useConfirm } from 'src/hooks/use-confirm'
import thumnail_courseinfo from 'src/_metronic/assets/images/course/thumnail_default.png'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import { KTIcon } from 'src/_metronic/helpers'
import AddTeacherToClass from './AddTeacherToClass'
import { IPartTeacher, ITeacherInClass } from 'src/type/teacher'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { ClassesApi } from 'src/apis/classes'
import { VALIDATE_LECTURE } from 'src/constants/classes'
interface Iprops {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  // data: ITeacherClassList | undefined
  getClassLectures: () => void
  classId?: string
  fetchClass?: () => void
}
const AddTeacherModal = ({ open, setOpen, getClassLectures, classId, fetchClass }: Iprops) => {
  const [openPopup, setOpenPopup] = useState<{ isOpen: boolean; sectionId: string }>({
    isOpen: false,
    sectionId: '',
  })
  const [section, setSection] = useState<IPartTeacher[]>()
  const [error, setError] = useState<{ id: string; msg: string }[] | null>()
  const { confirm, contextHolder } = useConfirm()

  const fetchData = async () => {
    if (!classId) return
    const res = await ClassesApi.getPartInClass(classId)
    setSection(res.data.data)
  }

  const handleDelete = (teacherId: string, sectionId: string) => {
    setSection((prev) => {
      const data = prev?.map((item: IPartTeacher) => {
        if (item?.lectures && item.id === sectionId) {
          item.lectures = item?.lectures?.filter(
            (teacher: ITeacherInClass) => teacher?.id !== teacherId
          )
        }
        return item
      })
      return data
    })
  }

  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        setOpen(false)
      },
    })
  }

  useEffect(() => {
    fetchData()
  }, [open])

  const handleSubmit = async () => {
    try {
      const emptyLecture = section?.filter(
        (section: IPartTeacher) => section?.lectures?.length === 0
      )
      if (emptyLecture?.length) {
        setError((prev) => {
          return emptyLecture.map((item) => ({ id: item.id, msg: VALIDATE_LECTURE }))
        })
        return
      }
      const lectures: { lecture_id: string; part_id: string }[] = []
      section?.map((section: IPartTeacher) => {
        const data = section?.lectures.map((lecture: ITeacherInClass) => ({
          lecture_id: lecture.id,
          part_id: section.id,
        }))
        lectures.push(...data)
      })
      await ClassesApi.updateClass({ id: classId, lectures })
      getClassLectures()
      fetchClass && fetchClass()
      setOpen(false)
    } catch (error) {
      setOpen(false)
      // do nothing
    }
  }
  return (
    <SappDrawer
      rootClassName='sapp-question_essay-drawer'
      open={open}
      title='Add Teacher'
      cancelButtonCaption={'Cancel'}
      okButtonCaption={'Save'}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      width='55%'
      loading={false}
      footerClassName='border-top-0'
    >
      {contextHolder}
      <div className='row'>
        <div className='col-6'>
          <h1 className='fw-bold fs-5 mt-8'>List Section</h1>
        </div>
        <div className='col-6'>
          <h1 className='fw-bold fs-5 mt-8'>Responsibility</h1>
        </div>
      </div>
      {section?.map((el: IPartTeacher, index: number) => (
        <div
          className={`row mt-10  pb-10 ${
            index === section?.length - 1 ? '' : 'dashed-border-bottom'
          }`}
        >
          <div className='col-6 d-flex align-items-center'>
            <div className='fw-semibold fs-6 sapp-text-truncate-2 text-break'>{el?.name}</div>
          </div>
          <div className='col-6'>
            {el?.lectures?.map((lecture: ITeacherInClass) => (
              <div className='mt-2 row'>
                <div className='col-9 border border-gray-300 rounded sapp-h-45px'>
                  <div className='d-flex align-items-center gap-3'>
                    <div className='symbol symbol-circle symbol-40px overflow-hidden me-3 round d-flex align-items-center'>
                      <div className='symbol-label'>
                        <img
                          src={
                            lecture?.detail?.avatar?.['40x40'] ??
                            lecture?.detail?.avatar?.ORIGIN ??
                            thumnail_courseinfo
                          }
                          alt='author'
                          className='w-100 h-100'
                        />
                      </div>
                    </div>
                    <div className='fw-semibold fs-6'>{lecture?.detail?.full_name}</div>
                  </div>
                </div>
                <div className='col-3 d-flex justify-content-end'>
                  <ButtonIcon
                    title=''
                    className='px-4 py-4 sapp-color-import-student bg-light btn btn-primary'
                    customButton='btn-sm bg-white'
                    onClick={() => handleDelete(lecture?.id, el?.id)}
                  >
                    <KTIcon
                      iconName='trash'
                      iconType='outline'
                      className='fs-2 text-inverse-light'
                    />
                  </ButtonIcon>
                </div>
              </div>
            ))}
            <div className='mt-2 row'>
              <ButtonIcon
                title={'Thêm giảng viên khác'}
                className='col-9 d-flex align-items-center fs-6 fw-bold px-3 py-4 sapp-color-import-student border border-gray-300 rounded btn btn-primary'
                customButton='btn-sm bg-white'
                onClick={() => setOpenPopup({ isOpen: true, sectionId: el?.id })}
              >
                <KTIcon iconName='plus' iconType='solid' className='fs-2 text-inverse-light' />
              </ButtonIcon>
            </div>
            {!!error?.find((e) => e.id === el.id)?.msg && (
              <div className='row mt-2 text-danger'>{error?.find((e) => e.id === el.id)?.msg}</div>
            )}
          </div>
        </div>
      ))}
      <AddTeacherToClass
        open={openPopup}
        setOpen={setOpenPopup}
        section={section}
        setSection={setSection}
        classId={classId}
      />
    </SappDrawer>
  )
}
export default memo(AddTeacherModal)
