export const TEXT_ALIGN = [
  {label: 'Left', value: 'left'},
  {label: 'Center', value: 'center'},
  {label: 'Right', value: 'right'},
]

export const LINE_HEIGHT = [
  {label: 'Single', value: '1'},
  {label: '1.5', value: '1.5'},
  {label: 'Double', value: '2'},
]

export const FONTSIZE = [
  {label: '8px', value: '8px'},
  {label: '10px', value: '10px'},
  {label: '12px', value: '12px'},
  {label: '14px', value: '14px'},
  {label: '16px', value: '16px'},
  {label: '18px', value: '18px'},
  {label: '20px', value: '20px'},
  {label: '22px', value: '22px'},
  {label: '24px', value: '24px'},
  {label: '26px', value: '26px'},
  {label: '28px', value: '28px'},
  {label: '30px', value: '30px'},
  {label: '32px', value: '32px'},
  {label: '34px', value: '34px'},
  {label: '36px', value: '36px'},
  {label: '38px', value: '38px'},
  {label: '40px', value: '40px'},
  {label: '42px', value: '42px'},
  {label: '44px', value: '44px'},
  {label: '46px', value: '46px'},
  {label: '48px', value: '48px'},
  {label: '50px', value: '50px'},
]

export const FONT_FAMILY = [
  {label: 'Arial', value: 'Arial'},
  {label: 'Arial Black', value: 'Arial Black'},
  {label: 'Be Vietnam Pro', value: 'Be Vietnam Pro,Inter'},
  {label: 'Comic Sans MS', value: 'Comic Sans MS'},
  {label: 'Courier New', value: 'Courier New'},
  {label: 'Georgia', value: 'Georgia'},
  {label: 'Helvetica', value: 'Helvetica'},
  {label: 'Impact', value: 'Impact'},
  {label: 'Lucida Console', value: 'Lucida Console'},
  {label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode'},
  {label: 'Palatino Linotype', value: 'Palatino Linotype'},
  {label: 'Tahoma', value: 'Tahoma'},
  {label: 'Times New Roman', value: 'Times New Roman'},
  {label: 'Trebuchet MS', value: 'Trebuchet MS'},
  {label: 'Verdana', value: 'Verdana'},
]

export const TAGS = [
  {label: 'Paragraph', value: 'p'},
  {label: 'Heading 1', value: 'h1'},
  {label: 'Heading 2', value: 'h2'},
  {label: 'Heading 3', value: 'h3'},
  {label: 'Heading 4', value: 'h4'},
  {label: 'Heading 5', value: 'h5'},
  {label: 'Heading 6', value: 'h6'},
]

export const OPTIONS_OF_STATUS = [
  {
    label: 'Published',
    value: 'PUBLISHED',
  },
  {
    label: 'Block',
    value: 'BLOCK',
  },
]

export const CERTIFICATE_PROFILE = '/certificates'
export const CERTIFICATE_CREATE = '/create-template'
