import ActionCell from '../base/action/ActionCell'
import {useNavigate} from 'react-router-dom'
import {PageLink} from 'src/constants'
import {CODE_ADMIN, TITLE_COURSE_GR} from 'src/constants/permission'
import {useUserContext} from 'src/context/UserProvider'
import {Role} from 'src/type'

interface IProps {
  id: string
}

const CourseAction = ({id}: IProps) => {
  const navigate = useNavigate()
  const {profileMe} = useUserContext()
  const allowRenderEditCourse = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_COURSE_GR.EDIT_COURSE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <>
      <ActionCell customWidth='w-150px'>
        <div className='menu-item px-3'>
          <div className='menu-link px-3'>List Mock Test</div>
        </div>
        {allowRenderEditCourse && (
          <div className='menu-item px-3'>
            <div
              className='menu-link px-3'
              onClick={() => navigate(`${PageLink.COURSE_FULL}/${id}`)}
            >
              Edit Course
            </div>
          </div>
        )}
      </ActionCell>
    </>
  )
}

export default CourseAction
