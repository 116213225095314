import { useConfirm } from 'src/hooks/use-confirm'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonDanger from 'src/components/ui/button-danger/ButtonDanger'

interface IProps {
  selected: Array<any>
  okClick: () => void
  title?: string
  loading?: boolean
  body?: string
  okButtonCaption?: string
  type?: 'danger' | 'primary'
  showSelected?: boolean
  isConfirm?: boolean
  isListScreen?: boolean
}

const ListGrouping = ({
  selected,
  okClick,
  title = ' Block Selected',
  loading,
  body,
  okButtonCaption,
  type = 'danger',
  showSelected = true,
  isConfirm = true,
  isListScreen = false,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()

  const handleOK = () => {
    if (isConfirm) {
      confirm({
        okButtonCaption: okButtonCaption,
        cancelButtonCaption: 'No',
        body: body,
        onClick: () => {
          okClick && okClick()
        },
      })
    } else {
      okClick && okClick()
    }
  }

  return (
    <div>
      {contextHolder}
      <div className='d-flex justify-content-end align-items-center'>
        {showSelected && (
          <div className='fw-bolder me-5'>
            <span className='me-2'>{selected.length}</span> Selected
          </div>
        )}
        {type === 'danger' ? (
          <ButtonDanger title={title} onClick={handleOK} loading={loading} size='small' />
        ) : (
          <ButtonPrimary title={title} onClick={handleOK} loading={loading} size='small' />
        )}
        {/* {openBlocked && (
      )} */}
      </div>
    </div>
  )
}

export default ListGrouping
