import {Dispatch, SetStateAction, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import {HEADER_CLASS_LIST} from 'src/constants'
import {useConfirm} from 'src/hooks/use-confirm'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import {format} from 'date-fns'
import {KTCardBody} from 'src/_metronic/helpers'
import {IMailLog, IMailLogList, MAIL_LOG_STATUS} from 'src/type/mail-log'

interface IProps {
  handlePreviewMailLog: (id: string) => void
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  mailLogList: IMailLogList
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchMailLogList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: (
    email: string,
    action: string,
    status: string,
    fromDate?: Date | string,
    toDate?: Date | string,
    dateField?: string
  ) => {
    email: string
    action: string
    status: string
    fromDate?: Date | string
    toDate?: Date | string
    dateField?: string
  }
  queryParams: IQueryParams
  checkedList: Array<string>
  toggleCheckAll: (isCheckedAll?: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
}
interface IQueryParams {
  email: string
  action: string
  status: string
  fromDate: Date
  toDate: Date
  page_index: number
  page_size: number
}

// define headers
const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Title',
    className: 'min-w-350px pe-5',
  },
  {
    label: 'Receiver',
    className: 'min-w-350px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Sent time',
    className: 'min-w-275px pe-5',
  },
]

const TableMailLog = ({
  handlePreviewMailLog,
  mailLogList,
  loading,
  setLoading,
  filterParams,
  fetchMailLogList,
  getParams,
  queryParams,
  isCheckedAll,
  toggleCheckAll,
}: IProps) => {
  const {contextHolder} = useConfirm()
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const navigate = useNavigate()

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  // TODO: check xem có tồn tại params ở trên router không?
  const showQueryParams =
    queryParams.email || queryParams.fromDate || queryParams.toDate || queryParams.action

  // TODO: dùng để filter params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.email.trimStart().trimEnd() ?? '',
      queryParams.action ?? '',
      queryParams.status ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )
  // Using validate for input
  const {setValue} = useForm<any>({
    mode: 'onChange',
  })

  useEffect(() => {
    setLoading(true)
    fetchMailLogList(page_index || 1, page_size || 10, filterParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    mailLogList?.mail_logs
      ?.map((e) => ({label: e.status, value: e.status}))
      ?.forEach((option, index) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, mailLogList?.mail_logs])

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      action: queryParams?.action ?? '',
      email: queryParams.email ?? '',
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchMailLogList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={mailLogList?.mail_logs}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
      >
        {loading ? (
          <>
            {HEADER_CLASS_LIST.map((header, i) => (
              <LoadingTable key={header} headers={HEADER_CLASS_LIST} />
            ))}
          </>
        ) : (
          <>
            {mailLogList?.mail_logs?.map((mailLog: IMailLog, index: number) => {
              return (
                <tr key={mailLog.id}>
                  <td>
                    {index + 1 + (mailLogList?.meta?.page_index - 1) * mailLogList?.meta?.page_size}
                  </td>
                  <td
                    onClick={() => handlePreviewMailLog(mailLog.id)}
                    className='text-start sapp-cursor-pointer text-hover-primary text-break'
                  >
                    {mailLog.title ?? '--'}
                  </td>
                  <td className='text-start'>{mailLog.email ?? '--'}</td>
                  <td className='text-start'>{MAIL_LOG_STATUS[mailLog?.status] || '--'}</td>
                  <td className='text-start'>
                    {format(new Date(mailLog.created_at), 'dd/MM/yyyy HH:mm:ss') ?? '--'}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>

      <PagiantionSAPP
        currentPage={mailLogList?.meta?.page_index || 1}
        pageSize={mailLogList?.meta?.page_size || 10}
        totalItems={mailLogList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableMailLog
