import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import dayjs from 'dayjs'
import { isEmpty, isUndefined } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import cmndBack from 'src/_metronic/assets/media/auth/cmndmatsau.png'
import cmndFront from 'src/_metronic/assets/media/auth/cmndmattruoc.png'
import avatarDefault from 'src/_metronic/assets/media/avatars/blank.png'
import { createStudent, updateStudent, uploadFile, UsersAPI } from 'src/apis/user'
import { SappHeading2 } from 'src/common/SappHeading'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextArea from 'src/components/base/textaria/HookFormTextArea'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  BREAKPOINT,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_HUBSPOT_CONTACT_ID,
  GUIDELINE_PASSWORD,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  IS_STAFF,
  MESSAGE_VALIDATION_USERNAME,
  PageLink,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MAX_HUBSPOT_CONTACT_ID,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_HUBSPOT_CONTACT_ID,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PASSWORD,
  VALIDATE_PHONE,
  VALIDATION_FILED,
} from 'src/constants'
import { LANG_PLACEHOLDER, LANG_USER } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import useWindowWidth from 'src/hooks/use-width'
import { EEntranceLevel, EStatusUser, IError, ITabs } from 'src/type'
import { CourseNameLevel } from 'src/type/courses'
import { IStudentDetail, IStudents, IUniveristy } from 'src/type/students'
import { phoneRegExp, sizeInBytes, usernamePattern, validatePassword } from 'src/utils'
import { z } from 'zod'
const { Option } = Select

const CreateAndUpdateStudent = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const { id } = useParams()
  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [showFrontDefault, setShowFrontDefault] = useState(false)
  const [showBackDefault, setShowBackDefault] = useState(false)
  const [universities, setUniversities] = useState<IUniveristy[]>([])
  const [studentDetail, setStudentDetail] = useState<IStudentDetail>()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const DEFAULT_SCHEMA = {
    full_name: z
      .string({ required_error: VALIDATION_FILED })
      .min(3, { message: VALIDATE_MIN_FULLNAME })
      .max(100, { message: VALIDATE_MAX_FULLNAME }),
    hubspot_contact_id: z
      .string({ required_error: VALIDATION_FILED })
      .min(3, { message: VALIDATE_MIN_HUBSPOT_CONTACT_ID })
      .max(20, { message: VALIDATE_MAX_HUBSPOT_CONTACT_ID }),
    email: z.string({ required_error: VALIDATION_FILED }).email(),
    username: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(usernamePattern), {
        message: MESSAGE_VALIDATION_USERNAME,
      })
      .min(6, { message: VALIDATE_MIN_USERNAME })
      .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
    level: z.string().optional(),
    cma_level: z.string().optional(),
    acca_level: z.string().optional(),
    phone: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
    status: z.string().optional(),
    avatar: z.any(),
    is_sapp_operator: z.string().optional(),
    // examination_code: z.string().optional(),
  }

  const schemaCreate = z
    .object(
      Object.assign({}, DEFAULT_SCHEMA, {
        sex: z.string().optional(),
        password: z
          .string({ required_error: VALIDATION_FILED })
          .regex(new RegExp(validatePassword), {
            message: VALIDATE_PASSWORD,
          }),
        confirmPassword: z.string({ required_error: VALIDATION_FILED }).min(1),
      })
    )
    .refine((data) => data.password === data.confirmPassword, {
      message: 'Password not match!',
      path: ['confirmPassword'],
    })

  const schemaUpdate = z.object(
    Object.assign({}, DEFAULT_SCHEMA, {
      sex: z.string().optional(),
      address: z.string().optional(),
      major: z.string().optional(),
      university: z.string().optional(),
      dob: z.any(),
      note: z.string().max(512).optional(),
      id_front: z.any(),
      id_back: z.any(),
      learning_purpose: z.string().optional(),
      contact_detail: z.string().optional(),
      special_note: z.string().optional(),
      classification: z.string().optional(),
    })
  )

  const validation = useMemo(
    () => (id ? schemaUpdate : schemaCreate),
    [id, schemaCreate, schemaUpdate]
  )

  const getUniversities = async () => {
    try {
      const res = await UsersAPI.universities()
      setUniversities(res?.data)
    } catch (error) {}
  }

  const { control, handleSubmit, setValue, setError, reset } = useForm<IStudents>({
    resolver: zodResolver(validation),
    mode: 'onChange',
  })

  const getStudentDetail = async () => {
    const res = await UsersAPI.detail(id)
    setStudentDetail(res?.data)
  }

  const onSubmitCreate = async (data: IStudents) => {
    const {
      email,
      level,
      acca_level,
      cma_level,
      full_name,
      hubspot_contact_id,
      password,
      phone,
      username,
      avatar,
      sex,
      is_sapp_operator,
    } = data
    setLoading(true)
    try {
      const res = await createStudent({
        email: email.toLowerCase(),
        level: level || null,
        cma_level: cma_level || null,
        acca_level: acca_level || null,
        full_name: full_name,
        password: password,
        phone: phone,
        username: username.toLowerCase(),
        status: EStatusUser.ACTIVE,
        // examination_code: examination_code || null,
        sex: sex,
        is_sapp_operator: is_sapp_operator === 'true' ? true : false,
        hubspot_contact_id: hubspot_contact_id,
      })
      const dataStudent = res?.data
      toast.success('Create Student Successfully!')
      setUploadSuccess(true)
      if (isUndefined(avatar)) {
        navigate(PageLink.STUDENTS)
      }

      if (!isUndefined(avatar)) {
        await uploadFile({ userId: dataStudent?.id, avatar: avatar }).then(() =>
          navigate(PageLink.STUDENTS)
        )
      }
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IError) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
    } finally {
      setUploadSuccess(false)
      setLoading(false)
    }
  }

  const onSubmitUpdate = async (data: IStudents) => {
    const {
      address,
      dob,
      email,
      level,
      cma_level,
      acca_level,
      full_name,
      hubspot_contact_id,
      major,
      note,
      phone,
      sex,
      university,
      username,
      status,
      avatar,
      id_back,
      id_front,
      // examination_code,
      classification,
      contact_detail,
      learning_purpose,
      special_note,
    } = data

    setLoading(true)

    try {
      if (!isUndefined(avatar) || !isUndefined(id_front) || !isUndefined(id_back)) {
        await uploadFile({
          userId: studentDetail?.id,
          avatar: avatar,
          id_front: id_front,
          id_back: id_back,
        })
      }

      const paramsUpdate: IStudents = {
        username: username,
        email: email.toLowerCase(),
        phone: phone,
        dob: dayjs(dob).add(1, 'day'),
        sex: sex || null,
        address: address,
        major: major,
        university: university,
        note: note,
        level: level,
        cma_level: cma_level,
        acca_level: acca_level,
        full_name: full_name,
        hubspot_contact_id: hubspot_contact_id,
        status: status,
        // examination_code: examination_code,
        classification: classification,
        contact_detail: contact_detail,
        learning_purpose: learning_purpose,
        special_note: special_note,
        avatar: null,
        identity_card_image_front: null,
        identity_card_image_back: null,
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      if (!isUndefined(id_front) || !showFrontDefault) {
        delete paramsUpdate.identity_card_image_front
      }

      if (!isUndefined(id_back) || !showBackDefault) {
        delete paramsUpdate.identity_card_image_back
      }

      await updateStudent({
        id: studentDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update Student Successfully!')
          navigate(PageLink.STUDENTS)
        })
        .catch((err: any) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, { message: errorMessage })
          }, {})
        })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    // if (Object.keys(errors).length === 0) {
    if (
      !isUndefined(data.avatar) &&
      (data?.avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    if (
      !isUndefined(data.id_front) &&
      (data?.id_front?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    if (
      !isUndefined(data.id_back) &&
      (data?.id_back?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    if (id) {
      onSubmitUpdate(data)
      return
    }
    onSubmitCreate(data)
    // }
  }

  useEffect(() => {
    if (!id) return
    getUniversities()
    getStudentDetail()
  }, [])

  useEffect(() => {
    if (!id) {
      setUploadSuccess(true)
      reset()
    } else {
      setValue('hubspot_contact_id', studentDetail?.hubspot_contact_id ?? '')
      setValue('full_name', studentDetail?.detail?.full_name ?? '')
      setValue('username', studentDetail?.username ?? '')
      setValue('email', studentDetail?.user_contacts?.[0]?.email ?? '')
      setValue('phone', studentDetail?.user_contacts?.[0]?.phone ?? '')
      // setValue('examination_code', studentDetail?.code ?? '')
      setValue('level', studentDetail?.detail?.level ?? '')
      setValue('cma_level', studentDetail?.detail?.cma_level ?? '')
      setValue('acca_level', studentDetail?.detail?.acca_level ?? '')
      setValue('major', studentDetail?.detail?.major ?? '')
      setValue('university', studentDetail?.detail?.university ?? '')
      setValue('address', studentDetail?.user_contacts?.[0]?.address ?? '')
      setValue('dob', studentDetail?.detail?.dob ?? '')
      setValue('note', studentDetail?.detail?.note ?? '')
      setValue('status', studentDetail?.status ?? '')
      setValue('classification', studentDetail?.detail?.classification ?? '')
      setValue('learning_purpose', studentDetail?.detail?.learning_purpose ?? '')
      setValue('contact_detail', studentDetail?.detail?.contact_detail ?? '')
      setValue('special_note', studentDetail?.detail?.special_note ?? '')
      setValue('sex', studentDetail?.detail?.sex ?? '')
    }
  }, [
    id,
    reset,
    setValue,
    // studentDetail?.code,
    studentDetail?.user_contacts?.[0]?.address,
    studentDetail?.detail?.classification,
    studentDetail?.detail?.contact_detail,
    studentDetail?.detail?.dob,
    studentDetail?.user_contacts?.[0]?.email,
    studentDetail?.detail?.full_name,
    studentDetail?.hubspot_contact_id,
    studentDetail?.detail?.learning_purpose,
    studentDetail?.detail?.level,
    studentDetail?.detail?.cma_level,
    studentDetail?.detail?.acca_level,
    studentDetail?.detail?.major,
    studentDetail?.detail?.note,
    studentDetail?.user_contacts?.[0]?.phone,
    studentDetail?.detail?.special_note,
    studentDetail?.detail?.university,
    studentDetail?.status,
    studentDetail?.username,
    studentDetail?.detail?.sex,
  ])

  const widthWindow = useWindowWidth()
  let identity_card_front = null
  let identity_card_back = null

  if (widthWindow > BREAKPOINT.xs) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['280x180']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['280x180']
  }

  if (widthWindow > BREAKPOINT.md) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['320x190']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['320x190']
  }

  if (widthWindow > BREAKPOINT.xl) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['370x220']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['370x220']
  }

  const { confirm, contextHolder } = useConfirm()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(PageLink.STUDENTS),
    })
  }

  // mở popup confỉm khi submit
  const handleSubmitForm = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  const courseLevel = [
    {
      name: CourseNameLevel.LEVEL,
      label: 'CFA Level',
      defaultValue: studentDetail?.detail?.level,
    },
    {
      name: CourseNameLevel.CMA,
      label: 'CMA Level',
      defaultValue: studentDetail?.detail?.cma_level,
    },
    {
      name: CourseNameLevel.ACCA,
      label: 'ACCA Level',
      defaultValue: studentDetail?.detail?.acca_level,
    },
  ]

  return (
    <CreateEditLayout
      onSave={handleSubmitForm}
      onCancel={handleCancel}
      loading={loading}
      pageTitle={`${id ? 'Update Student' : 'Create Student'}`}
    >
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
          <div className='card card-flush py-4 sapp-pt--image'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>Avatar</h2>
              </div>
            </div>

            <div className='card-body text-center pt-0'>
              <SAPPHookUploadFile
                name='avatar'
                control={control}
                setValue={setValue}
                setError={setError}
                imagePreview={studentDetail?.detail?.avatar?.['150x150'] ?? avatarDefault}
                accept={ACCEPT_UPLOAD_MIME_TYPE}
                maxFileSize={DEFAULT_MAX_FILE_SIZE}
                uploadSuccess={uploadSuccess}
                setShowAvatarDefault={setShowAvatarDefault}
                removeAvatar={avatarDefault}
              />
            </div>
          </div>

          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <SappHeading2 title={LANG_USER.TEST_ACCOUNT} />
              </div>
            </div>

            <div className='card-body pt-0'>
              <HookFormSelectAntd
                name='is_sapp_operator'
                control={control}
                dropdownStyle={{ minWidth: 'fit-content' }}
                defaultValue={IS_STAFF[0].value}
                className='sapp-h-45px'
              >
                {IS_STAFF.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='create student'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush pb-4 pt-10'>
                  <div className='card-body pt-0'>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='full_name'
                        label={LANG_PLACEHOLDER.FULLNAME}
                        required
                        guideline={GUIDELINE_FULLNAME}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='hubspot_contact_id'
                        label='Code'
                        required
                        guideline={GUIDELINE_HUBSPOT_CONTACT_ID}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='username'
                        label='Username'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_USERNAME}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='email'
                        label='Email'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_EMAIL}
                      />
                    </div>

                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='password'
                          label='Password'
                          type='password'
                          required
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}

                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='confirmPassword'
                          label='Confirm Password'
                          type='password'
                          required
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='phone'
                        label='Phone Number'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_PHONE}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormSelectAntd
                        label='Gender'
                        size='large'
                        name='sex'
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        placeholder='Please select'
                        defaultValue={studentDetail?.detail?.sex ?? GENDER_FORM[0]?.value}
                      >
                        {GENDER_FORM.map((gender) => (
                          <Option value={gender.value} key={gender.label}>
                            {gender.label}
                          </Option>
                        ))}
                      </HookFormSelectAntd>
                    </div>

                    {courseLevel.map((course, idx) => (
                      <HookFormSelectAntd
                        size='large'
                        name={course.name}
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        label={course.label}
                        filterOption={true}
                        className={idx !== courseLevel.length - 1 ? 'mb-10' : ''}
                        key={idx}
                      >
                        <Option value={EEntranceLevel.IA}>{EEntranceLevel.IA}</Option>
                        <Option value={EEntranceLevel.IIA}>{EEntranceLevel.IIA}</Option>
                        <Option value={EEntranceLevel.IB}>{EEntranceLevel.IB}</Option>
                        <Option value={EEntranceLevel.IIB}>{EEntranceLevel.IIB}</Option>
                      </HookFormSelectAntd>
                    ))}

                    {id && (
                      <div className='mt-10 fv-row'>
                        <HookFormSelectAntd
                          size='large'
                          name='major'
                          control={control}
                          dropdownStyle={{ minWidth: 'fit-content' }}
                          placeholder='Please select'
                          filterOption={true}
                          // defaultValue={user?.detail?.major ?? ''}
                          label='Major'
                        >
                          <Option value='Tài chính'>Tài chính</Option>
                          <Option value='Kế kiểm'>Kế kiểm</Option>
                          <Option value='Quản trị kinh doanh'>Quản trị kinh doanh</Option>
                          <Option value='Khác'>Khác</Option>
                        </HookFormSelectAntd>
                      </div>
                    )}

                    {id && (
                      <div className='mt-10 fv-row'>
                        {!isEmpty(universities) && (
                          <HookFormSelectAntd
                            size='large'
                            name='university'
                            control={control}
                            placeholder='Please select'
                            // defaultValue={user?.detail?.university ?? universities?.[0]?.name}
                            label='University'
                          >
                            {universities?.map((university) => (
                              <Option key={university.code} value={university.name}>
                                {university.name}
                              </Option>
                            ))}
                          </HookFormSelectAntd>
                        )}
                      </div>
                    )}

                    {id && (
                      <div className='mt-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='address'
                          label='Address'
                          placeholder='Address'
                        />
                      </div>
                    )}

                    {id && (
                      <div className='mt-10 fv-row'>
                        <SappLabel label='Dob' />
                        <HookFormDateTime control={control} name='dob' />
                      </div>
                    )}

                    {id && (
                      <div className='mt-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='learning_purpose'
                          label='Learning Purpose'
                        />
                      </div>
                    )}

                    {id && (
                      <div className='mt-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='contact_detail'
                          label='Contact Detail'
                          placeholder='Contact Detail'
                        />
                      </div>
                    )}

                    {id && (
                      <div className='mt-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='special_note'
                          label='Special Note'
                          placeholder='Special Note'
                        />
                      </div>
                    )}

                    {id && (
                      <div className='mt-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='classification'
                          label='Classification'
                          placeholder='Classification'
                        />
                      </div>
                    )}

                    {id && (
                      <div className='mt-10 fv-row'>
                        <HookFormTextArea
                          control={control}
                          name='note'
                          label='Note'
                          placeholder='Note'
                          rows={8}
                        />
                      </div>
                    )}

                    {id && (
                      <div className='container mt-10 px-0'>
                        <SappLabel label='CMND/CCCD' />
                        <div className='row'>
                          <div className='col-xl-6 col-md-6 col-lg-12'>
                            <SAPPHookUploadFile
                              name='id_front'
                              control={control}
                              setValue={setValue}
                              setError={setError}
                              imagePreview={identity_card_front ?? cmndFront}
                              accept={ACCEPT_UPLOAD_MIME_TYPE}
                              maxFileSize={DEFAULT_MAX_FILE_SIZE}
                              setShowFrontDefault={setShowFrontDefault}
                              removeAvatar={cmndFront}
                              width='sapp-w-front--face'
                              height='sapp-h-front--face'
                            />
                          </div>
                          <div className='col-xl-6 col-md-6 col-lg-12 mt-lg-5 mt-xl-0'>
                            <div className='d-xl-flex justify-content-end'>
                              <div>
                                <SAPPHookUploadFile
                                  name='id_back'
                                  control={control}
                                  setValue={setValue}
                                  setError={setError}
                                  imagePreview={identity_card_back ?? cmndBack}
                                  accept={ACCEPT_UPLOAD_MIME_TYPE}
                                  maxFileSize={DEFAULT_MAX_FILE_SIZE}
                                  setShowFrontDefault={setShowBackDefault}
                                  removeAvatar={cmndBack}
                                  width='sapp-w-front--face'
                                  height='sapp-h-front--face'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CreateEditLayout>
  )
}

export default withAuthComponents(CreateAndUpdateStudent)
