import React, {useState, useEffect} from 'react'
import {KTIcon} from 'src/_metronic/helpers'
import 'src/components/layout/backtop/backtop.scss'

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  // function handle scroll
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    setIsVisible(scrollTop > 300)
  }

  // function scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`scrolltop ${isVisible ? 'visible' : ''}`} onClick={scrollToTop}>
      {/* start:: icon arrow up */}
      <KTIcon iconName='arrow-up' className='me-0' />
      {/* end:: icon arrow up */}
    </div>
  )
}

export default BackToTop
