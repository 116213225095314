import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { isEmpty, isUndefined } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import avatarDefault from 'src/_metronic/assets/media/avatars/blank.png'
import { createTeacher, uploadFile, UsersAPI } from 'src/apis/user'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PASSWORD,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  MESSAGE_VALIDATION_USERNAME,
  PageLink,
  STATUS_FORM,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PASSWORD,
  VALIDATE_PHONE,
  VALIDATION_FILED,
} from 'src/constants'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusUser, IError, ITabs } from 'src/type'
import { IExpandInfo, IStudentDetail } from 'src/type/students'
import { phoneRegExp, sizeInBytes, usernamePattern, validatePassword } from 'src/utils'
import { z } from 'zod'

const { Option } = Select

enum EEntranceLevel {
  IIA = 'IIA',
  IA = 'IA',
  IB = 'IB',
  IIB = 'IIB',
}

interface IStudents extends IExpandInfo {
  username: string
  password: string
  email: string
  phone: number
  level: EEntranceLevel.IA | EEntranceLevel.IB | EEntranceLevel.IIA | EEntranceLevel.IIB
  full_name: string
  confirmPassword?: string
  status?: string
  avatar?: File
  sex?: string
}

export interface IUpdateTeacher extends IExpandInfo {
  email: string
  full_name: string
  phone: number
  username: string
  status: string | undefined
  sex: string | null
  avatar?: any
}

const CreateAndUpdateTeacher = () => {
  const { id } = useParams()
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [teacherDetail, setTeacherDetail] = useState<IStudentDetail>()
  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const isValidHttpUrl = (url: string) => {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    return pattern.test(url)
  }
  const DEFAULT_SCHEMA = {
    full_name: z
      .string({ required_error: VALIDATION_FILED })
      .min(3, { message: VALIDATE_MIN_FULLNAME })
      .max(100, { message: VALIDATE_MAX_FULLNAME }),
    email: z.string({ required_error: VALIDATION_FILED }).email(),
    username: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(usernamePattern), {
        message: MESSAGE_VALIDATION_USERNAME,
      })
      .min(6, { message: VALIDATE_MIN_USERNAME })
      .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
    status: z.string().optional(),
    phone: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
    avatar: z.any(),
    job_title: z.string().trim().optional(),
    facebook: z.string().trim().optional(),
    linkedin: z.string().trim().optional(),
    youtube: z.string().trim().optional(),
    describe: z.string().trim().optional(),
  }

  const schemaCreate = z.object(
    Object.assign({}, DEFAULT_SCHEMA, {
      sex: z.string({ required_error: VALIDATION_FILED }).optional(),
      password: z.string({ required_error: VALIDATION_FILED }).regex(new RegExp(validatePassword), {
        message: VALIDATE_PASSWORD,
      }),
      confirmPassword: z
        .string({ required_error: VALIDATION_FILED })
        .regex(new RegExp(validatePassword), {
          message: 'Password must contain at least 8 characters, 1 uppercase character, 1 number',
        }),
    })
  )

  const schemaUpdate = z.object(
    Object.assign({}, DEFAULT_SCHEMA, {
      sex: z.string({ required_error: VALIDATION_FILED }).optional(),
    })
  )

  const validation = useMemo(
    () => (id ? schemaUpdate : schemaCreate),
    [id, schemaCreate, schemaUpdate]
  )

  const { control, handleSubmit, setValue, setError, reset } = useForm<IStudents>({
    resolver: zodResolver(validation),
    mode: 'onChange',
  })

  const onSubmitCreate = async (data: IStudents) => {
    const {
      email,
      level,
      full_name,
      password,
      phone,
      username,
      avatar,
      sex,
      job_title,
      facebook,
      linkedin,
      youtube,
      describe,
    } = data

    if (facebook && !isValidHttpUrl(facebook)) {
      setError('facebook', { message: 'Invalid value' })
      return
    }

    if (linkedin && !isValidHttpUrl(linkedin)) {
      setError('linkedin', { message: 'Invalid value' })
      return
    }

    if (youtube && !isValidHttpUrl(youtube)) {
      setError('youtube', { message: 'Invalid value' })
      return
    }
    setLoading(true)
    try {
      const res = await createTeacher({
        email: email.toLowerCase(),
        level: level,
        full_name: full_name,
        password: password,
        phone: phone,
        username: username.toLowerCase(),
        status: EStatusUser.ACTIVE,
        sex: sex,
        job_title,
        facebook,
        linkedin,
        youtube,
        describe,
      })
      const dataStudent = res?.data
      toast.success('Create Teacher Successfully!')
      setUploadSuccess(true)

      if (isUndefined(avatar)) {
        navigate(PageLink.TEACHERS)
      }

      if (!isUndefined(avatar)) {
        await uploadFile({ userId: dataStudent?.id, avatar: avatar }).then(() =>
          navigate(PageLink.TEACHERS)
        )
      }
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IError) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
    } finally {
      setUploadSuccess(false)
      setLoading(false)
    }
  }

  const onSubmitUpdate = async (data: IStudents) => {
    const { email, full_name, phone, username, status, sex, avatar } = data
    setLoading(true)

    try {
      if (!isUndefined(avatar)) {
        await uploadFile({ userId: teacherDetail?.id, avatar: avatar })
      }

      const paramsUpdate: IUpdateTeacher = {
        email: email.toLowerCase(),
        full_name: full_name,
        phone: phone,
        username: username,
        status: status,
        sex: sex || null,
        avatar: null,
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      UsersAPI.update({
        id: teacherDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update Teacher Successfully!')
          navigate(PageLink.TEACHERS)
        })
        .catch((err: any) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, { message: errorMessage })
          }, {})
        })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<IStudents> = async (data) => {
    // if (Object.keys(errors).length === 0) {
    if (
      !isUndefined(data.avatar) &&
      (data?.avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    if (id) {
      onSubmitUpdate(data)
      return
    }
    onSubmitCreate(data)
    // }
  }

  const getTeacherDetail = async () => {
    const res = await UsersAPI.detail(id)
    setTeacherDetail(res?.data)
  }

  useEffect(() => {
    if (!id) return
    getTeacherDetail()
  }, [])

  useEffect(() => {
    if (!id) {
      reset()
      setUploadSuccess(true)
    } else {
      setValue('full_name', teacherDetail?.detail?.full_name ?? '')
      setValue('username', teacherDetail?.username ?? '')
      setValue('email', teacherDetail?.user_contacts?.[0]?.email ?? '')
      setValue('phone', teacherDetail?.user_contacts?.[0]?.phone ?? '')
      setValue('status', teacherDetail?.status ?? '')
      setValue('sex', teacherDetail?.detail?.sex ?? '')
    }
  }, [
    id,
    reset,
    setValue,
    teacherDetail?.detail?.email,
    teacherDetail?.detail?.full_name,
    teacherDetail?.user_contacts?.[0]?.phone,
    teacherDetail?.detail?.sex,
    teacherDetail?.status,
    teacherDetail?.username,
  ])

  const { confirm, contextHolder } = useConfirm()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(PageLink.TEACHERS),
    })
  }

  // mở popup confỉm khi submit
  const handleSubmitForm = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  return (
    <CreateEditLayout
      onSave={handleSubmitForm}
      onCancel={handleCancel}
      loading={loading}
      pageTitle={`${id ? 'Update Teacher' : 'Create Teacher'}`}
    >
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
          <div className='card card-flush py-4 sapp-pt--image'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>Avatar</h2>
              </div>
            </div>

            <div className='card-body text-center pt-0'>
              <SAPPHookUploadFile
                name='avatar'
                control={control}
                setValue={setValue}
                setError={setError}
                imagePreview={teacherDetail?.detail?.avatar?.['150x150'] ?? avatarDefault}
                accept={ACCEPT_UPLOAD_MIME_TYPE}
                maxFileSize={DEFAULT_MAX_FILE_SIZE}
                uploadSuccess={uploadSuccess}
                setShowAvatarDefault={setShowAvatarDefault}
                removeAvatar={avatarDefault}
              />
            </div>
          </div>

          {id && (
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>Status</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <HookFormSelectAntd
                  size='large'
                  name='status'
                  control={control}
                  dropdownStyle={{ minWidth: 'fit-content' }}
                  placeholder='Status'
                  filterOption={true}
                  defaultValue={STATUS_FORM[0].value}
                  className='fs-6'
                >
                  {STATUS_FORM.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='kt_ecommerce_add_product_general'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush py-4 pt-10'>
                  <div className='card-body pt-0'>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='full_name'
                        label={LANG_PLACEHOLDER.FULLNAME}
                        required
                        guideline={GUIDELINE_FULLNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='username'
                        label='Username'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_USERNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='email'
                        label='Email'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_EMAIL}
                      />
                    </div>
                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='password'
                          label='Password'
                          type='password'
                          required
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}
                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='confirmPassword'
                          label='Confirm Password'
                          type='password'
                          required
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}
                    <div className={`${!id ? '' : 'mb-10'} fv-row`}>
                      <HookFormTextField
                        control={control}
                        name='phone'
                        label='Phone Number'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_PHONE}
                      />
                    </div>
                    <div className={`fv-row mt-10 mb-10`}>
                      <HookFormSelectAntd
                        name='sex'
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        placeholder='Please select'
                        label='Gender'
                        defaultValue={teacherDetail?.detail?.sex ?? GENDER_FORM?.[0]?.value}
                        classNameHeight='sapp-h-45px'
                      >
                        {GENDER_FORM.map((gender) => (
                          <Option key={gender.label} value={gender.value}>
                            {gender.label}
                          </Option>
                        ))}
                      </HookFormSelectAntd>
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='job_title' label='Job Title' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='facebook' label='Facebook' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='linkedin' label='LinkedIn' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='youtube' label='YouTube' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormEditor
                        control={control}
                        name='describe'
                        placeholder=''
                        defaultValue={''}
                        label='Describe'
                        resourceLocation={RESOURCE_LOCATION.USER}
                        object_id={''}
                        setDataFile={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CreateEditLayout>
  )
}

export default withAuthComponents(CreateAndUpdateTeacher)
