import {Skeleton} from 'antd'
import React from 'react'

interface IProps {
  loadingAvatar?: any
}

const LoadingProfileDetail = ({loadingAvatar}: IProps) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>
            <Skeleton.Input size='large' active />
          </h3>
        </div>

        <div className='btn btn-sm align-self-center'>
          <Skeleton.Button size='large' active className='w-75' />
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>
            <Skeleton.Input size='small' active />
          </label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6 text-gray-800'>
              <Skeleton.Input size='small' active className='w-75' />
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>
            <Skeleton.Input size='small' active />
          </label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6 text-gray-800'>
              <Skeleton.Input size='small' active className='w-75' />
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>
            <Skeleton.Input size='small' active />
          </label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6 text-gray-800'>
              <Skeleton.Input size='small' active className='w-75' />
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>
            <Skeleton.Input size='small' active />
          </label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6 text-gray-800'>
              <Skeleton.Input size='small' active className='w-75' />
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>
            <Skeleton.Input size='small' active />
          </label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6 text-gray-800'>
              <Skeleton.Input size='small' active className='w-75' />
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-semibold text-muted'>
            <Skeleton.Input size='small' active />
          </label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6 text-gray-800'>
              <Skeleton.Input size='small' active className='w-75' />
            </span>
          </div>
        </div>

        <div className='row mb-10'>
          <label className='col-lg-4 fw-semibold text-muted'>
            <Skeleton.Input size='small' active />
          </label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6 text-gray-800'>
              <Skeleton.Input size='small' active className='w-75' />
            </span>
          </div>
        </div>

        {loadingAvatar === undefined && (
          <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <Skeleton.Avatar size='large' active className='me-4' />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-semibold'>
                <h4 className='text-gray-900 fw-bold'>
                  <Skeleton.Input size='small' active />
                </h4>
                <div>
                  <Skeleton.Input size='small' active />
                  <span className='fw-bold'>
                    <Skeleton.Input size='small' active />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoadingProfileDetail
