import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import { useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { KTCardBody } from 'src/_metronic/helpers'
import { ImportLogAPI } from 'src/apis/import-log'
import LoadingTable from 'src/common/LoadingTable'
import NotData from 'src/components/NotData'
import { FORMAT_DATETIME, MOCKUP_HEADER, PageLink } from 'src/constants'
import { z } from 'zod'
import ActionCell from '../../action/ActionCell'
import PagiantionSAPP from '../../pagination/PagiantionSAPP'
import FilterHeader, { JOB_STATUS } from './FilterHeader'
import clsx from 'clsx'
import styles from './TableImportLog.module.scss'

type IProps = {
  type: string
}

export interface ILog {
  file_url: string | undefined
  file_name: string | undefined
  importer: string
  date: Date
  status: string
  id: string
  object_type: string
}

interface IFilterHeader {
  text: string
  status: string
  object_type: string
}

const TableImportLog = ({ type }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  const validationSchema = z.object({
    text: z.string().optional(),
    status: z.string().optional(),
    object_type: z.string().optional(),
  })
  const filterForm = useForm<IFilterHeader>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const { getValues } = filterForm
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const queryParams = {
    page_index: parseInt(searchParams.get('page_index') as any),
    page_size: parseInt(searchParams.get('page_size') as string),
    text: searchParams.get('text') as string,
    status: searchParams.get('status') as string,
    object_type: searchParams.get('object_type') as string,
  }

  const [logs, setLogs] = useState<{
    data: ILog[]
    meta: {
      total_pages: number
      total_records: number
      page_index: number
      page_size: number
    }
  }>()
  useLayoutEffect(() => {
    getImportLogs({
      page_index: queryParams.page_index || 1,
      page_size: queryParams.page_size || 10,
      ...(queryParams.text && { text: queryParams.text }),
      ...(queryParams.status && { status: queryParams.status }),
      ...(queryParams.object_type && type === 'exam' && { object_type: queryParams.object_type }),
    })
  }, [type])

  const navigate = useNavigate()

  const handleChangeParams = (currentPage: number, size: number) => {
    const queryParam = {
      page_index: currentPage,
      page_size: size,
      text: getValues('text') ?? '',
      status: getValues('status') ?? '',
      ...(queryParams.object_type && type === 'exam' && { object_type: queryParams.object_type }),
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  // TODO: hàm này để change params khi đổi pagination
  const handleChangeParamsPagination = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: queryParams.text ?? '',
      status: queryParams.status ?? '',
      object_type: queryParams.object_type ?? undefined,
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const getImportLogs = async ({
    text,
    status,
    object_type,
    page_index = 1,
    page_size = 10,
  }: {
    text?: string
    status?: string
    page_index: number
    page_size: number
    object_type?: string
  }) => {
    handleChangeParams(page_index, page_size)

    try {
      setLoading(true)
      const importLogs = await ImportLogAPI.getImportLogs<{
        files: {
          id: string
          updated_at: string
          file_key: string
          file_name: string
          file_url: string
          file_type: 'MAIN' | 'CONVERTED' | 'REPORT'
          content_type: string
        }[]
        actor: {
          username: string
          detail: {
            full_name: string
          }
        }
        updated_at: Date
        id: string
        result: string
        status: string
        object_type: string
      }>({ type, page_index, page_size, text, status, object_type })

      const logs = importLogs.data.logs
      const result = await Promise.all(
        logs?.map(async (log) => {
          const main = log.files.find((e) => e.file_type === 'MAIN')

          return {
            file_name: main?.file_name,
            file_url: main?.file_url,
            importer: log.actor.detail.full_name,
            date: log.updated_at,
            status: log.status,
            id: log.id,
            object_type: log.object_type,
          }
        })
      )

      setLogs({
        data: result,
        meta: importLogs.data.meta,
      })
    } finally {
      setLoading(false)
    }
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case JOB_STATUS.FAILED:
        return 'danger'
      case JOB_STATUS.SUCCESSFUL:
        return 'success'
      case JOB_STATUS.PROCESSING:
        return 'warning'
      case JOB_STATUS.PENDING:
        return 'primary'
      default:
        return 'secondary'
    }
  }
  const headers = [
    {
      label: 'Import file',
      key: 'import_file',
    },
    {
      label: 'Importer',
      key: 'importer',
    },
    {
      label: 'Date',
      key: 'date',
    },
    {
      label: 'Status',
      key: 'status',
    },
    ...(type === 'exam'
      ? [
          {
            label: 'Program',
            key: 'exam',
            className: '',
          },
        ]
      : []),
    {
      label: '',
      key: 'log_file',
      className: '',
    },
  ]
  return (
    <>
      <FilterHeader
        form={filterForm}
        getImportLogs={getImportLogs}
        meta={logs?.meta}
        textSearch={queryParams.text}
        status={queryParams.status}
        object_type={queryParams.object_type}
      />
      <KTCardBody>
        <div className='table-responsive position-relative'>
          <table
            className={clsx([
              'table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer',
              styles.table,
            ])}
          >
            <thead>
              {/* start:: header table */}
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th>#</th>
                {headers.map((header) => (
                  <th
                    className={`text-gray-500 fw-bold fs-7 text-uppercase gs-0 ${header.className}`}
                    key={header.key}
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
              {/* end:: header table */}
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {loading ? (
                <>
                  {MOCKUP_HEADER.map((_header, i) => (
                    <LoadingTable key={i} headers={headers} />
                  ))}
                </>
              ) : (
                logs?.data?.map((log, index) => {
                  const startIndex = (logs?.meta.page_index - 1) * logs?.meta.page_size
                  return (
                    <tr key={log.id}>
                      <td className='min-w-50px'>{index + startIndex + 1}</td>
                      <td className='min-w-300px'>
                        <a
                          className='text-gray-800 text-hover-primary'
                          href={log.file_url}
                          download={log.file_name ?? ''}
                        >
                          {log.file_name ?? ''}
                        </a>
                      </td>
                      <td className='min-w-200px'>{log.importer}</td>
                      <td className='min-w-200px'>
                        <span>{log?.date ? format(new Date(log.date), FORMAT_DATETIME) : '-'}</span>
                      </td>
                      <td className='min-w-200px'>
                        <span
                          className={`badge badge-light-${getStatusColor(
                            log.status
                          ).toLocaleLowerCase()} text-capitalize`}
                        >
                          {log.status.toLocaleLowerCase()}
                        </span>
                      </td>
                      {type === 'exam' && (
                        <td className='min-w-70px'>
                          {log.object_type.replace('_EXAMINATION', '')}
                        </td>
                      )}
                      <td className='min-w-50px d-flex justify-content-end'>
                        <ActionCell customWidth='w-150px'>
                          <Link
                            to={`${
                              type === 'CLASS_STUDENT'
                                ? `/logs/detail/${type}/${log.id}`
                                : `${PageLink.IMPORT_LOG}/detail/${type}/${log.id}`
                            } `}
                            className='menu-item px-3'
                          >
                            <div className='menu-link px-3'>Detail</div>
                          </Link>
                        </ActionCell>
                      </td>
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>
          {isEmpty(logs?.data) && !loading && <NotData />}
        </div>
        <PagiantionSAPP
          currentPage={logs?.meta.page_index ?? 1}
          pageSize={logs?.meta.page_size ?? 10}
          totalItems={logs?.meta.total_records}
          handlePaginationChange={(page_index: number, page_size: number) => {
            getImportLogs({
              page_index,
              page_size,
              text: queryParams?.text ?? '',
              status: queryParams?.status ?? '',
            })
            handleChangeParamsPagination(page_index, page_size)
          }}
        />
      </KTCardBody>
    </>
  )
}

export default TableImportLog
