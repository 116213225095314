import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { UsersAPI } from 'src/apis/user'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { GENDER, PageLink, pageSizeOptions, STATUS } from 'src/constants'
import { BUTTON_TEXT } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { formatDate, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import HookFormTextField from '../base/textfield/HookFormTextField'
import UserListGrouping from '../user-management/UserListGrouping'
import { ListViewProvider } from './components/core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest } from './components/core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
} from './components/core/QueryResponseProvider'
import { TeachersListToolbar } from './components/header/TeacherListToolbar'
import { usersColumns } from './components/table/columns/_columns'
import { TeacherTable } from './components/table/TeacherTable'

const { Option } = Select

const fieldNames = ['gender', 'text', 'status', 'fromDate', 'toDate']
const initialValues: any = { gender: '', text: '', status: '', fromDate: '', toDate: '' }

const TeachersList = () => {
  const { updateState } = useQueryRequest()
  const [openBlocked, setOpenBlocked] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { rows } = useTable({ columns, data })
  const { refetch, isLoading } = useQueryResponse()
  const navigate = useNavigate()

  const queryParams = {
    text: searchParams.get('text') ?? '',
    gender: searchParams.get('gender'),
    status: searchParams.get('status'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const [currentPage, setCurrentPage] = useState(queryParams.page_index || 1)
  const [pageSize, setPageSize] = useState(queryParams.page_size || pageSizeOptions[0].value)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const validationSchema = z.object({
    text: z.string().optional(),
    gender: z.string().optional(),
    status: z.any(),
    fromDate: z.string().optional(),
    toDate: z.string().optional(),
  })

  // Using validate for input
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: getValues('text'),
      gender: replaceValueAll(getValues('gender')),
      status: replaceValueAll(getValues('status')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    rows.map((row) => {
      return row.original
    })
  )

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))

    updateState({
      page_index: 1,
      text: getValues('text'),
      gender: replaceValueAll(getValues('gender')),
      status: replaceValueAll(getValues('status')),
      fromDate: getValues('fromDate')
        ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
        : '',
      toDate: getValues('toDate')
        ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
        : '',
      dateField: 'updated_at',
    })

    handleChangeParams(1, queryParams.page_size || 10)
    toggleCheckAll(false)
    setCurrentPage(1)
  }

  const onReset = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })

    navigate(PageLink.TEACHERS)
    updateState({
      page_index: 1,
      page_size: 10,
      text: '',
      gender: '',
      status: '',
      fromDate: null,
      toDate: null,
      dateField: '',
    })
    toggleCheckAll(false)
    setCurrentPage(1)
    setPageSize(10)
  }

  const blockUser = useMutation(() => UsersAPI.blocked(checkedList), {
    onSuccess: () => {
      toggleCheckAll(false)
      toast.success('Block Successfully!')
      setOpenBlocked(false)
      cancel(true)
      setCurrentPage(1)
    },
  })

  return (
    <div className='card'>
      <div className='px-10 border-0 pt-10'>
        <div className=''>
          <div className='row'>
            <div className='col-xl-3 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0'>
                <HookFormTextField
                  control={control}
                  name='text'
                  placeholder='Search'
                  defaultValue={queryParams.text ?? ''}
                  isListScreen
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            <div className='col-xl-3 col-sm-4'>
              <HookFormSelectAntd
                control={control}
                name='gender'
                placeholder='Gender'
                size='large'
                defaultValue={queryParams.gender ?? ''}
                classNameHeight='sapp-h-40'
              >
                {GENDER.map((gender) => (
                  <Option key={gender.value} value={gender.value}>
                    {gender.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>

            <div className='col-xl-3 col-sm-4'>
              <HookFormSelectAntd
                control={control}
                name='status'
                placeholder='Status'
                size='large'
                defaultValue={queryParams.status ?? ''}
                classNameHeight='sapp-h-40'
              >
                {STATUS.map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>

            <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams.fromDate ?? ''}
                isListScreen
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-5'>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams.toDate ?? ''}
                isListScreen
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6'>
        <div className='d-flex'>
          <SAPPFIlterButton
            okClick={onSubmit}
            resetClick={onReset}
            titleReset='Reset'
            titleSubmit={BUTTON_TEXT.SEARCH}
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
        <>
          {/* start:: button open modal */}
          {checkedList?.length > 0 ? (
            <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3 pe-xl-0'>
              <UserListGrouping
                selected={checkedList}
                blockUser={async () => await blockUser.mutateAsync()}
                openBlocked={openBlocked}
                setOpenBlocked={setOpenBlocked}
              />
            </div>
          ) : (
            <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3'>
              <TeachersListToolbar
                searchTerm={watch('text')}
                sortGender={watch('gender')}
                sortStatus={watch('status')}
                fromDate={watch('fromDate')}
                toDate={watch('toDate')}
                location={location}
              />
            </div>
          )}
          {/* end:: button open modal */}
        </>
      </div>
      <TeacherTable
        currentPage={currentPage}
        handleChangeParams={handleChangeParams}
        pageSize={pageSize}
        checkedList={checkedList}
        toggleCheck={toggleCheck}
        toggleCheckAll={toggleCheckAll}
        isCheckedAll={isCheckedAll}
        sortGender={queryParams.gender}
        textSearch={queryParams.text}
        sortStatus={queryParams.status}
        fromDate={queryParams.fromDate}
        toDate={queryParams.toDate}
        // fetchTeacherList={fetchTeacherList}
      />
    </div>
  )
}

const TeachersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TeachersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { TeachersListWrapper }
