import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingTable from 'src/common/LoadingTable'
import { MOCKUP_HEADER, PageLink } from 'src/constants'
import { ITopicList, ITopicObject } from 'src/type/question-bank'
import SAPPCheckbox from '../base/checkbox/SAPPCheckbox'
import DatetimeColumn from '../base/DatetimeColumn'
import PagiantionSAPP from '../base/pagination/PagiantionSAPP'
import { QuestionAction } from './QuestionAction'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  htmlToRaw,
} from 'src/utils'
import SappTable from '../base/SappTable'
import { KTCardBody } from 'src/_metronic/helpers'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_TOPIC_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import ActiveQuestForm from './choose-question-type/ActiveQuestForm'
import { QUESTION_TYPES } from './shared/interfaces'
import QuestionActionsCell from './topic/QuestionActionsCell'
import { QuestionBankAPI } from 'src/apis/question-bank'
import { useConfirm } from 'src/hooks/use-confirm'
import { uniqueId } from 'lodash'
import toast from 'react-hot-toast'
import { useQuestionProviderContext } from './shared/providers/QuestionProvider'
import PreviewQuestion from './preview-question/PreviewQuestion'
import React from 'react'

const headers = [
  {
    label: 'Item Set Name',
    key: 'topic_name',
    className: 'min-w-250px',
  },
  {
    label: 'Item Set Description',
    key: 'topic_description',
    className: 'min-w-250px',
  },
  {
    label: 'Multiple Question',
    key: 'multiple_question',
    className: 'min-w-200px text-nowrap text-center',
  },
  {
    label: 'Constructed Question',
    key: 'constructed_question',
    className: 'min-w-200px text-nowrap text-center',
  },
  {
    label: 'Date',
    key: 'update_at',
    className: 'min-w-250px',
  },
  {
    label: '',
    key: 'action',
    className: 'text-end w-35px',
  },
]

interface IProps {
  topicList: ITopicList | undefined
  setTopicList: Dispatch<SetStateAction<any>>
  cleanedParams: Object
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  fetchTopic: (page_index: number, page_size: number, object?: Object) => void
  queryParam: any
  getParams: any
}

const TableList = ({
  topicList,
  setTopicList,
  cleanedParams,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  fetchTopic,
  queryParam,
  getParams,
}: IProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [singleQuestionId, setSingleQuestionId] = useState<any>()
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [activeQuestForm, setActiveQuestForm] = useState<QUESTION_TYPES | null>(null)
  const [actionType, setActionType] = useState<'edit' | 'duplicate' | undefined>('edit')
  const [reload, setReload] = useState<boolean>(false)
  const [openPreviewPopup, setOpenPreviewPopup] = useState<{ value: any; data: any }>({
    value: false,
    data: undefined,
  })

  const handleReload = () => setReload(!reload)

  const { refreshListQuestion } = useQuestionProviderContext()

  const { confirm, contextHolder } = useConfirm()

  const handleOpenFormEdit = (
    qId?: string,
    qType?: QUESTION_TYPES,
    setActionTypeParam: 'edit' | 'duplicate' = 'edit'
  ) => {
    if (!qId || !qType) {
      return
    }
    setActiveQuestForm(qType)
    setPopupOpen(true)
    setSingleQuestionId(qId)
    setActionType(setActionTypeParam)
  }
  const handleOpenPreview = async (qId?: string | undefined) => {
    try {
      if (qId) {
        const response = await QuestionBankAPI.getQuestionById(qId)
        if (response.success) {
          setOpenPreviewPopup((prev) => ({ ...prev, value: true, data: response.data }))
        }
      }
    } catch (error) {}
  }

  const handleClosePreview = () => {
    setOpenPreviewPopup({ value: false, data: undefined })
  }

  const handleDeleteQuestion = (qId?: string | undefined) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc chắn muốn xóa không?',
      onClick: async () => {
        if (!qId) {
          return
        }
        const toastId = uniqueId('delete_question')
        // toast.loading('Deleting question...', {id: toastId})
        try {
          await QuestionBankAPI.deleteQuestion(qId)
          toast.success('Question deleted successfully!', { id: toastId })
          refreshListQuestion(['multiple', 'constructed'])
        } catch (error: any) {
        } finally {
          toast.remove(toastId)
          handleReload()
        }
      },
    })
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParam.fromDate)
  const dateQueryToDate = getDateInfo(queryParam.toDate)

  //TODO: dùng để change params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParam.text ?? '',
      queryParam.sortType ?? '',
      queryParam.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParam.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : ''
    )
  )

  // call API getTopic
  useEffect(() => {
    setLoading(true)
    fetchTopic(queryParam.page_index || 1, queryParam?.page_size || 10, cleanedParams)
  }, [reload, location.state?.reload])

  //TODO: đổi params trên router
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      text: queryParam.text ?? '',
      sortType: queryParam.sortType ?? '',
      fromDate: formatDate(queryParam.fromDate) ?? '',
      toDate: formatDate(queryParam.toDate) ?? '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchTopic(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }
  const handleOpenSingleQuestion = async (isSingle: boolean, topic: ITopicObject) => {
    if (isSingle && topic.questions) {
      setSingleQuestionId(topic.questions[0].id)
      setActiveQuestForm(topic.questions[0].qType)
      setPopupOpen(true)
      navigate(`${PageLink.TOPIC}/${topic?.id}/question/${topic.questions[0].id}`)
    } else {
      navigate(`${PageLink.TOPIC}/${topic?.id}${location.search || ''}`)
    }
  }
  const { profileMe } = useUserContext()
  const allowRenderView = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.VIEW_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderDelete = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderAction = allowRenderEdit || allowRenderDelete

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={topicList?.topics}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((_header, i) => (
              <LoadingTable key={i} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {topicList?.topics?.map((topic, i) => {
              const isChecked = checkedList && checkedList.includes(topic.id)
              const isSingleQuestion =
                topic?.questions?.length === 1 && !!topic?.questions[0]?.is_single_question
              return (
                <tr key={topic?.id ?? i}>
                  <td>
                    <SAPPCheckbox
                      checked={isChecked}
                      onChange={() => {
                        toggleCheck(topic.id)
                      }}
                    />
                  </td>
                  <td>
                    <div
                      className='sapp-cursor-pointer text-gray-800 text-hover-primary sapp-text-truncate-2'
                      onClick={() => {
                        if (allowRenderView) {
                          handleOpenSingleQuestion(isSingleQuestion, topic)
                        }
                      }}
                    >
                      {topic.name}
                    </div>
                  </td>
                  <td>
                    <div className='mw-500px sapp-text-truncate-2'>
                      {isSingleQuestion && topic.questions
                        ? htmlToRaw(topic.questions[0].question_content)
                        : htmlToRaw(topic.description)}
                    </div>
                  </td>
                  <td className='text-center'>{topic.num_of_multiple_choice_questions}</td>
                  <td className='text-center'>{topic.num_of_constructed_questions}</td>
                  <td>
                    <DatetimeColumn created_at={topic?.created_at} updated_at={topic?.updated_at} />
                  </td>
                  {allowRenderAction && (
                    <td className='text-end sapp-absolute-column'>
                      {isSingleQuestion && topic.questions[0] && topic.questions[0].qType ? (
                        <QuestionActionsCell
                          handleOpenFormEdit={handleOpenFormEdit}
                          handleDeleteQuestion={handleDeleteQuestion}
                          handleOpenPreview={handleOpenPreview}
                          id={topic.questions[0].id}
                          qType={topic.questions[0].qType ?? undefined}
                        />
                      ) : (
                        <QuestionAction
                          id={topic.id}
                          setTopicList={setTopicList}
                          cleanedParams={cleanedParams}
                          setLoading={setLoading}
                          currentPage={queryParam?.page_index || 1}
                          pageSize={queryParam?.page_size || 10}
                        />
                      )}
                    </td>
                  )}
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      {/* start:: show pagination */}
      <PagiantionSAPP
        currentPage={topicList?.meta?.page_index || 1}
        pageSize={topicList?.meta?.page_size || 10}
        totalItems={topicList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
      {/* end:: show pagination */}
      <ActiveQuestForm
        id={singleQuestionId}
        actionType={actionType}
        activeQuestForm={activeQuestForm}
        setActiveQuestForm={setActiveQuestForm}
        openQuestForm={popupOpen}
        setOpenQuestForm={setPopupOpen}
        isSingleQuestion={true}
        handleReload={handleReload}
      />
      <PreviewQuestion
        open={openPreviewPopup.value}
        onClose={handleClosePreview}
        data={openPreviewPopup.data}
        enableLoading={false}
      />
    </KTCardBody>
  )
}

export default TableList
