// @ts-nocheck
import {Column} from 'react-table'
import {UserActionsCell} from './UserActionsCell'
import {User} from '../../core/_models'
import {UserSelectionHeader} from './UserSelectionHeader'
import {UserSelectionCell} from './UserSelectionCell'
import UserCell from 'src/components/user-management/UserCell'
import UserStatusCell from 'src/components/user-management/UserStatusCell'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import UserCustomHeader from 'src/components/user-management/UserCustomHeader'
import {STUDENT_PROFILE} from 'src/constants'
import CodeUser from 'src/components/user-management/CodeUser'
import DatetimeColumn from 'src/components/base/DatetimeColumn'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Code' className='min-w-200px' />,
    id: 'code',
    Cell: ({...props}) => (
      <CodeUser
        code={props.data[props.row.index]?.key}
        examination={props.data[props.row.index]?.code}
      />
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='User' />,
    id: 'full_name',
    Cell: ({...props}) => (
      <UserInfoCell
        user={props.data[props.row.index]}
        linkViewProfile={`${STUDENT_PROFILE}/${props.data[props.row.index].id}/overview`}
      />
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Username' className='w-150px' />,
    id: 'username',
    Cell: ({...props}) => <UserCell data={props.data[props.row.index]?.username} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Phone' className='min-w-125px' />
    ),
    id: 'phoneNumber',
    Cell: ({...props}) => (
      <UserCell data={props.data[props.row.index]?.detail?.phone} className='min-w-125px' />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserStatusCell status={props.data[props.row.index]?.status} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Class' className='min-w-125px' />
  //   ),
  //   accessor: 'classes',
  //   Cell: ({...props}) => <UserClassCell classes={props.data[props.row.index].classes} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Date' className='min-w-250px' />,
    id: 'updated_at',
    Cell: ({...props}) => (
      <DatetimeColumn
        updated_at={props.data[props.row.index]?.updated_at}
        created_at={props.data[props.row.index]?.created_at}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='' className='text-center sapp-absolute-column' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        id={props.data[props.row.index].id}
        status={props.data[props.row.index]?.status}
      />
    ),
  },
]

export {usersColumns}
