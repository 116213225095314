import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LoadingTable from 'src/common/LoadingTable'
import { MOCKUP_HEADER, PageLink } from 'src/constants'
import { IQuestionBankList, IQuestionList } from 'src/type/question-bank'
import SAPPCheckbox from '../../base/checkbox/SAPPCheckbox'
import DatetimeColumn from '../../base/DatetimeColumn'
import PagiantionSAPP from '../../base/pagination/PagiantionSAPP'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  htmlToRaw,
} from 'src/utils'
import SappTable from '../../base/SappTable'
import { KTCardBody } from 'src/_metronic/helpers'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_TOPIC_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import { QUESTION_TYPES } from '../shared/interfaces'
import ActiveQuestForm from '../choose-question-type/ActiveQuestForm'

const headers = [
  {
    label: 'Question',
    key: 'question_content',
    className: 'min-w-250px',
  },
  {
    label: 'Item Set Name',
    key: 'topic_description',
    className: 'min-w-250px',
  },
  {
    label: 'Type',
    key: 'qType',
    className: 'min-w-200px text-nowrap',
  },
  {
    label: 'Count',
    key: 'course_count',
    className: 'min-w-200px text-nowrap',
  },
  {
    label: 'Date',
    key: 'update_at',
    className: 'min-w-250px',
  },
]

interface IProps {
  questionList: IQuestionList | undefined
  setQuestionList: Dispatch<SetStateAction<any>>
  cleanedParams: Object
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  fetchQuestions: (page_index: number, page_size: number, object?: Object) => void
  queryParam: any
  getParams: any
}

const TableList = ({
  questionList,
  setQuestionList,
  cleanedParams,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  fetchQuestions,
  queryParam,
  getParams,
}: IProps) => {
  const navigate = useNavigate()
  const [singleQuestionId, setSingleQuestionId] = useState<any>()
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [activeQuestForm, setActiveQuestForm] = useState<QUESTION_TYPES | null>(null)
  const [reload, setReload] = useState<boolean>(false)
  const handleReload = () => setReload(!reload)

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParam.fromDate)
  const dateQueryToDate = getDateInfo(queryParam.toDate)

  //TODO: dùng để change params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParam.question_content ?? '',
      queryParam.course_id ?? '',
      queryParam.part_id ?? '',
      queryParam.chapter_id ?? '',
      queryParam.unit_id ?? '',
      queryParam.activity_id ?? '',
      queryParam.topic_id ?? '',
      queryParam.tag_id ?? '',
      queryParam.sortType ?? '',
      queryParam.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParam.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : ''
    )
  )

  // call API getTopic
  useEffect(() => {
    setLoading(true)
    fetchQuestions(queryParam.page_index || 1, queryParam?.page_size || 10)
  }, [reload])

  //TODO: đổi params trên router
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      question_content: queryParam.question_content ?? '',
      course_id: queryParam.course_id ?? '',
      part_id: queryParam.part_id ?? '',
      chapter_id: queryParam.chapter_id ?? '',
      unit_id: queryParam.unit_id ?? '',
      activity_id: queryParam.activity_id ?? '',
      topic_id: queryParam.topic_id ?? '',
      tag_id: queryParam.tag_id ?? '',
      sortType: queryParam.sortType ?? '',
      fromDate: formatDate(queryParam.fromDate) ?? '',
      toDate: formatDate(queryParam.toDate) ?? '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchQuestions(page_index, page_size)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }
  const handleOpenSingleQuestion = async (question: IQuestionBankList) => {
    setSingleQuestionId(question.id)
    setActiveQuestForm(question.qType)
    setPopupOpen(true)
    navigate(`${PageLink.TOPIC}/${question?.question_topic?.id}/question/${question?.id}`)
  }
  const { profileMe } = useUserContext()
  const allowRenderView = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.VIEW_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <KTCardBody>
      <SappTable
        headers={headers}
        loading={loading}
        data={questionList?.questions}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((_header, i) => (
              <LoadingTable key={i} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {questionList?.questions?.map((question, i) => {
              const isChecked = checkedList && checkedList.includes(question.id)
              return (
                <tr key={question?.id ?? i}>
                  <td>
                    <SAPPCheckbox
                      checked={isChecked}
                      onChange={() => {
                        toggleCheck(question.id)
                      }}
                    />
                  </td>
                  <td>
                    {allowRenderView && (
                      <div
                        className='sapp-cursor-pointer text-gray-800 text-hover-primary sapp-text-truncate-2'
                        onClick={() => (allowRenderView ? handleOpenSingleQuestion(question) : {})}
                      >
                        {htmlToRaw(question.question_content)}
                      </div>
                    )}
                  </td>
                  <td>
                    {question.is_single_question ? (
                      <div className='mw-500px sapp-text-truncate-2'>
                        {htmlToRaw(question?.question_topic?.name) ?? ''}
                      </div>
                    ) : (
                      <Link
                        className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                        to={`/topic/${question.question_topic.id}`}
                      >
                        {htmlToRaw(question?.question_topic?.name) ?? ''}
                      </Link>
                    )}
                  </td>
                  <td className='text-capitalize'>
                    {question?.qType?.replaceAll('_', ' ')?.toLowerCase()}
                  </td>
                  <td className=''>{question.course_count + question.activity_count}</td>
                  <td>
                    <DatetimeColumn
                      created_at={question?.created_at}
                      updated_at={question?.updated_at}
                    />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      {/* start:: show pagination */}
      <PagiantionSAPP
        currentPage={questionList?.meta?.page_index || 1}
        pageSize={questionList?.meta?.page_size || 10}
        totalItems={questionList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
      {/* end:: show pagination */}
      <ActiveQuestForm
        id={singleQuestionId}
        actionType={'edit'}
        activeQuestForm={activeQuestForm}
        setActiveQuestForm={setActiveQuestForm}
        openQuestForm={popupOpen}
        setOpenQuestForm={setPopupOpen}
        isSingleQuestion={true}
        handleReload={handleReload}
      />
    </KTCardBody>
  )
}

export default TableList
