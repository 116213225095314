import {VALIDATE_FILED_MAX_LENGTH, VALIDATION_FILED} from 'src/constants'
import {z} from 'zod'

export const topicValidationSchema = z.object({
  name: z
    .string({required_error: VALIDATION_FILED})
    .trim()
    .min(1, VALIDATION_FILED)
    .max(1000, VALIDATE_FILED_MAX_LENGTH('Name', 1000)),
  description: z.string().optional(),
  files: z.array(z.any().optional()).default([]),
})
