import {QuizResultComponent} from 'quiz-result-package'
import {IQuestionResult, IQuestionResultResponse} from 'quiz-result-package/dist/type'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import root from 'react-shadow/styled-components'
import {EntranceApi} from 'src/apis/entrance-test'
import {QuizResultTableStyles} from './QuizResultTableStyles'
import {PageLink} from 'src/constants'

interface ApiResponse {
  data: {
    meta: {
      total_pages: number
      total_records: number
      page_index: number
      page_size: number
    }

    answers?: Array<{
      active: string
      id: string
      is_correct: boolean
      time_spent: number
      question: {
        question_content: string
        question_topic: {
          name: string
        }
        qType: string
      }
    }>
  }
}

const QuizResultTable = () => {
  const [questionResponse, setQuestionResponse] = useState<IQuestionResultResponse>()

  const {id} = useParams()

  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const getTable = async ({page_index, page_size}: {page_index: number; page_size: number}) => {
    setLoading(true)
    try {
      const response = (await EntranceApi.getDataResultQuizTable({
        id: id,
        page_index: page_index || 1,
        page_size: page_size || 10,
      })) as ApiResponse

      if (response) {
        const newQuestionResponse: IQuestionResultResponse = {
          meta: response.data.meta,
          data: (questionResponse?.data || []).concat(
            response.data.answers?.map((e) => ({
              active: e.active,
              id: e.id,
              content: e.question.question_content,
              section: e.question.question_topic?.name,
              type: e.question.qType,
              is_correct: e.is_correct,
              time_spent: e.time_spent,
              question: e.question as any,
            })) || []
          ),
        }
        setQuestionResponse(newQuestionResponse)
      } else {
        return
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTable({page_index: 1, page_size: 10})
  }, [id])
  const handleClose = () => {
    navigate(-1)
  }

  const handleShowQuestionResultDetail = (e: IQuestionResult) => {
    navigate(PageLink.ENTRANCE_QUIZ_EXPLANATION + '/' + e.id)
  }

  return (
    <>
      <div className='position-absolute end-0 top-8 p-3 '>
        <i className='ki-outline ki-cross fs-1' onClick={handleClose} style={{cursor: 'pointer'}} />
      </div>
      <div>
        <root.div className='quote bg-white' mode='closed'>
          <style>{QuizResultTableStyles}</style>
          <div className='sapp_quiz_result__package'>
            <QuizResultComponent
              questionResponse={questionResponse}
              getTable={getTable}
              onShowDetail={handleShowQuestionResultDetail}
              loading={loading}
            />
          </div>
        </root.div>
      </div>
    </>
  )
}

export default QuizResultTable
