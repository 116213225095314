import { fetcher } from 'src/services/request'
import { IMeta, IResponseMeta } from 'src/type'
import qs from 'qs'
export interface IResponseLogData<T> {
  meta: IMeta
  logs: T[]
}
interface IResponseLogMeta<T> extends IResponseMeta {
  data: IResponseLogData<T>
}

export class ImportLogAPI {
  static getImportLogs<T>({
    page_index = 1,
    page_size,
    type,
    text,
    status,
    object_type,
  }: {
    type: string
    page_size?: number
    page_index: number
    text?: string
    status?: string
    object_type?: string
  }): Promise<IResponseLogMeta<T>> {
    const getType = (type: string) => {
      if (type.toUpperCase() === 'EXAM' && (object_type === undefined || object_type === '')) {
        return ['ACCA_EXAMINATION', 'CMA_EXAMINATION', 'CFA_EXAMINATION']
      } else {
        return object_type
      }
    }

    return fetcher(`import-logs`, {
      params: {
        page_size,
        page_index,
        object_type: getType(type),
        text: text ? text.trim() : undefined,
        status: status ? status.trim() : undefined,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  }
  static getImportLogsById({ id }: { id: string }): Promise<any> {
    return fetcher(`import-logs/${id}`)
  }
}
