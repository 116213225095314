import { Dispatch, SetStateAction } from 'react'
import { Modal } from 'react-bootstrap'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClick: () => void
  body?: string | string[]
  okButtonCaption?: string
  cancelButtonCaption?: string
  bg?: 'danger' | 'primary'
  onClose?: () => void
}

const PopupConfirmCategory = ({
  open,
  setOpen,
  onClick,
  body,
  okButtonCaption,
  // cancelButtonCaption,
  // bg,
  onClose,
}: IProps) => {
  const handleClose = () => {
    onClose && onClose()
    setOpen(false)
  }
  return (
    <Modal
      dialogClassName='modal-dialog mw-430px swal2-container sapp-popup-confirm'
      contentClassName='sapp-p-content-modal'
      centered
      show={open}
      onHide={handleClose}
    >
      <div className='swal2-icon swal2-warning swal2-icon-show d-flex'>
        <div className='swal2-icon-content'>!</div>
      </div>
      <div className='swal2-html-container text-center d-block'>
        {body
          ? typeof body === 'string'
            ? body
            : body.map((e: string) => (
                <div key={e} className='mb-2'>
                  {e}
                </div>
              ))
          : 'Bạn có chắc chắn muốn khóa không?'}
      </div>

      <div className='swal2-actions d-flex'>
        <div className='swal2-loader'></div>
        <button
          className='swal2-confirm btn fw-bold btn-danger d-inline-block min-w-70px'
          onClick={onClick}
        >
          {okButtonCaption ?? 'Yes, block!'}
        </button>
        {/* <button
          className='swal2-cancel btn fw-bold btn-active-light-primary d-inline-block min-w-70px'
          onClick={handleClose}
        >
          {cancelButtonCaption ?? 'No, cancel'}
        </button> */}
      </div>
    </Modal>
  )
}

export default PopupConfirmCategory
