import React, {useState} from 'react'
import {ArrowCollapseIcon} from 'src/common/icons'
import Processing from 'src/components/base/processing/Processing'
import './Accodian.scss'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
function Accodian({explorer, type, action}: any) {
  const [expand, setExpand] = useState(false)
  return (
    <div
      className={`sapp-parent-wrapper`}
      style={expand ? {maxHeight: `100vh`, height: 'max-content'} : {}}
    >
      <div className='d-flex align-items-center justify-content-between mb-8'>
        <div className='d-flex align-items-center gap-3'>
          <div
            className={`sapp-collapse-wrapper d-flex align-items-center ${expand && 'expanded'}`}
            onClick={() => setExpand((prevState) => !prevState)}
          >
            <ArrowCollapseIcon />
          </div>
          {type === 'course-content' && (
            <SAPPCheckbox
              checked={explorer.checked}
              onChange={() => action(explorer.id, !explorer.checked)}
            />
          )}
          <div className='sapp-text-truncate-1 sapp-text-primary fw-semibold fs-6'>
            {explorer.name}
          </div>
        </div>
        {type === 'process' && (
          <div className='d-flex align-items-center gap-4'>
            <div className='w-150px'>
              <Processing value={explorer.percent} />
            </div>
            <div>{explorer.percent}%</div>
          </div>
        )}
      </div>
      <>
        {explorer.children?.map((child: any) => {
          return (
            <div style={{marginLeft: '20px'}} key={child.id}>
              <Accodian explorer={child} type={type} action={action} />
            </div>
          )
        })}
      </>
    </div>
  )
}

export default Accodian
