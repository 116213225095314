import {Dispatch, SetStateAction} from 'react'
import SappModal from './SappModal'
import parse from 'html-react-parser'
import {IMailLog} from 'src/type/mail-log'
import createRoot from 'react-shadow'

interface IProps {
  openPreview: boolean
  setOpenPreview: Dispatch<SetStateAction<boolean>>
  mailLogDetail: IMailLog
}

const ModalPreviewMailLog = ({openPreview, setOpenPreview, mailLogDetail}: IProps) => {
  return (
    <SappModal
      centered
      hideHeader={true}
      cancelButtonCaption='Cancel'
      open={openPreview}
      title={'Preview Mail Log'}
      handleClose={() => setOpenPreview(false)}
      showFooter={false}
      dialogClassName='sapp-modal-dialog min-w-800px'
      classNameBody='d-flex justify-content-center w-100 h-100 sapp-p-body-content sapp-overflow-y'
      classNameContent='sapp-p-body-content'
      classBody='p-10 modal-body sapp-bg-gray-200 modal-maillog'
    >
      <div className='ms-5 me-5'>
        <createRoot.div>{parse(mailLogDetail?.bodyHTML || '')}</createRoot.div>
      </div>
    </SappModal>
  )
}

export default ModalPreviewMailLog
