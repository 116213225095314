import {fetcher} from 'src/services/request'
import {IResponse} from 'src/type'
import {ISubjectList} from 'src/type/subject'

export class SubjectAPI {
  static getSubjects(
    page_index: number,
    page_size: number,
    params?: Object
  ): Promise<IResponse<ISubjectList>> {
    return fetcher(`subjects?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }
}
