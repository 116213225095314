import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import { UsersListWrapper } from 'src/components/user-list/UserList'
import { PageLink } from 'src/constants'
import { ITabs } from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.STUDENTS}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Student List',
  },
]

const ListStudents = () => {
  return (
    <PageLayouts pageTitle='Student List' breadcrumbs={breadcrumbs}>
      <UsersListWrapper />
    </PageLayouts>
  )
}

export default withAuthComponents(ListStudents)
