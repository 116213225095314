import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IStudentDetail } from 'src/type/students'
import { useUserContext } from 'src/context/UserProvider'
import DeviceManager from './DeviceManager'
import { deleteListDevices, getListActivities, getListDevices } from 'src/apis/user'
import { useConfirm } from 'src/hooks/use-confirm'
import RecentHistory from './RecentHistory'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { Role } from 'src/type'

interface IProps {
  studentDetail: IStudentDetail | undefined
  loading: boolean
  setStudentDetail: Dispatch<SetStateAction<any>>
  setLoading: Dispatch<SetStateAction<boolean>>
  id: any
}

const StudentProfileSecurity = ({ setStudentDetail, id }: IProps) => {
  const { getStudentDetail } = useUserContext()
  const [listDevice, setListDevice] = useState<any>([])
  const [RecentHistoryData, setRecentHistoryData] = useState([])
  const [loading, setLoading] = useState<any>()
  const { confirm, contextHolder } = useConfirm()
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_GR.GET_SECURITY_USER_GR) ||
      hasPermission(role, TITLE_GR.GET_ACTIVITIES_USER_GR) ||
      hasPermission(role, TITLE_GR.REMOVE_SECURITY_USER_GR) ||
      hasPermission(role, TITLE_GR.REMOVE_DEVICE_USER_GR) ||
      hasPermission(role, TITLE_GR.VIEW_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowGetDevice = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_GR.GET_SECURITY_USER_GR) ||
      hasPermission(role, TITLE_GR.REMOVE_SECURITY_USER_GR) ||
      hasPermission(role, TITLE_GR.REMOVE_DEVICE_USER_GR) ||
      hasPermission(role, TITLE_GR.VIEW_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const navigate = useNavigate()
  useEffect(() => {
    if (!allowRender) {
      navigate(-1)
    }
  }, [allowRender])

  const fetchListDevice = async (id: any) => {
    try {
      const res = await getListDevices({
        id: id,
      })
      setListDevice(res)
    } catch (error: any) {
      // if (error?.response?.data?.error?.code === '403|000000' && !allowGetDevice) {
      //   toast.error(DENIED_MESSAGES)
      // }
    } finally {
      setLoading(false)
    }
    return
  }
  const fetchHistory = async (currentPage: number, pageSize: number) => {
    try {
      const res = await getListActivities({
        page_index: currentPage,
        page_size: pageSize,
        id: id,
      })
      setRecentHistoryData(res)
    } catch (error: any) {
      // if (error?.response?.data?.error?.code === '403|000000' && allowGetDevice) {
      //   toast.error(DENIED_MESSAGES)
      // }
    } finally {
      setLoading(false)
    }
    return
  }
  useEffect(() => {
    if (allowGetDevice) {
      fetchListDevice(id)
    }
    fetchHistory(1, 10)
  }, [id, allowGetDevice])

  const handleDeleteDevice = async (id: any) => {
    try {
      const response = await deleteListDevices({ id })
      if (response.success === true) {
        setListDevice(listDevice.filter((e: { id: any }) => e.id !== id))
      }
    } catch (error) {
      console.error('Error handling delete section:', error)
    }
  }

  useEffect(() => {
    if (!id || loading) return

    getStudentDetail(id)
      .then((res) => {
        setStudentDetail(res?.data)
      })
      .catch((error) => {
        // if (error?.response?.data?.error?.code === '403|000000') {
        //   toast.error(DENIED_MESSAGES)
        // }
      })
  }, [id])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        {contextHolder}
        <div className='device-header pt-7 pb-1'>
          <div className='d-flex flex-column ml-8 gap-3 mb-2 w-1/6 items-start card-title'>
            <div className='sapp-title-device '>Quản lý trình duyệt</div>
            <div className='sapp-text-drawer--description'>Tối đa 3 trình duyệt</div>
          </div>
        </div>
        <div className='card-body p-4'>
          <DeviceManager
            ListDevice={listDevice}
            loading={loading}
            setLoading={setLoading}
            fetchListDevice={fetchListDevice}
            confirm={confirm}
            onDeleteSection={handleDeleteDevice}
          />
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='text-lg fw-bold'>Lịch sử đăng nhập gần đây</h3>
          </div>
        </div>
        <div className='card-body p-4'>
          <RecentHistory
            RecentHistoryData={RecentHistoryData}
            loading={loading}
            // setLoading={setLoading}
            fetchHistory={fetchHistory}
            id={id}
          />
        </div>
      </div>
    </>
  )
}

export default StudentProfileSecurity
