import React from 'react'
import styles from 'src/components/ui/button-secondary/ButtonSecondary.module.scss'
import { IButtonProps } from 'src/type/button'
import { KTIcon } from 'src/_metronic/helpers'

interface IProps extends IButtonProps {
  iconName: string
}

const ButtonIconSecondary = ({
  title,
  size = 'large',
  disabled,
  loading,
  iconName,
  iconType,
  onClick,
  className = '',
}: IProps) => {
  const heightClass = size === 'large' ? 'sapp-h-45px' : 'sapp-h-40'

  return (
    <button
      className={`btn ${styles['sapp-btn--secondary']} ${heightClass} btn-flex btn-center ${className}`}
      disabled={loading || disabled}
      onClick={onClick && onClick}
    >
      <KTIcon iconName={iconName} iconType={iconType} className='me-1' />
      <span className='indicator-label'>{title}</span>
    </button>
  )
}

export default ButtonIconSecondary
