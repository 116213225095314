import { useState, useEffect } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import NotData from 'src/components/NotData'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import './ListUser.scss'
import { ClassesApi } from 'src/apis/classes'
import { cleanParamsAPI } from 'src/utils'
import AddMentor from '../add-mentor/AddMentor'
import { IMentor, IMentorClass, IMentorClassList } from 'src/type/mentors'
import ActionCell from 'src/components/base/action/ActionCell'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonDanger from 'src/components/ui/button-danger/ButtonDanger'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const headers = [
  {
    label: 'Mentor Name',
    key: 'mentor_name',
    className: 'fs-7 lh-1 fw-bold min-w-100px',
  },
  {
    label: 'Phone',
    key: 'phone',
    className: 'fs-7 lh-1 fw-bold min-w-100px',
  },
  {
    label: 'Email',
    key: 'email',
    className: 'fs-7 lh-1 fw-bold min-w-100px',
  },
  {
    label: 'Main Reponsible',
    key: 'responsible',
    className: 'fs-7 lh-1 fw-bold min-w-150px text-center ',
  },
]

type Props = {
  classId: string
  title: string
}

const ListMentor = ({ classId, title }: Props) => {
  const [mentorList, setMentorList] = useState<IMentorClassList>()
  const [open, setOpen] = useState<boolean>(false)
  const { contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>()

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<IMentor>(
    mentorList?.data?.map((item: IMentorClass) => ({ ...item.mentor })) || []
  )

  const getParams = (text: string) => ({
    text,
  })

  const initialValues = {
    text: '',
  }
  const [searchParams, setSearchParams] = useState<{ text: string }>(initialValues)

  const getClassMentors = async (page_index?: number, page_size?: number, params?: Object) => {
    try {
      const response = await ClassesApi.getClassMentor({
        class_id: classId,
        page_index: page_index || 1,
        page_size: page_size || 10,
        params: params,
      })
      if (response) {
        setMentorList(response?.data)
      }
    } catch (error) {
      // donothing
    }
  }

  const handleDeleteMentors = async (mentorId: string[]) => {
    try {
      setLoading(true)
      const data = mentorList?.data.filter(
        (item: IMentorClass) => !mentorId.includes(item.mentor.id)
      )

      const isNotMain = data?.find((item: IMentorClass) => item.is_main_mentor === true)
      const mentors = data?.map((item, idx) => ({
        mentor_id: item.mentor.id,
        is_main_mentor: isNotMain?.id ? item.is_main_mentor : idx === 0,
      }))
      await ClassesApi.updateClass({ mentors, id: classId })
      getClassMentors()
      toggleCheckAll(false)
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  const handleMainMentor = async (mentorId: string) => {
    try {
      setLoading(true)
      const mentors = mentorList?.data.map((item: IMentorClass) => {
        return {
          mentor_id: item.mentor.id,
          is_main_mentor: mentorId === item.id,
        }
      })
      await ClassesApi.updateClass({ mentors, id: classId })
      getClassMentors()
    } catch {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassMentors(page_index, page_size, cleanedParams)
  }

  const handleOnClick = () => {
    setOpen(true)
  }

  const handleClosePopup = () => {
    setOpen(false)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedParams = {
      text: e.target.value.toLowerCase(),
    }
    setSearchParams(() => {
      return cleanedParams
    })
  }

  const handleSearchActive = () => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassMentors(1, mentorList?.metadata.page_size || 10, cleanedParams)
  }

  useEffect(() => {
    getClassMentors()
  }, [])

  return (
    <div>
      {contextHolder}
      <div className='mb-5'>
        <div className='py-8 px-10'>
          <div
            className={`d-flex justify-content-between align-items-center gap-5 flex-wrap ${
              mentorList?.data?.length ? ' mb-5' : ''
            }`}
          >
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search Mentor'}
                smallSearch={true}
                isListScreen
              ></Search>
              <ButtonPrimary
                title='Search'
                onClick={() => {
                  handleSearchActive()
                }}
                className='ms-4'
                size='small'
              />
            </div>
            <div className='d-flex justify-content-between'>
              <div className='d-flex'>
                {checkedList?.length > 0 ? (
                  <>
                    <div className='fw-bold sapp-checkbox-text-custom me-5 pt-5'>
                      <span>{checkedList?.length}</span> Selected
                    </div>
                    <ButtonDanger
                      title={'Delete Selected'}
                      onClick={() => handleDeleteMentors(checkedList)}
                      loading={loading}
                      size='small'
                    />
                  </>
                ) : (
                  <ButtonIconPrimary
                    iconName={'plus'}
                    title={'Add Mentor'}
                    onClick={handleOnClick}
                    size='small'
                  />
                )}
              </div>
            </div>
          </div>
          <h5 className='fw-bold fs-6 mt-8'>
            {title} ({mentorList?.metadata.total_records || 0})
          </h5>
          <div className='card card-flush border-gray-300 h-xl-100 mt-3'>
            <div className='container'>
              <div className='row px-4 py-8'>
                <div className='table-responsive'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-0'>
                          <SAPPCheckbox
                            checked={isCheckedAll}
                            ktCheck={isCheckedAll}
                            className={'ps-0'}
                            onChange={() => {
                              toggleCheckAll(!isCheckedAll, true)
                            }}
                          />
                          {headers?.map((column) => (
                            <th key={column.label} className={column.className}>
                              {column.label}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-semibold'>
                        {(() => {
                          if (!mentorList?.metadata.total_records) {
                            return (
                              <tr>
                                <td colSpan={7}>
                                  <NotData />
                                </td>
                              </tr>
                            )
                          }
                          return mentorList?.data?.map((data: IMentorClass, i: number) => {
                            const isChecked = checkedList.includes(data?.mentor.id)
                            return (
                              <tr className='border-0' key={data?.id ?? i}>
                                <td>
                                  <SAPPCheckbox
                                    checkTarget='#kt_table_users .form-check-input'
                                    checked={isChecked}
                                    ktCheck={isChecked}
                                    onChange={() => {
                                      toggleCheck(data?.mentor.id!)
                                    }}
                                  />
                                </td>
                                <td className='text-start text-break sapp-table-title-des'>
                                  {data.mentor?.detail?.full_name}
                                </td>
                                <td className='text-start'>{data.mentor?.detail?.phone}</td>
                                <td className='text-start'>{data.mentor?.detail?.email}</td>
                                <td className='text-start'>
                                  <div className='d-flex justify-content-center'>
                                    <SAPPRadio
                                      checked={data.is_main_mentor}
                                      ktCheck={data.is_main_mentor}
                                      onChange={() => {
                                        handleMainMentor(data.id)
                                      }}
                                    />
                                  </div>
                                </td>
                                <td className='text-end'>
                                  <ActionCell customWidth='w-150px'>
                                    <div
                                      className='menu-item px-3'
                                      onClick={() => handleDeleteMentors([data?.mentor?.id])}
                                    >
                                      <div className='menu-link px-3'>Delete</div>
                                    </div>
                                  </ActionCell>
                                </td>
                              </tr>
                            )
                          })
                        })()}
                      </tbody>
                    </table>
                  </div>
                </div>
                <PagiantionSAPP
                  currentPage={mentorList?.metadata?.page_index || 1}
                  pageSize={mentorList?.metadata?.page_size || 10}
                  totalItems={mentorList?.metadata?.total_records}
                  handlePaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
          <AddMentor
            open={open}
            setOpen={handleClosePopup}
            id={classId}
            getClassMentor={getClassMentors}
          />
        </div>
      </div>
    </div>
  )
}

export default ListMentor
