import { useEffect, useMemo, useState } from 'react'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { BUTTON_TEXT } from 'src/constants/lang'
import TableList from './TableList'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import useChecked from 'src/hooks/use-checked'
import { getClassCourse } from 'src/apis/user'
import { IClassCourseList } from 'src/type/class-user'
import { cleanParamsAPI } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { DEFAULT_SELECT_ALL, LEARNING_STATUS } from 'src/constants'
import HookFormTextField from '../base/textfield/HookFormTextField'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import { ICourseCategoies } from 'src/type/courses'
import { CoursesAPI } from 'src/apis/courses'
import { Select } from 'antd'
import useCourseFilter from 'src/hooks/use-course-filter'

const initialValues: any = {
  class_code: '',
  course_id: '',
  course_category_id: '',
  status: '',
}

export type FilterType = {
  class_code: string
  course_id: string
  course_category_id: string
  status: string
}

interface IProps {
  id: string
}

const fileNames = ['class_code', 'course_id', 'course_category_id', 'status']

const { Option } = Select

const ClassUserManagement = ({ id }: IProps) => {
  const [dataList, setDataList] = useState<IClassCourseList>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page_index = parseInt(queryParams.get('page_index') as string)
  const [loading, setLoading] = useState(true)
  const page_size = parseInt(queryParams.get('page_size') as string)
  const searchParams = new URLSearchParams(location.search)
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll, listDataChecked } = useChecked<
    typeof initialValues
  >(dataList?.data)

  const validationSchema = z.object({
    class_code: z.string().optional(),
    course_id: z.string().optional(),
    course_category_id: z.string().optional(),
    status: z.string().optional(),
  })

  // Using validate for input
  const { control, getValues, reset, setValue } = useForm<FilterType>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const fetchData = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await getClassCourse({
        id: id,
        page_index: currentPage || 1,
        page_size: pageSize || 10,
        params: params,
      })
      setDataList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleResetFilter = () => {
    reset()
    fileNames.forEach((fieldName) => {
      setValue(fieldName as typeof initialValues, initialValues[fieldName])
    })
    navigate(location.pathname)
    setLoading(true)
    toggleCheckAll(false)
    fetchData(1, 10)
  }

  const navigate = useNavigate()

  const getParams = (
    class_code: string,
    course_id: string,
    course_category_id?: string,
    status?: string
  ) => ({
    class_code,
    course_id,
    course_category_id,
    status,
  })

  const queryParam = {
    class_code: searchParams.get('class_code'),
    course_id: searchParams.get('course_id'),
    course_category_id: searchParams.get('course_category_id'),
    status: searchParams.get('status'),
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      class_code: getValues('class_code'),
      course_id: replaceValueAll(getValues('course_id')),
      course_category_id: replaceValueAll(getValues('course_category_id')) ?? '',
      status: replaceValueAll(getValues('status')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const onSubmit = () => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('class_code')?.trimStart()?.trimEnd(),
        replaceValueAll(getValues('course_id')),
        replaceValueAll(getValues('course_category_id')),
        replaceValueAll(getValues('status'))
      )
    )
    handleChangeParams(page_index || 1, page_size || 10)
    setLoading(true)
    fetchData(1, 10, cleanedParams)
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác

  const cleanedParams = cleanParamsAPI(
    getParams(
      queryParam.class_code ?? '',
      queryParam.course_id ?? '',
      queryParam.course_category_id ?? '',
      queryParam.status ?? ''
    )
  )

  const newCourseCategory = useMemo(
    () =>
      courseCategory?.course_categories?.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [courseCategory]
  )
  const fetchCourseCategory = async () => {
    try {
      const res = await CoursesAPI.getCategory({ page_index: 1, page_size: 100 })
      setCourseCategory(res?.data)
    } catch (error) {}
  }

  useEffect(() => {
    fetchCourseCategory()
  }, [])

  const {
    listCourse,
    setListCourse,
    getCourses,
    clearChildren,
    debouncedGetCourses,
    handleNextPage,
  } = useCourseFilter(setValue)

  return (
    <div className='card'>
      <div className='card-header border-0 pt-6'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-3 ps-xl-0 pe-md-3 ps-0 pe-0'>
              <div className='card-title justify-content-center mb-0 mx-0'>
                <HookFormTextField
                  control={control}
                  name='class_code'
                  placeholder='Class Code'
                  defaultValue={queryParam.class_code ?? ''}
                  onSubmit={onSubmit}
                  isListScreen
                />
              </div>
            </div>
            <div className='col-sm-3 mt-2 px-0 pe-md-3'>
              <HookFormSelectAntd
                name='course_id'
                placeholder='Course'
                control={control}
                classNameHeight='sapp-h-40'
                showSearch
                onFocus={async () => {
                  if (listCourse?.course?.data.length <= 0) {
                    await getCourses()
                    return
                  }
                }}
                handleNextPage={handleNextPage}
                onChange={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                }}
                onSearch={async (e) => {
                  debouncedGetCourses(e)
                  return
                }}
                loading={listCourse.course.loading}
                allowClear
                onClear={() => {
                  setListCourse(clearChildren('course'))
                }}
              >
                {listCourse.course.data?.map((e) => {
                  return (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  )
                })}
              </HookFormSelectAntd>
            </div>
            <div className='col-sm-3 mt-2 ps-md-0'>
              <HookFormSelectAntd
                control={control}
                name='course_category_id'
                placeholder='Program'
                defaultValue={queryParam.course_category_id ?? ''}
                showSearch
                classNameHeight='sapp-h-40'
                onChange={() => {}}
              >
                {DEFAULT_SELECT_ALL?.concat(newCourseCategory ?? []).map((course_category_ids) => (
                  <Option key={course_category_ids.value} value={course_category_ids.value}>
                    {course_category_ids.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-sm-3 mt-2 ps-md-0'>
              <HookFormSelectAntd
                name='status'
                placeholder='Status'
                control={control}
                defaultValue={queryParam?.status ?? ''}
                classNameHeight='sapp-h-40'
              >
                {LEARNING_STATUS.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4 col-lg-9 col-xl-4 px-0 px-md-0'>
              <SAPPFIlterButton
                okClick={onSubmit}
                resetClick={handleResetFilter}
                titleReset='Reset'
                titleSubmit={BUTTON_TEXT.SEARCH}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <TableList
        dataList={dataList}
        setDataList={setDataList}
        cleanedParams={cleanedParams}
        loading={loading}
        setLoading={setLoading}
        getParams={getParams}
        checkedList={checkedList}
        toggleCheck={toggleCheck}
        toggleCheckAll={toggleCheckAll}
        isCheckedAll={isCheckedAll}
        fetchData={fetchData}
        queryParam={queryParam}
      />
    </div>
  )
}

export default ClassUserManagement
