import { isEmpty } from 'lodash'
import NotData from '../NotData'
import SAPPCheckbox from './checkbox/SAPPCheckbox'
import './SappTable.scss'
import { MOCKUP_HEADER } from 'src/constants'
import LoadingTable from 'src/common/LoadingTable'
import { ITable } from 'src/type'
interface ITableClassDetail extends ITable {
  hasCheckAll?: boolean
  showHeader?: boolean
  showHashtag?: boolean
  classResponsive?: string
  classTable?: string
  classString?: string
  disabled?: boolean
  classNameTable?: string
  classNameSelect?: string
  data?: Array<any> | undefined
}

const SappTable = ({
  children,
  headers,
  loading,
  data,
  isCheckedAll,
  onChange,
  hasCheckAll = true,
  hasCheck = true,
  showHeader = true,
  showHashtag = false,
  classResponsive = '',
  classTable = 'table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer',
  classString,
  disabled,
  classNameSelect,
}: ITableClassDetail) => {
  return (
    <div className={`table-responsive ${classResponsive}`}>
      <table className={classTable}>
        {showHeader && (
          <thead>
            <tr className={`text-start text-muted fw-bold text-uppercase gs-0 ${classString}`}>
              {hasCheck && (
                <th className={`w-50px ${classNameSelect ? classNameSelect : 'pe-5'}`}>
                  {hasCheckAll && (
                    <SAPPCheckbox
                      checked={isCheckedAll}
                      ktCheck={isCheckedAll}
                      onChange={onChange}
                      disabled={disabled}
                    />
                  )}
                </th>
              )}
              {showHashtag && <th className='w-40px'>#</th>}
              {headers?.map((column) => (
                <th
                  key={column.label}
                  className={`${column.className} sapp-text-header--column fw-bold`}
                  onClick={column.onClick && column.onClick}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className='text-gray-600 fw-semibold sapp-fs-14'>
          {loading ? (
            <>
              {MOCKUP_HEADER.map((_header, i) => (
                <LoadingTable key={i} headers={MOCKUP_HEADER} />
              ))}
            </>
          ) : (
            children
          )}
        </tbody>
      </table>

      {isEmpty(data) && !loading && <NotData />}
    </div>
  )
}

export default SappTable
