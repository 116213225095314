import React, {Dispatch, SetStateAction} from 'react'
import {PageLink} from 'src/constants'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'

interface IProps {
  confirmDeactive: boolean
  setConfirmDeactive: Dispatch<SetStateAction<boolean>>
  blockedUser: () => void
}

const DeactivateAccount = ({confirmDeactive, setConfirmDeactive, blockedUser}: IProps) => {
  return (
    <div className='card d-none'>
      <div className='card-header border-0 cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>Deactivate Account</h3>
        </div>
      </div>

      <div className='collapse show'>
        <div className='form'>
          <div className='card-body border-top p-9'>
            <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
              <i className='ki-outline ki-information fs-2tx text-warning me-4'></i>

              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-semibold'>
                  <h4 className='text-gray-900 fw-bold'>You Are Deactivating Your Account</h4>
                  <div className='fs-6 text-gray-700'>
                    For extra security, this requires you to confirm your email or phone number when
                    you reset yousignr password.
                    <br />
                    <a className='fw-bold' href={PageLink.DASHBOARD}>
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex'>
              <SAPPCheckbox
                checked={confirmDeactive}
                onChange={(e) => setConfirmDeactive(e.target.checked)}
              />
              <label className='form-check-label fw-semibold ps-2 fs-6'>
                I confirm my account deactivation
              </label>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              className='btn btn-danger fw-semibold'
              disabled={!confirmDeactive}
              onClick={blockedUser}
            >
              Deactivate Account
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeactivateAccount
