import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import { resetPassUser, UsersAPI } from 'src/apis/user'
import PopupConfirm from 'src/common/PopupConfirm'
import UserAction from 'src/components/user-management/UserAction'
import { STUDENT_PROFILE } from 'src/constants'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import ModalEditEmail from '../../user-edit-modal/ModalEditEmail'
import ModalResetPassword from '../../user-edit-modal/ModalResetPassword'

type Props = {
  id: any
  status: 'BLOCKED'
  email: 'string'
}

const UserActionsCell: FC<Props> = ({ id, status, email }) => {
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()
  const [openReset, setOpenReset] = useState(false)
  const [openBlocked, setOpenBlocked] = useState(false)
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const [openModalShowPass, setOpenModalShowPass] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const { refetch } = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const blockUser = useMutation(() => UsersAPI.blocked([id]), {
    onSuccess: () => {
      queryClient.invalidateQueries([`students-${query}`])
      toast.success('Blocked Successfully!')
      setOpenBlocked(false)
      cancel(true)
    },
    onError: () => {},
  })

  const handleShowPassword = async () => {
    await resetPassUser(id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setOpenReset(false)
        setOpenModalShowPass(true)
        setNewPassword(res?.data?.password)
      })
      .catch((err) => {
        // if (err?.response?.data?.error?.code === '403|000000') {
        //   toast.error(DENIED_PERMISSIONS)
        // } else toast.error('Reset password failed!')
      })
  }

  return (
    <>
      <UserAction
        setOpenBlocked={setOpenBlocked}
        setOpenReset={setOpenReset}
        status={status}
        linkProfile={`${STUDENT_PROFILE}/${id}/overview`}
        setOpenEditEmail={setOpenEditEmail}
        linkEdit={`${STUDENT_PROFILE}/${id}/setting`}
      />
      <PopupConfirm
        open={openBlocked}
        setOpen={setOpenBlocked}
        onClick={async () => await blockUser.mutateAsync()}
      />
      <ModalResetPassword
        open={openModalShowPass}
        setOpen={setOpenModalShowPass}
        newPassword={newPassword}
        content='Mật khẩu mới của học viên là:'
      />
      <PopupConfirm
        open={openReset}
        setOpen={setOpenReset}
        onClick={handleShowPassword}
        body='Bạn có chắc chắn muốn đổi mật khẩu không?'
        okButtonCaption='Yes, reset!'
      />
      <ModalEditEmail
        id={id}
        open={openEditEmail}
        setOpen={setOpenEditEmail}
        isActive={isActive}
        setIsActive={setIsActive}
        currentEmail={email}
        refetch={cancel}
      />
      {/* end::Menu */}
    </>
  )
}

export { UserActionsCell }
