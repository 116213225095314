import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { secondsToMinutesAndSeconds } from 'src/utils'
import { useUserContext } from 'src/context/UserProvider'
import { PageLink } from 'src/constants'
import SAPPTextFiled from 'src/components/base/textfield/SAPPTextFiled'
import sapp from 'src/_metronic/assets/media/logos/sapp-logo-default.svg'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

const VerifyCode = () => {
  const { emailStaff, verifyOTP, forgotPasswordOTP } = useUserContext()
  const [isActive, setIsActive] = useState(true)
  const navigate = useNavigate()
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [durationResend, setDurationResend] = useState(15)
  const [startTime, setStartTime] = useState<any>(new Date())
  const [elapsedTime, setElapsedTime] = useState(300)

  useEffect(() => {
    if (isActive && elapsedTime > 0) {
      const intervalId = setInterval(() => {
        setElapsedTime((prevTime) => Math.max(0, prevTime - 1))
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [startTime, isActive])

  useEffect(() => {
    let interval: any

    if (durationResend > 0) {
      interval = setInterval(() => {
        setDurationResend((prev) => {
          if (prev - 1 === 0) {
            clearInterval(interval)
          }
          return prev - 1
        })
      }, 1000)
    }

    // Clear the interval when the component unmounts
    return () => clearInterval(interval)
  }, [durationResend])

  const resendOTP = () => {
    forgotPasswordOTP(emailStaff).then(() => {
      setDurationResend(15)
      elapsedTime === 0 && setElapsedTime(300)
      setIsActive(true)
      setOtp(['', '', '', '', '', ''])
    })
  }

  const handleInputChange = (index: number, e: any) => {
    const value = e.target.value

    // Update the OTP array
    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    // Check if all OTP digits are filled
    const isComplete = newOtp.every((digit) => digit.length === 1)

    // Enable/disable the button accordingly
    setButtonDisabled(!isComplete)

    // Move to the next input if the current one is filled
    if (value.length === 1 && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`)
      if (nextInput) {
        nextInput.focus()
      }
    }
  }

  const handlePaste = (index: number, e: any) => {
    const pasted = e.clipboardData.getData('text/plain').split(' ').slice(0, 6)

    // Update the OTP array
    const newOtp = [...otp]
    newOtp.splice(index, pasted.length, ...pasted)
    setOtp(newOtp)

    // Enable/disable the button accordingly
    setButtonDisabled(!newOtp.every((digit) => digit.length === 1))
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    // Implement your submission logic here
    await verifyOTP(emailStaff, otp.join('')).then(() => navigate(PageLink.AUTH_CHAGEPASSWORD))
  }

  return (
    <div className='d-flex flex-column flex-root sapp-height-layout--login justify-content-center'>
      <div className='d-flex justify-content-center'>
        <img className='sapp-logo w-125px object-fit-contain' src={sapp} alt='sapp' />
      </div>
      <div className='d-flex flex-column  flex-lg-row justify-content-center'>
        <div className='d-flex  flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20'>
          <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20 box-shadow--custom'>
            <div className='d-flex flex-center flex-column'>
              {/* Start Form Login */}
              <div className='form w-100'>
                <div className='text-center mb-11'>
                  <h1 className='text-dark fw-bolder mb-3 text-left'>Forgot Password</h1>
                  <div className='text-gray-500 fw-semibold fs-6 text-left'>
                    Enter the 6 digits code that you received on your email
                  </div>
                </div>

                {/* Start TextField Email */}
                <div className='d-flex mb-2 fv-row'>
                  {otp.map((digit, index) => (
                    <SAPPTextFiled
                      key={index}
                      id={`otp-input-${index}`}
                      type='text'
                      maxLength={1}
                      value={digit}
                      onChange={(e) => handleInputChange(index, e)}
                      onPaste={(e: any) => handlePaste(index, e)}
                      className={`${index === 5 ? '' : 'me-3'} text-center sapp-h-70px`}
                    />
                  ))}
                </div>
                {/* End TextField Email */}
                <div className='text-end mt-0 mb-10'>
                  {isActive && emailStaff && elapsedTime > 0
                    ? secondsToMinutesAndSeconds(elapsedTime)
                    : ''}
                </div>
                {/* Start Button Login */}
                <div className='d-grid mb-5 sapp-h-50px'>
                  <ButtonPrimary
                    title='Verify code'
                    disabled={isButtonDisabled}
                    onClick={handleSubmit}
                    className='sapp-h-50px'
                  />
                </div>
                {/* End Button Login */}
              </div>

              <div className='d-grid w-100 sapp-h-50px'>
                <ButtonSecondary
                  title='Resend code'
                  onClick={resendOTP}
                  disabled={durationResend > 0}
                  className='me-0 sapp-h-50px'
                />
              </div>
              {/* End Form Login */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyCode
