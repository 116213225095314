import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import Card from 'src/components/base/Card'
import RenderStudentProfile from 'src/components/student-profile/RenderStudentProfile'
import { MY_PROFILE, PageLink, STUDENT_PROFILE } from 'src/constants'
import { ITabs } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import { divide, round } from 'lodash'

const StudentProfile = () => {
  const [loading, setLoading] = useState(true)
  const [studentDetail, setStudentDetail] = useState<IStudentDetail>()
  const { id } = useParams()
  const breadcrumbs: ITabs[] = [
    {
      link: `${STUDENT_PROFILE}/${id}/overview`,
      title: 'LMS',
    },
    {
      link: `${PageLink.STUDENTS}`,
      title: 'Students List ',
    },
    {
      link: `${PageLink.AUTH_LOGIN}`,
      title: 'Student Profile',
    },
  ]

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${STUDENT_PROFILE}/${id}/overview`,
      title: 'Overview',
    },
    {
      link: `${STUDENT_PROFILE}/${id}/setting`,
      title: 'Setting',
    },
    {
      link: `${STUDENT_PROFILE}/${id}/class`,
      title: 'Class & Course',
    },
    // {
    //   link: `${STUDENT_PROFILE}/${id}/activity`,
    //   title: 'Activity',
    // },
    // {
    //   link: `${STUDENT_PROFILE}/${id}/billing`,
    //   title: 'Billing',
    // },
    // {
    //   link: `${STUDENT_PROFILE}/${id}/statements`,
    //   title: 'Statements',
    // },
    {
      link: `${STUDENT_PROFILE}/${id}/${MY_PROFILE.CERTIFICATE}`,
      title: 'Certificate',
    },
    {
      link: `${STUDENT_PROFILE}/${id}/security`,
      title: 'Security',
    },
    // {
    //   link: `${STUDENT_PROFILE}/${id}/logs`,
    //   title: 'Logs',
    // },
    // {
    //   link: `${STUDENT_PROFILE}/${id}/course`,
    //   title: 'Course',
    // },
  ]

  // const filteredTabs = allowRenderEdit ? tabs : tabs.filter((tab) => tab.title !== 'Setting')

  const getStudentDetail = async () => {
    setLoading(true)
    try {
      const res = await UsersAPI.detail(id)
      setStudentDetail(res?.data)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!loading) return
    getStudentDetail()
  }, [loading])

  const inputFields = [
    studentDetail?.key,
    studentDetail?.detail?.full_name,
    studentDetail?.username,
    studentDetail?.hubspot_contact_id,
    studentDetail?.detail?.email,
    studentDetail?.detail?.phone,
    studentDetail?.status,
    studentDetail?.key,
    studentDetail?.detail?.sex,
    studentDetail?.detail?.university,
    studentDetail?.detail?.major,
    studentDetail?.detail?.learning_purpose,
    studentDetail?.detail?.contact_detail,
    studentDetail?.detail?.special_note,
    studentDetail?.detail?.classification,
    studentDetail?.detail?.note,
    studentDetail?.files?.[0]?.file_url,
    studentDetail?.files?.[1]?.file_url,
    studentDetail?.files?.[2]?.file_url,
  ]

  const countNonEmptyFields = () => {
    return inputFields.filter((input) => input !== '' && input !== null && input !== undefined)
      .length
  }

  return (
    <PageLayouts pageTitle='Student Profile' breadcrumbs={breadcrumbs}>
      <Card
        userDetail={studentDetail}
        tabs={tabs}
        loading={loading}
        progress={round(divide(countNonEmptyFields(), inputFields.length), 2)}
      />
      <RenderStudentProfile
        studentDetail={studentDetail}
        setLoading={setLoading}
        loading={loading}
        setStudentDetail={setStudentDetail}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(StudentProfile)
