import { ITabs } from 'src/type'
import SappTabs from 'src/components/tabs/SappTabs'
import { Popover, Skeleton } from 'antd'
import MoreInfoClass from './MoreInfoClass'

interface IProps {
  classDetail: any | undefined
  tabs: ITabs[]
  loading: boolean
}

const Card = ({ classDetail, tabs, loading }: IProps) => {
  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='pt-10 px-10 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start mb-9'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-5'>
                  {/* Start Name */}
                  {loading ? (
                    <Skeleton.Button active block />
                  ) : (
                    <div className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                      {classDetail?.name}
                    </div>
                  )}
                  {/* End Name */}
                </div>
                <MoreInfoClass
                  durationType={classDetail?.duration_type}
                  status={classDetail?.status?.toLowerCase()}
                  area={classDetail?.area?.name}
                  flexible={classDetail?.flexible_days}
                  startedAt={classDetail?.started_at}
                  finishedAt={classDetail?.finished_at}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Start Tabs */}
        {loading ? <Skeleton.Button active block /> : <SappTabs tabs={tabs} />}
        {/* End Tabs */}
      </div>
    </div>
  )
}

export default Card
