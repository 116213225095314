import { Location } from 'react-router-dom'
import { PROGRAM } from 'src/type'
import { PageLink } from '.'

export const EXAM_TITLES = {
  list: 'Exam List',
}

export const EXAM_DETAIL_URL = {
  OVERVIEW: 'overview',
  SETTING: 'setting',
}

// Kì thi, ID, Mở đăng ký, Hạn đăng ký sớm, hạn đăng ký chuẩn, Lịch thi (Môn + Ngày thi)
export const TITLE_OVERVIEW_EXAM = {
  name: 'Kỳ thi',
  id: 'ID',
  code: 'Code',
  registration_opening_date: 'Mở Đăng kí',
  early_registration_deadline: 'Hạn Đăng kí sớm',
  standard_registration_deadline: 'Hạn Đăng kí chuẩn',
  registration_closing_date: 'Ngày đăng ký cuối',
  exam_schedule: 'Lịch thi',
}

export const getExamListLinks = (location: Location<any>) =>
  Object.values(PROGRAM).map((program) => ({
    id: `id-${program}-list`,
    name: `${program} Exams`,
    link: `${PageLink.EXAMS}/${program}`,
    active: location.pathname === `${PageLink.EXAMS}/${program}`,
  }))
