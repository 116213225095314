export interface IImportLogDetailHeader {
  label: string
  id: string
  className: string
  cellClassName?: string
  tdClassName?: string
}

interface IImportLogDetailHeaders {
  STAFF: IImportLogDetailHeader[]
  STUDENT: IImportLogDetailHeader[]
  TEACHER: IImportLogDetailHeader[]
  CLASS_STUDENT: IImportLogDetailHeader[]
  CLASS: IImportLogDetailHeader[]
  TEST_PARTICIPANT: IImportLogDetailHeader[]
  exam: IImportLogDetailHeader[]
}

export const IMPORT_LOG_DETAIL_HEADER: IImportLogDetailHeaders = {
  STAFF: [
    {
      label: 'Fullname',
      id: 'full_name',
      className: 'min-w-200px',
    },
    {
      label: 'username',
      id: 'username',
      className: 'min-w-150px bg-white',
      tdClassName: 'bg-white',
    },
    {
      label: 'email',
      id: 'email',
      className: 'min-w-200px',
    },
    {
      label: 'password',
      id: 'password',
      className: 'min-w-150px',
    },
    {
      label: 'phone',
      id: 'phone',
      className: 'min-w-100px',
    },
    {
      label: 'status',
      id: 'status',
      className: 'min-w-100px',
    },
  ],
  STUDENT: [
    {
      label: 'username',
      id: 'username',
      className: 'min-w-150px bg-white',
      tdClassName: 'bg-white',
    },
    {
      label: 'password',
      id: 'password',
      className: 'min-w-150px',
    },
    {
      label: 'email',
      id: 'email',
      className: 'min-w-200px',
    },
    {
      label: 'phone',
      id: 'phone',
      className: 'min-w-100px',
    },
    {
      label: 'level',
      id: 'level',
      className: 'min-w-50x',
    },
    {
      label: 'Fullname',
      id: 'full_name',
      className: 'min-w-200px',
    },
    {
      label: 'address',
      id: 'address',
      className: 'min-w-150px',
      cellClassName: 'sapp-text-truncate-3',
    },
    {
      label: 'major',
      id: 'major',
      className: 'min-w-150px',
    },
    {
      label: 'dob',
      id: 'dob',
      className: 'min-w-100px',
      cellClassName: 'text-nowrap',
    },
    {
      label: 'note',
      id: 'note',
      className: 'min-w-150px',
      cellClassName: 'sapp-text-truncate-3',
    },
    {
      label: 'gender',
      id: 'gender',
      className: 'min-w-100px',
    },
    {
      label: 'university',
      id: 'university',
      className: 'min-w-150px',
    },
    {
      label: 'status',
      id: 'status',
      className: 'min-w-100px',
    },
  ],
  TEACHER: [
    {
      label: 'username',
      id: 'username',
      className: 'min-w-150px bg-white',
      tdClassName: 'bg-white',
    },
    {
      label: 'password',
      id: 'password',
      className: 'min-w-150px',
    },
    {
      label: 'email',
      id: 'email',
      className: 'min-w-200px',
    },
    {
      label: 'phone',
      id: 'phone',
      className: 'min-w-100px',
    },
    {
      label: 'Fullname',
      id: 'full_name',
      className: 'min-w-200px',
    },
    {
      label: 'address',
      id: 'address',
      className: 'min-w-200px',
      cellClassName: 'sapp-text-truncate-3',
    },
    {
      label: 'dob',
      id: 'dob',
      className: 'min-w-200px',
      cellClassName: 'text-nowrap',
    },
    {
      label: 'note',
      id: 'note',
      className: 'min-w-250px',
      cellClassName: 'sapp-text-truncate-3',
    },
    {
      label: 'gender',
      id: 'gender',
      className: 'min-w-150px',
    },
    {
      label: 'status',
      id: 'status',
      className: 'min-w-100px',
    },
  ],
  CLASS: [
    {
      label: 'areaCode',
      id: 'areaCode',
      className: 'min-w-100px',
    },
    {
      label: 'name',
      id: 'name',
      className: 'min-w-250px',
    },
    {
      label: 'code',
      id: 'code',
      className: 'min-w-150px',
    },
    {
      label: 'capacity',
      id: 'capacity',
      className: 'min-w-100px',
    },
    {
      label: 'opening_at',
      id: 'opening_at',
      className: 'min-w-150px ',
      cellClassName: 'text-nowrap',
    },
    {
      label: 'started_at',
      id: 'started_at',
      className: 'min-w-150px',
      cellClassName: 'text-nowrap',
    },
    {
      label: 'finished_at',
      id: 'finished_at',
      className: 'min-w-150px',
      cellClassName: 'text-nowrap',
    },
    {
      label: 'duration_type',
      id: 'duration_type',
      className: 'min-w-150px',
      cellClassName: 'text-nowrap',
    },
    {
      label: 'flexible_days',
      id: 'flexible_days',
      className: 'min-w-150px',
      cellClassName: 'text-nowrap',
    },
    {
      label: 'course_code',
      id: 'course_code',
      className: 'min-w-150px',
    },
    {
      label: 'exam_code',
      id: 'exam_code',
      className: 'min-w-150px',
    },
    {
      label: 'type',
      id: 'type',
      className: 'min-w-150px',
    },
  ],
  CLASS_STUDENT: [
    {
      label: 'email',
      id: 'email',
      className: 'min-w-150px',
    },
  ],
  TEST_PARTICIPANT: [
    {
      label: 'email',
      id: 'email',
      className: 'min-w-150px',
    },
  ],
  exam: [
    {
      label: 'Exam Date',
      id: 'exam_date',
      className: 'min-w-150px',
    },
    {
      label: 'season',
      id: 'season',
      className: 'min-w-150px',
    },
    {
      label: 'subject_code',
      id: 'subject_code',
      className: 'min-w-150px',
    },
  ],
}
