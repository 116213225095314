import { useParams } from 'react-router-dom'
import ExamList from 'src/components/exam/ExamList'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { EXAM_TITLES } from 'src/constants/exam'
import { ITabs, PROGRAM } from 'src/type'

// Type guard to ensure only valid exam types are used
const isValidExamType = (type: string): type is PROGRAM => {
  return Object.values(PROGRAM).includes(type.toUpperCase() as PROGRAM)
}

function ExamListPage() {
  const { program } = useParams()

  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.EXAMS}/${program}`,
      title: `${program?.toUpperCase()} Exams`,
    },
  ]

  if (!program || !isValidExamType(program)) {
    return <div>Invalid exam type</div>
  }

  return (
    <PageLayouts pageTitle={`${program?.toUpperCase()} Exams`} breadcrumbs={breadcrumbs}>
      <ExamList />
    </PageLayouts>
  )
}

export default ExamListPage
