export const DEFAULT_ATTEMPT_DATA = {
  id: '',
  attempt_answers: [] as string[],
  is_present: false,
  index: 0,
  question_id: '',
}

export const STATUS_ALL = {
  GRADED: {
    value: 'graded',
    label: 'Graded',
  },
  CORRECT: {
    value: 'correct',
    label: 'Correct',
  },
  INCORRECT: {
    value: 'incorrect',
    label: 'Incorrect',
  },
  UNGRADED: {
    value: 'ungraded',
    label: 'Chưa chấm',
  },
  CONDITIONS: {
    SUBMITTED: 'Submitted',
    UNSUBMITTED: 'Unsubmitted',
    CORRECT: 'Correct',
    INCORRECT: 'Incorrect',
  },
  ANSWERS: {
    CORRECTS: 'corrects',
    WRONGS: 'wrongs',
    INCORRECTS: 'incorrects',
  },
}

export const FILTER = [
  [STATUS_ALL.GRADED, STATUS_ALL.CORRECT],
  STATUS_ALL.INCORRECT,
  STATUS_ALL.UNGRADED,
]

export const DROPDOWN_ACTION = [
  {
    label: 'Comment & Recommendations',
    url: null,
    event: 'openAction',
    icon: null,
    description: 'Comment & Recommendations',
  },
  {
    label: 'Finish',
    url: null,
    event: 'finish',
    icon: 'info-circle-fill',
    description: 'Complete the grading process and send the result to the student.',
  },
  {
    label: 'Exit',
    url: '/dashboard',
    event: 'exit',
    icon: 'info-circle-fill',
    description: 'Save your current grading process and exit. You may resume at a later time.',
  },
]

export const COMMENTS = {
  Examiner_Comment: 'Examiner’s Comment',
  Recommendation: 'Recommendation',
  Cancel: 'Cancel',
  Save: 'Save',
  Comment: 'Comment',
  Grade: 'Grade',
  Grading: 'Grading',
  UpLoad: 'Upload graded file',
  FileDescription:
    '• Định dạng cho phép jpg, .jpeg, .png, .gif, .webp, .mp4, .pdf, .docx, .doc, .xls, .xlsx, .csv, .txt, .ppt, .pptx, .zip',
  FileSize: '• Kích thước tối đa của file là 500MB, video là 20GB',
}
