import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { StaffAPI } from 'src/apis/staffs'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { GUIDELINE_PASSWORD, PageLink, VALIDATION_FILED } from 'src/constants'
import { LANG_SIGNIN } from 'src/constants/lang'
import { useUserContext } from 'src/context/UserProvider'
import { validatePassword } from 'src/utils'
import { z } from 'zod'
import sapp from 'src/_metronic/assets/media/logos/sapp-logo-default.svg'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

interface IInputProps {
  confirmPassword: string
  password: string
}

const ChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const { staffForgot, emailStaff } = useUserContext()
  const navigate = useNavigate()

  // Validate for input
  const validationSchema = z
    .object({
      password: z
        .string({ required_error: VALIDATION_FILED })
        .min(8, { message: VALIDATION_FILED })
        .regex(new RegExp(validatePassword), {
          message: 'Password must be at least 8 characters, 1 uppercase letter, 1 number',
        }),
      confirmPassword: z
        .string({ required_error: VALIDATION_FILED })
        .min(8, { message: VALIDATION_FILED })
        .regex(new RegExp(validatePassword), {
          message: 'Password must be at least 8 characters, 1 uppercase letter, 1 number',
        }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords don't match",
      path: ['confirmPassword'],
    })

  // Using validate for input
  const { control, handleSubmit } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  // Call API when submit
  const onSubmit = async (data: IInputProps) => {
    await StaffAPI.changePassword(data.password, staffForgot?.code, emailStaff)
      .then(() => navigate(PageLink.AUTH_CHANGE_PASSWORD_SUCCESS))
      .catch((err: any) => {
        err?.response?.data?.error?.others?.map((error: any) =>
          error?.errors?.map((error: any) => toast.error(error.message))
        )
      })
  }

  const [passwordVisible, setPasswordVisible] = useState(true)
  const toggleChangeType = () => {
    setPasswordVisible(!passwordVisible)
  }

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(true)
  const toggleChangeTypeConfirmPassword = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }

  return (
    <div className='d-flex flex-column flex-root sapp-height-layout--login justify-content-center'>
      <div className='d-flex justify-content-center'>
        <img className='sapp-logo w-125px object-fit-contain' src={sapp} alt='sapp' />
      </div>
      <div className='d-flex flex-column  flex-lg-row justify-content-center'>
        <div className='d-flex  flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20'>
          <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20 box-shadow--custom'>
            <div className='d-flex flex-center flex-column'>
              {/* Start Form Login */}
              <form className='form w-100' onSubmit={handleSubmit(onSubmit)}>
                <div className='text-center mb-11'>
                  <h1 className='text-dark fw-bolder mb-3 text-left'>New Password</h1>
                  <div className='text-gray-500 fw-semibold fs-6 text-left'>
                    Set the new password for your account
                  </div>
                </div>

                {/* Start TextField Email */}
                <div className='fv-row mb-8'>
                  <HookFormTextField
                    control={control}
                    name='password'
                    placeholder={LANG_SIGNIN.password}
                    type={passwordVisible ? 'password' : 'text'}
                    onChangeType={toggleChangeType}
                    passwordVisible={passwordVisible}
                    showIconPassword
                    guideline={GUIDELINE_PASSWORD}
                    className='sapp-h-50px'
                  />
                </div>
                {/* End TextField Email */}

                {/* Start TextField Password */}
                <div className='fv-row mb-8'>
                  <HookFormTextField
                    control={control}
                    name='confirmPassword'
                    placeholder='Confirm Password'
                    type={confirmPasswordVisible ? 'password' : 'text'}
                    onChangeType={toggleChangeTypeConfirmPassword}
                    passwordVisible={confirmPasswordVisible}
                    showIconPassword
                    className='sapp-h-50px'
                  />
                </div>
                {/* End TextField Password */}

                {/* Start Button Login */}
                <div className='d-grid mb-5 sapp-h-50px'>
                  <ButtonPrimary
                    title='Submit'
                    loading={loading}
                    type='submit'
                    className='sapp-h-50px'
                  />
                </div>
                <div className='d-grid sapp-h-50px'>
                  <ButtonSecondary
                    link={PageLink.AUTH_LOGIN}
                    title='Cancel'
                    className='sapp-me-0 sapp-h-50px'
                  />
                </div>
                {/* End Button Login */}
              </form>
              {/* End Form Login */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
