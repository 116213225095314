import ActionCell from 'src/components/base/action/ActionCell'
import { CertificatesApi } from 'src/apis/certificates'
import { toast } from 'react-hot-toast'
import PopupConfirm from 'src/common/PopupConfirm'
import React, { useState } from 'react'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_CERTIFICATE_GR } from 'src/constants/permission'
import { Role } from 'src/type'

interface IProps {
  Action: any
  data: any
  id: string
  fetchCertificateList: (currentPage: number, pageSize: number, params?: Object) => void
  page_index: number
  page_size: number
  filterParams: Object
}
const CertificateAction = ({
  Action,
  data,
  id,
  fetchCertificateList,
  page_index,
  page_size,
  filterParams,
}: IProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(false)

  const deleteCertificate = async () => {
    try {
      const res = await CertificatesApi.delete(id)
      if (res) {
        toast.success('Delete Successfully!')
        fetchCertificateList(page_index || 1, page_size || 10, filterParams)
      }
    } finally {
      setOpenModalDelete(false)
    }
  }
  const { profileMe } = useUserContext()
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_CERTIFICATE_GR.EDIT_CERTIFICATE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <>
      {allowRenderEdit || (
        <ActionCell>
          {allowRenderEdit && (
            <div className='menu-item px-3' onClick={() => Action('edit', data)}>
              <div className='menu-link px-3'>Edit</div>
            </div>
          )}
          <div className='menu-item px-3' onClick={() => Action('preview', data)}>
            <div className='menu-link px-3'>Preview</div>
          </div>
          {/*<div className='menu-item px-3' onClick={() => setOpenModalDelete(true)}>
          <div className='menu-link px-3'>Delete</div>
        </div>*/}
        </ActionCell>
      )}

      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClick={deleteCertificate}
        body='Bạn có chắc chắn muốn xóa không?'
      />
    </>
  )
}
export default CertificateAction
