import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import Card from 'src/components/base/Card'
import RenderTeacherProfile from 'src/components/teacher-profile/RenderTeacherProfile'
import { PageLink, TEACHER_PROFILE } from 'src/constants'
import { ITabs } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import { divide, round } from 'lodash'

const TeacherProfile = () => {
  const [loading, setLoading] = useState(true)
  const [teacherDetail, setTeacherDetail] = useState<IStudentDetail>()
  const { id } = useParams()

  const breadcrumbs: ITabs[] = [
    {
      link: `${TEACHER_PROFILE}/${id}/overview`,
      title: 'LMS',
    },
    {
      link: `${PageLink.TEACHERS}`,
      title: 'List Teachers',
    },
    {
      link: `${PageLink.AUTH_LOGIN}`,
      title: 'Teacher Profile',
    },
  ]

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${TEACHER_PROFILE}/${id}/overview`,
      title: 'Overview',
    },
    {
      link: `${TEACHER_PROFILE}/${id}/setting`,
      title: 'Setting',
    },
    {
      link: `${TEACHER_PROFILE}/${id}/class`,
      title: 'Class & Course',
    },
    // {
    //   link: `${TEACHER_PROFILE}/${id}/security`,
    //   title: 'Security',
    // },
    // {
    //   link: `${TEACHER_PROFILE}/${id}/activity`,
    //   title: 'Activity',
    // },
    // {
    //   link: `${TEACHER_PROFILE}/${id}/billing`,
    //   title: 'Billing',
    // },
    // {
    //   link: `${TEACHER_PROFILE}/${id}/statements`,
    //   title: 'Statements',
    // },
    // {
    //   link: `${TEACHER_PROFILE}/${id}/referrals`,
    //   title: 'Referrals',
    // },
    // {
    //   link: `${TEACHER_PROFILE}/${id}/logs`,
    //   title: 'Logs',
    // },
  ]
  // const {profileMe} = useUserContext()

  // const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
  //   roles?.some(
  //     (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
  //   ) || false

  // const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)

  // const filteredTabs = allowRenderEdit ? tabs : tabs.filter((tab) => tab.title !== 'Setting')

  const getTeacherDetail = async () => {
    setLoading(true)
    try {
      const res = await UsersAPI.detail(id)
      setTeacherDetail(res?.data)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!loading) return
    getTeacherDetail()
  }, [loading])

  const inputFields = [
    teacherDetail?.key,
    teacherDetail?.detail?.full_name,
    teacherDetail?.username,
    teacherDetail?.detail?.email,
    teacherDetail?.detail?.phone,
    teacherDetail?.status,
    teacherDetail?.files?.[0]?.file_url,
  ]

  const countNonEmptyFields = () => {
    return inputFields.filter((input) => input !== undefined).length
  }

  return (
    <PageLayouts pageTitle='Teacher Profile' breadcrumbs={breadcrumbs}>
      <Card
        userDetail={teacherDetail}
        tabs={tabs}
        loading={loading}
        progress={round(divide(countNonEmptyFields(), inputFields.length), 2)}
      />
      <RenderTeacherProfile
        teacherDetail={teacherDetail}
        setLoading={setLoading}
        loading={loading}
        setTeacherDetail={setTeacherDetail}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(TeacherProfile)
