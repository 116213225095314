import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {NotificationAPI} from 'src/apis/notifications'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import useChecked from 'src/hooks/use-checked'
import {useConfirm} from 'src/hooks/use-confirm'
import {INotificationGroup} from 'src/type/notification'
import TableGroup from './TableGroup'
import {DESCRIPTION_POPUPCONFIRM} from 'src/constants/lang'

interface IProps {
  open: boolean
  setOpen: any
  group?: any
  setGroup?: React.Dispatch<React.SetStateAction<INotificationGroup[]>>
}
const AddGroupToNoti = ({open, setOpen, group, setGroup}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [groupState, setGroupState] = useState<any>()
  const {id} = useParams()

  const getParams = (
    name: string,
    type: string,
    sort_type: string,
    fromDate: string,
    toDate: string,
    selectType: string
  ) => ({
    name,
    type,
    sort_type,
    fromDate,
    toDate,
    selectType,
  })
  const queryParams = {
    name: '',
    type: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    selectType: '',
    page_index: 1,
    page_size: 10,
  }

  const fetchListGroup = (page_index?: number, page_size?: number, params?: Object) => {
    ;(async () => {
      try {
        const response = await NotificationAPI.getNotificationSelectGroups(
          id ? id : ' ',
          page_index || 1,
          page_size || 10,
          params
        )
        setGroupState(response?.data)
        setLoading(true)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    })()
  }

  const {
    checkedList,
    listDataChecked,
    toggleCheck,
    toggleCheckAll,
    isCheckedAll,
    setDefaultChecked,
  } = useChecked<INotificationGroup>(groupState?.groups ?? undefined)

  const {confirm, contextHolder} = useConfirm()

  useEffect(() => {
    if (open && group) {
      setDefaultChecked(group)
    }
  }, [open])

  const handleClose = () => {
    setOpen()
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: DESCRIPTION_POPUPCONFIRM,
      onClick: handleClose,
    })
  }

  const handleSubmit = async () => {
    // id && (await NotificationAPI.addStudentGroup(checkedList, id))
    setGroup && setGroup(listDataChecked)
    setOpen()
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Send to</div>
          <div className='d-flex justify-content-between align-items-center'>
            {listDataChecked.length > 0 && (
              <div className='fw-bold sapp-fs-16px text-primary me-8'>
                {listDataChecked.length} Selected
              </div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0 sapp-fs-16px'
              classNameSubmit='fw-bold me-5 sapp-fs-16px'
              // loading={loading}
            />
          </div>
        </div>
        <div className='px-7'>
          <TableGroup
            notificationId={id}
            groupState={groupState}
            loading={loading}
            setLoading={setLoading}
            handleChangeParams={() => {}}
            fetchListGroups={fetchListGroup}
            filterParams={{}}
            getParams={getParams}
            queryParams={queryParams}
            checkedList={checkedList}
            toggleCheck={toggleCheck}
            toggleCheckAll={toggleCheckAll}
            isCheckedAll={isCheckedAll}
          />
        </div>
      </SappModal>
    </>
  )
}
export default AddGroupToNoti
