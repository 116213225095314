import { Dispatch, SetStateAction, useState } from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { EntranceApi } from 'src/apis/entrance-test'
import AddParticipantTable from './AddParticipantTable'
import { IParticipantInTest, IParticipantInTestList } from 'src/type/entrancetest'
import toast from 'react-hot-toast'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  id: string
  fetchParticipantPage?: () => void
}

const AddParticipant = ({ open, setOpen, id, fetchParticipantPage }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [participantList, setParticipantList] = useState<IParticipantInTestList>()
  const getParams = (
    text: string,
    sortType: string,
    fromDate?: string | Date,
    toDate?: string | Date,
    dateField?: string
  ) => ({
    text,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })

  const queryParams = {
    text: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }

  const fetchParticipants = async (page_index?: number, page_size?: number, params?: Object) => {
    try {
      if (!id) return
      setLoading(true)
      const response = await EntranceApi.getParticipantEntranceTest({
        id,
        page_index: page_index || 1,
        page_size: page_size || 10,
        params: {
          ...(params ?? {}),
          filter_type: 'NOT_SELECTED',
        },
      })
      setParticipantList(response?.data)
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  const { checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll } =
    useChecked<IParticipantInTest>(participantList?.data)

  const { confirm, contextHolder } = useConfirm()

  const handleClose = () => {
    setOpen(false)
    toggleCheckAll(false)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc chắn muốn hủy không?',
      onClick: handleClose,
    })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const response = await EntranceApi.addParticipantToEntranceTest(id, { user_ids: checkedList })
      fetchParticipantPage && fetchParticipantPage()
      toggleCheckAll(false)
      if (response.data.invalid_student?.participants?.length) {
        const message = response.data.invalid_student.error_message
        response.data.invalid_student.participants.map((participant) => {
          toast.error(`${participant}: ${message}!`)
        })
      } else {
        toast.success('Add Participants successfully!')
      }
      setOpen(false)
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Add Participants</div>
          <div className='d-flex justify-content-between align-items-center'>
            {listDataChecked.length > 0 && (
              <div className='fw-bold fs-6 text-primary me-8'>
                {listDataChecked.length} Selected
              </div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              loading={loading}
            />
          </div>
        </div>
        <div className='px-7'>
          <AddParticipantTable
            participantList={participantList}
            loading={loading}
            setLoading={setLoading}
            handleChangeParams={() => {}}
            fetchParticipants={fetchParticipants}
            filterParams={{}}
            getParams={getParams}
            queryParams={queryParams}
            checkedList={checkedList}
            toggleCheck={toggleCheck}
            toggleCheckAll={toggleCheckAll}
            isCheckedAll={isCheckedAll}
          />
        </div>
      </SappModal>
    </>
  )
}
export default AddParticipant
