import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import {useListView} from 'src/components/user-list/components/core/ListViewProvider'
import {User} from '../../core/_models'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'

type Props = {
  tableProps: PropsWithChildren<HeaderProps<User>>
}

const UserSelectionHeader: FC<Props> = ({tableProps}) => {
  const {isAllSelected, onSelectAll} = useListView()
  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <SAPPCheckbox checked={isAllSelected} ktCheck={isAllSelected} onChange={onSelectAll} />
    </th>
  )
}

export {UserSelectionHeader}
