import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import './sidebar.scss'

interface IProps {
  item?: any
  menuLMS?: Array<any> | undefined
  menuAdmin?: Array<any> | undefined
  activeAdmin?: boolean
  sidebarLeft?: boolean
}
const MenuItem = ({item, sidebarLeft}: IProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>(item.showMenu ?? item.active)

  const hasChildren = item?.children && item?.children?.length > 0

  const handleClickMenu = () => {
    setOpenMenu(!openMenu)
  }

  useEffect(() => {
    if (item.showMenu !== null) {
      setOpenMenu(item.showMenu)
    }
  }, [item.showMenu])

  return (
    <>
      <div
        className={`${hasChildren ? 'here menu-accordion' : ''} ${
          !openMenu ? '' : 'hover show'
        } menu-item`}
        onClick={handleClickMenu}
      >
        <Link
          to={item.link}
          className={`${item.active ? 'active' : ''} menu-link ${
            sidebarLeft ? 'sapp-group-hover--sidebarleft' : 'sapp-group-hover--sidebarright'
          }`}
        >
          <span className='menu-icon'>
            <i
              className={`${item.icon} fs-2 ${
                item.active || (item.showMenu && sidebarLeft) ? 'menu-active' : ''
              }`}
            ></i>
          </span>
          <span
            className={`menu-title ${
              item.active && sidebarLeft
                ? 'text-hover-primary'
                : sidebarLeft && !item.showMenu && !openMenu
                ? 'text-gray-600'
                : ''
            }`}
          >
            {item.name}
          </span>
          {hasChildren && (
            <span
              className={`menu-arrow ${
                (item.showMenu || item.active || openMenu) && !sidebarLeft
                  ? 'sapp-icon-sidebarright'
                  : (item.showMenu || item.active || openMenu) && sidebarLeft
                  ? 'sapp-icon-sidebarleft'
                  : ''
              }`}
            ></span>
          )}
        </Link>
      </div>

      <div
        style={{
          opacity: !openMenu ? '0' : '1',
          transition: 'all .2s ease-in-out',
          visibility: !openMenu ? 'hidden' : 'visible',
        }}
      >
        {openMenu && (
          <ul className='my-0'>
            {item?.children?.map((child: any) => {
              return (
                <div className={`menu-item ${child ? 'here menu-accordion' : ''}`} key={child.id}>
                  <Link
                    to={child?.link}
                    className={`${child?.active ? 'active text-hover-primary' : ''} menu-link`}
                  >
                    <span className='menu-bullet'>
                      <span className='bullet bullet-dot'></span>
                    </span>
                    <span
                      className={`${
                        child.active && sidebarLeft
                          ? 'text-hover-primary'
                          : child.active && !sidebarLeft
                          ? ''
                          : 'text-gray-700'
                      } menu-title`}
                    >
                      {child.name}
                    </span>
                  </Link>
                </div>
              )
            })}
          </ul>
        )}
      </div>
    </>
  )
}

export default MenuItem
