import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CoursesAPI } from 'src/apis/courses'
import LoadingTable from 'src/common/LoadingTable'
import PopupConfirm from 'src/common/PopupConfirm'
import ActionCell from 'src/components/base/action/ActionCell'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import { LANG_COURSE_CATEGORY } from 'src/constants/lang'
import { ICourseCategoies } from 'src/type/courses'
import CreateAndUpdateCategory from './CreateAndUpdateCategory'
import { MOCKUP_HEADER } from 'src/constants'
import { truncateString } from 'src/utils/string'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { useNavigate } from 'react-router-dom'
import { KTCardBody } from 'src/_metronic/helpers'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'
import { CODE_ADMIN, TITLE_COURSE_CATEGORY_GR } from 'src/constants/permission'

interface IProps {
  courseCategory: ICourseCategoies | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  fetchCourseCategory: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  setOpenCourse: Dispatch<SetStateAction<boolean>>
  open: boolean
  idItem: string
  setIdItem: Dispatch<SetStateAction<string>>
  queryParams: any
  getParams: any
  checkedList: string[]
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

const headers = [
  {
    label: LANG_COURSE_CATEGORY.categoryName,
    className: 'min-w-200px pe-5',
  },
  {
    label: LANG_COURSE_CATEGORY.description,
    className: 'min-w-250px pe-5',
  },
  {
    label: 'Updated At',
    className: 'min-w-250px pe-5',
  },
  {
    label: '',
    className: 'pe-5 w-25px',
  },
]

const TableListCategory = ({
  courseCategory,
  fetchCourseCategory,
  filterParams,
  loading,
  setLoading,
  setOpenCourse,
  open,
  idItem,
  setIdItem,
  queryParams,
  getParams,
  checkedList,
  toggleCheck,
  toggleCheckAll,
  isCheckedAll,
}: IProps) => {
  const navigate = useNavigate()

  // check xem đã có
  const showQueryParams = queryParams.name || queryParams.fromDate || queryParams.toDate

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  //TODO: dùng để change params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )

  // TODO: state dùng để mở modal delete
  const [openModalDelete, setOpenModalDelete] = useState(false)

  const handleOpenModal = (id: string) => {
    setOpenCourse(true)
    setIdItem(id)
  }

  const deleteCategory = async () => {
    setOpenModalDelete(false)
    setLoading(true)
    try {
      const res = await CoursesAPI.deleteCategory(idItem)

      if (res.success) {
        fetchCourseCategory(
          queryParams?.page_index || 1,
          queryParams?.page_size || 10,
          filterParams
        )
      }
    } catch (error) {
    } finally {
      fetchCourseCategory(queryParams?.page_index || 1, queryParams?.page_size || 10, filterParams)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchCourseCategory(queryParams?.page_index || 1, queryParams?.page_size || 10, filterParams)
  }, [])

  //TODO: đổi params trên router
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: queryParams.name ?? '',
      fromDate: formatDate(queryParams.fromDate) ?? '',
      toDate: formatDate(queryParams.toDate) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchCourseCategory(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_COURSE_CATEGORY_GR.EDIT_CATEGORY) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  // const allowRenderCreate = profileMe?.roles?.some(
  //   (role: Role) =>
  //     hasPermission(role, TITLE_COURSE_CATEGORY_GR.CREATE_CATEGORY) ||
  //     role.code === CODE_ADMIN.SUPER_ADMIN
  // )

  return (
    <KTCardBody>
      <SappTable
        data={courseCategory?.course_categories}
        headers={headers}
        loading={loading}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((header, i) => (
              <LoadingTable key={header} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {courseCategory?.course_categories?.map((category) => {
              const isChecked = checkedList.includes(category.id)
              return (
                <tr key={category.id}>
                  <td>
                    <SAPPCheckbox
                      checked={isChecked}
                      onChange={() => {
                        toggleCheck(category.id)
                      }}
                    />
                  </td>
                  <td>{category?.name}</td>
                  <td className='text-start'>
                    {category?.description ? truncateString(category?.description, 160) : ''}
                  </td>
                  <td className='text-start'>
                    <DatetimeColumn
                      created_at={category?.created_at}
                      updated_at={category?.updated_at}
                    />
                  </td>
                  <td className='text-end'>
                    <ActionCell customWidth='w-150px'>
                      {allowRenderEdit && (
                        <div
                          className='menu-item px-3'
                          onClick={() => handleOpenModal(category.id)}
                        >
                          <div className='menu-link px-3'>Edit</div>
                        </div>
                      )}
                      {/* <div
                        className='menu-item px-3'
                        onClick={() => handleDeleteCategory(category.id)}
                      >
                        <div className='menu-link px-3'>Delete</div>
                      </div> */}
                    </ActionCell>
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <CreateAndUpdateCategory
        handleClose={() => setOpenCourse(false)}
        setLoading={setLoading}
        open={open}
        id={idItem}
        fetchCourseCategory={fetchCourseCategory}
        currentPage={queryParams?.page_index || 1}
        pageSize={queryParams?.page_size || 10}
        loading={loading}
      />
      <PopupConfirm
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClick={deleteCategory}
        body='Are you sure you want to delete the category?'
        okButtonCaption='Yes, delete!'
      />
      <PagiantionSAPP
        currentPage={courseCategory?.metadata?.page_index || 1}
        pageSize={courseCategory?.metadata?.page_size || 10}
        totalItems={courseCategory?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableListCategory
