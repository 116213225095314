import {fetcher} from 'src/services/request'
import {IResponse} from 'src/type'
import {IMailAction, IMailLog, IMailLogList} from 'src/type/mail-log'

export class MailLogApi {
  static get({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IMailLogList>> {
    return fetcher(`mail-logs?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getActions(): Promise<IResponse<IMailAction[]>> {
    return fetcher(`mail-logs/actions`)
  }

  static getMailLog(id: string): Promise<IResponse<IMailLog>> {
    return fetcher(`mail-logs/${id}`)
  }
}
