import {useLayoutEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {QuestionBankAPI} from 'src/apis/question-bank'
import DragDropQuestion from 'src/components/question-bank/create-question/DragDropQuestion'
import EssayQuestion from 'src/components/question-bank/create-question/EssayQuestion'
import FillUpQuestion from 'src/components/question-bank/create-question/FillUpQuestion'
import MatchingQuestion from 'src/components/question-bank/create-question/MatchingQuestion'
import MultiChoiceQuestion from 'src/components/question-bank/create-question/MultiChoiceQuestion'
import OneChoiceQuestion from 'src/components/question-bank/create-question/OneChoiceQuestion'
import SelectMissingWordQuestion from 'src/components/question-bank/create-question/SelectMissingWordQuestion'
import TrueFalseQuestion from 'src/components/question-bank/create-question/TrueFalseQuestion'
import {QUESTION_TYPES} from 'src/components/question-bank/shared/interfaces'
import {PageLink} from 'src/constants'
import {ICommonQuestionProps} from 'src/type/question-bank'
type Props = {}

const Question = (props: Props) => {
  const navigate = useNavigate()

  // const [activeQuestForm, setActiveQuestForm] = useState<QUESTION_TYPES | null>(null)
  const [actionType, setActionType] = useState<'edit' | 'duplicate' | undefined>('edit')
  const [reload, setReload] = useState<boolean>(false)
  const [question, setQuestion] = useState<ICommonQuestionProps>({
    actionType: 'edit',
    id: undefined,
    open: false,
    type: '',
    data: undefined,
    onClose: () => {},
  })
  const handleReload = () => setReload(!reload)
  const handleCloseAddNew = () => {
    if (handleReload) {
      handleReload()
    }
    // setActiveQuestForm(null)
    question?.data?.is_single_question
      ? navigate(PageLink.TOPICS, {replace: true})
      : question?.data?.is_independent_question
      ? navigate(`${PageLink.TOPIC}/${id}`)
      : navigate(-1)
  }
  const {id} = useParams()

  const fetch = async () => {
    if (!id) return
    const res = await QuestionBankAPI.getQuestionById(id)
    setQuestion({
      actionType: actionType,
      id: id,
      open: true,
      onClose: handleCloseAddNew,
      data: res.data,
      type: res.data.qType ?? '',
    })
  }
  useLayoutEffect(() => {
    fetch()
  }, [id])

  return (
    <>
      {(() => {
        switch (question?.data?.qType ?? null) {
          case QUESTION_TYPES.TRUE_FALSE:
            return <TrueFalseQuestion {...question} type='True/False'></TrueFalseQuestion>
          case QUESTION_TYPES.ONE_CHOICE:
            return <OneChoiceQuestion {...question} type='One choice'></OneChoiceQuestion>
          case QUESTION_TYPES.MULTIPLE_CHOICE:
            return <MultiChoiceQuestion {...question} type='Multiple choice'></MultiChoiceQuestion>
          case QUESTION_TYPES.MATCHING:
            return <MatchingQuestion {...question} type='Matching'></MatchingQuestion>
          case QUESTION_TYPES.SELECT_WORD:
            return (
              <SelectMissingWordQuestion
                {...question}
                type='Select word'
              ></SelectMissingWordQuestion>
            )
          case QUESTION_TYPES.FILL_WORD:
            return <FillUpQuestion {...question} type='Fill up'></FillUpQuestion>
          case QUESTION_TYPES.DRAG_DROP:
            return <DragDropQuestion {...question} type='Drag Drop'></DragDropQuestion>
          case QUESTION_TYPES.ESSAY:
            return <EssayQuestion {...question} type='Constructed'></EssayQuestion>
          default:
            return <></>
        }
      })()}
    </>
  )
}

export default Question
