import React from 'react'
import styles from './ButtonDanger.module.scss'
import { Link } from 'react-router-dom'
import { IButtonProps } from 'src/type/button'

const ButtonDanger = ({ title, size = 'large', disabled, loading, link = '' }: IButtonProps) => {
  const heightClass = size === 'large' ? 'sapp-h-45px' : 'sapp-h-40'

  if (link)
    return (
      <Link to={link} className={`btn fw-semiboid ${heightClass} ${styles['sapp-btn--danger']}`}>
        {title}
      </Link>
    )

  return (
    <button
      disabled={disabled || loading}
      className={`btn ${heightClass} ${styles['sapp-btn--danger']}`}
    >
      {title}
    </button>
  )
}

export default ButtonDanger
