import SappBaseTable from 'src/common/SappBaseTable'
import './TableTestQuizDetail.scss'
import { format } from 'date-fns'
import { IQuizDetail } from 'src/type/quiz'
import SappActionCellV2 from 'src/components/base/action/SappActionCellV2'
import AccessTimeModal from './AccessTimeModal'
import { useState } from 'react'
import { ClassesApi } from 'src/apis/classes'
import toast from 'react-hot-toast'
import { QUIZ_ATTEMPT_STATUS } from 'src/constants'

const headersGradingMethodAuto = [
  {
    label: '#',
    className: 'min-w-50px',
  },
  {
    label: 'Student ID',
    className: 'min-w-150px',
  },
  {
    label: 'Name',
    className: 'min-w-150px',
  },
  {
    label: 'Email',
    className: 'min-w-200px',
  },
  {
    label: 'Access Preiod',
    className: 'min-w-250px',
  },
  {
    label: 'Thời gian nộp',
    className: 'min-w-250px',
  },
  {
    label: 'Trạng thái',
    className: 'min-w-150px',
  },
  // {
  //   label: 'Số lần chấm',
  //   className: 'min-w-150px',
  // },
  {
    label: 'Điểm lần chấm cuối',
    className: 'min-w-150px',
  },
  // {
  //   label: 'Người chấm',
  //   className: 'min-w-150px',
  // },
  {
    label: '',
  },
]

const headersGradingMethodManual = [
  {
    label: '#',
    className: 'min-w-50px',
  },
  {
    label: 'Student ID',
    className: 'min-w-150px',
  },

  {
    label: 'Name',
    className: 'min-w-150px',
  },
  {
    label: 'Email',
    className: 'min-w-200px',
  },
  {
    label: 'Access Period',
    className: 'min-w-250px',
  },
  {
    label: 'Thời gian nộp',
    className: 'min-w-175px',
  },
  {
    label: 'Trạng thái',
    className: 'min-w-150px',
  },
  {
    label: 'Số lần chấm',
    className: 'min-w-150px text-center',
  },
  {
    label: 'Điểm lần chấm cuối',
    className: 'min-w-150px',
  },
  {
    label: 'Người chấm',
    className: 'min-w-150px',
  },
  {
    label: '',
  },
]

interface IProps {
  quizDetail: IQuizDetail | undefined
  loading: boolean
  fetchTestQuiz: (page_index: number, page_size: number, params?: Object) => void
  filterTestQuiz: Object
  quiz_id: { id: string; name: string; grading_method: string }
  startDate?: string
  endDate?: string
  isDuration?: boolean
}

export interface IAccessTime {
  isOpen: boolean
  id: string
  defaultValue?: {
    start_time?: Date | string
    end_time?: Date | string
  }
}

const TableTestQuizDetail = ({
  quizDetail,
  loading,
  fetchTestQuiz,
  filterTestQuiz,
  quiz_id,
  startDate,
  endDate,
  isDuration,
}: IProps) => {
  const defaultAccessTime = {
    isOpen: false,
    id: '',
    defaultValue: {
      start_time: new Date(),
      end_time: new Date(),
    },
  }
  const [openAccessTime, setOpenAccessTime] = useState<IAccessTime>(defaultAccessTime)

  const handleCloseAccessTime = () => setOpenAccessTime(defaultAccessTime)

  const handleUpdateAccessTime = async (data: { start_time: Date; end_time: Date }) => {
    try {
      await ClassesApi.updateAccessTimeUser({ id: openAccessTime.id, data })
      await fetchTestQuiz(1, 10)
      handleCloseAccessTime()
      toast.success('update Access Time successfully!')
    } catch (err) {}
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchTestQuiz(page_index || 1, page_size || 10, filterTestQuiz)
  }

  const quizManual = quiz_id.grading_method === 'MANUAL'
  return (
    <>
      <SappBaseTable
        headers={quizManual ? headersGradingMethodManual : headersGradingMethodAuto}
        data={quizDetail?.class_user_quizzes}
        hasCheck={false}
        loading={loading}
        // currentPage={quizDetail?.metadata?.page_index || 1}
        // pageSize={quizDetail?.metadata?.page_size || 10}
        totalItems={quizDetail?.metadata?.total_records || 10}
        handlePaginationChange={handlePaginationChange}
        classNameTable='max-w-none'
        dataResponse={quizDetail}
      >
        {quizDetail?.class_user_quizzes?.map((quiz, index: number) => {
          const userContact = quiz?.user?.user_contacts?.find((user) => user.is_default)
          return (
            <tr className='border-0' key={index}>
              <td>{index + 1}</td>
              <td>{quiz?.user?.hubspot_contact_id || '-'}</td>
              <td>{quiz?.user?.detail?.full_name}</td>
              <td>{userContact?.email}</td>
              {quizManual ? (
                <>
                  <td>
                    {quiz?.start_time ? format(new Date(quiz?.start_time), 'dd/MM/yyyy') : ''} -{' '}
                    {quiz?.end_time ? format(new Date(quiz?.end_time), 'dd/MM/yyyy') : ''}
                  </td>
                  <td>
                    {quiz?.attempt?.finished_at
                      ? format(new Date(quiz?.attempt?.finished_at), 'HH:mm dd/MM/yyyy')
                      : '-'}
                  </td>
                  <td>
                    {quiz?.attempt?.grading_status === QUIZ_ATTEMPT_STATUS.AWAITING_GRADING
                      ? 'Awating Grading'
                      : quiz?.attempt?.grading_status === QUIZ_ATTEMPT_STATUS.FINISHED_GRADING
                      ? 'Finish Grading'
                      : 'Unsubmitted'}
                  </td>
                  <td className='text-center'>{quiz?.attempt?.attempt_gradings?.length || 0}</td>
                  <td>{quiz?.attempt?.score || '-'}</td>
                  <td>{quiz?.staff?.detail?.full_name}</td>
                  {/* <td>
                    <SappActionCellV2
                      items={[
                        { label: 'Chấm bài', onClick: () => { } },
                        { label: 'Đổi người chấm', onClick: () => { } },
                      ]}
                    />
                  </td> */}
                </>
              ) : (
                <>
                  <td>
                    {quiz?.start_time ? format(new Date(quiz?.start_time), 'HH:mm dd/MM/yyyy') : ''}{' '}
                    - {quiz?.end_time ? format(new Date(quiz?.end_time), 'HH:mm dd/MM/yyyy') : ''}
                  </td>
                  <td>
                    {quiz?.attempt?.finished_at
                      ? format(new Date(quiz?.attempt?.finished_at), 'HH:mm dd/MM/yyyy')
                      : '--'}
                  </td>
                  <td>
                    {quiz?.attempt?.status === QUIZ_ATTEMPT_STATUS.SUBMITTED
                      ? 'Submitted'
                      : 'Unsubmitted'}
                  </td>
                  <td>
                    {quizDetail.class_quiz?.quiz?.quiz_question_type === 'MULTIPLE_CHOICE'
                      ? quiz?.attempt?.ratio_score
                      : quiz?.attempt?.score
                      ? quiz?.attempt?.score
                      : '--'}
                  </td>
                  <td>
                    {isDuration && (
                      <SappActionCellV2
                        items={[
                          {
                            label: 'Edit Access Time',
                            onClick: () =>
                              setOpenAccessTime({
                                id: quiz.id,
                                isOpen: true,
                                defaultValue: {
                                  start_time: quiz.start_time
                                    ? new Date(quiz.start_time)
                                    : new Date(),
                                  end_time: quiz.end_time ? new Date(quiz.end_time) : new Date(),
                                },
                              }),
                          },
                        ]}
                      />
                    )}
                  </td>
                </>
              )}
            </tr>
          )
        })}
      </SappBaseTable>
      <AccessTimeModal
        open={openAccessTime}
        onClose={handleCloseAccessTime}
        onSaveTime={handleUpdateAccessTime}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  )
}

export default TableTestQuizDetail
