import { Skeleton } from 'antd'
import clsx from 'clsx'
import { IFilterTabProps } from 'src/type/grade'

interface TabSlidesProps {
  isLoading: boolean
  currentTabId: string
  filteredTabs: IFilterTabProps[]
  openGrid: boolean
  handleChangeTab: (data: IFilterTabProps) => void
}

const TabSlides = ({
  isLoading,
  currentTabId,
  filteredTabs,
  openGrid,
  handleChangeTab,
}: TabSlidesProps) => {
  const onChangeQuestionAttempt = (item: IFilterTabProps) => {
    if (item.id === currentTabId) return
    scrollToTop()
    handleChangeTab(item)
  }

  const scrollToTop = () => {
    const element = document.querySelector('.sapp-grade-page .layout-left')
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className='sapp-tabslides-container'>
      <div className={clsx('sapp-tabslides-inner', { loading: isLoading })}>
        <Skeleton loading={isLoading} active title={false} paragraph={{ rows: 1, width: '100%' }}>
          {filteredTabs?.length > 0 && (
            <ul className={`sapp-tabslides ${openGrid ? 'sapp-tabslides-grid' : ''}`}>
              {filteredTabs?.map((item) => (
                <li
                  key={item.id}
                  className={`${item.status} ${currentTabId === item.id ? 'viewed' : ''}`}
                  onClick={() => onChangeQuestionAttempt(item)}
                >
                  {item.index}
                </li>
              ))}
            </ul>
          )}
        </Skeleton>
      </div>
    </div>
  )
}

export default TabSlides
