import { format } from 'date-fns'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { FORMAT_DATETIME, MY_PROFILE, TEACHER_PROFILE } from 'src/constants'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'
import { IStudentDetail } from 'src/type/students'

interface IProps {
  teacherDetail: IStudentDetail | undefined
  loading: boolean
  setTeacherDetail: Dispatch<SetStateAction<any>>
}

const TeacherProfileDetail = ({ teacherDetail, loading, setTeacherDetail }: IProps) => {
  const { id } = useParams()
  const { getStudentDetail } = useUserContext()

  useEffect(() => {
    if (!id || loading) return

    getStudentDetail(id).then((res) => setTeacherDetail(res?.data))
  }, [id])

  const userContact = teacherDetail?.user_contacts?.find((user) => user.is_default)
  const { profileMe } = useUserContext()
  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  return (
    <>
      {loading ? (
        <LoadingProfileDetail />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab
            title='Profile Details'
            titleButton={allowRender ? 'Edit Profile' : ''}
            link={`${TEACHER_PROFILE}/${id}/${MY_PROFILE.SETTING}`}
          />

          <div className='card-body p-9'>
            <ItemProfile title='Code' body={teacherDetail?.key} />
            <ItemProfile
              title={LANG_PLACEHOLDER.FULLNAME}
              body={teacherDetail?.detail?.full_name}
            />
            <ItemProfile title='Username' body={teacherDetail?.username} />
            <ItemProfile title='Email' body={teacherDetail?.detail?.email ?? userContact?.email} />
            <ItemProfile
              title='Phone Number'
              body={teacherDetail?.detail?.phone ?? userContact?.phone}
            />
            <ItemProfile title='Status' status={teacherDetail?.status} />
            <ItemProfile
              title='Gender'
              body={
                teacherDetail?.detail?.sex === 'MALE'
                  ? 'Male'
                  : teacherDetail?.detail?.sex === 'FEMALE'
                  ? 'Female'
                  : teacherDetail?.detail?.sex === 'OTHERS'
                  ? 'Others'
                  : '-'
              }
            />
            <ItemProfile title='Job Title' body={teacherDetail?.detail?.job_title} />
            <ItemProfile
              title='Facebook Link'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.facebook ?? '#'}
                >
                  {teacherDetail?.detail?.facebook}
                </Link>
              }
            />
            <ItemProfile
              title='Youtube Link'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.youtube ?? '#'}
                >
                  {teacherDetail?.detail?.youtube}
                </Link>
              }
            />
            <ItemProfile
              title='LinkedIn Link'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.linkedin ?? '#'}
                >
                  {teacherDetail?.detail?.linkedin}
                </Link>
              }
            />
            <ItemProfile
              title='Describe'
              body={
                <div dangerouslySetInnerHTML={{ __html: teacherDetail?.detail?.describe ?? '' }} />
              }
            />
            <ItemProfile
              title='Updated At'
              body={
                teacherDetail?.updated_at
                  ? format(new Date(teacherDetail?.updated_at), FORMAT_DATETIME)
                  : ''
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default TeacherProfileDetail
