import {useEffect, useState} from 'react'
import {UsersAPI} from 'src/apis/user'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import RenderProfile from 'src/components/my-profile/RenderProfile'
import Card from 'src/components/base/Card'
import {PageLink} from 'src/constants'
import {ITabs} from 'src/type'
import {IStudentDetail} from 'src/type/students'
import {divide, round} from 'lodash'
import {useUserContext} from 'src/context/UserProvider'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.PROFILE_OVERVIEW}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Account',
  },
]

// Config Tabs
const tabs: ITabs[] = [
  {
    link: PageLink.PROFILE_OVERVIEW,
    title: 'Overview',
  },
  {
    link: PageLink.PROFILE_SETTING,
    title: 'Setting',
  },
  // {
  //   link: PageLink.PROFILE_SECURITY,
  //   title: 'Security',
  // },
  // {
  //   link: PageLink.PROFILE_ACTIVITY,
  //   title: 'Activity',
  // },
  // {
  //   link: PageLink.PROFILE_BILLING,
  //   title: 'Billing',
  // },
  // {
  //   link: PageLink.PROFILE_STATEMENTS,
  //   title: 'Statements',
  // },
  // {
  //   link: PageLink.PROFILE_REFERRALS,
  //   title: 'Referrals',
  // },
  // {
  //   link: PageLink.PROFILE_LOGS,
  //   title: 'Logs',
  // },
]

const MyProfile = () => {
  const [loading, setLoading] = useState(false)
  const {profileMe} = useUserContext()
  const [profile, setProfile] = useState<IStudentDetail>()

  const getProfileDetail = async () => {
    setLoading(true)
    try {
      const res = await UsersAPI.myprofile()
      setProfile(res)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (profileMe) {
      setProfile(profileMe)
    }
    return () => {}
  }, [profileMe])

  const inputFields = [
    profile?.key,
    profile?.detail?.full_name,
    profile?.username,
    profile?.detail?.email,
    profile?.detail?.phone,
    profile?.status,
    profile?.roles,
    profile?.files?.[0]?.file_url,
  ]

  const countNonEmptyFields = () => {
    return inputFields.filter((input) => input !== undefined).length
  }

  return (
    <PageLayouts pageTitle='Account' breadcrumbs={breadcrumbs}>
      <Card
        loading={loading}
        tabs={tabs}
        userDetail={profile}
        progress={round(divide(countNonEmptyFields(), inputFields.length), 2)}
      />
      <RenderProfile
        userDetail={profile}
        loading={loading}
        setLoading={setLoading}
        setProfile={setProfile}
        getProfileDetail={getProfileDetail}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(MyProfile)
