import { Dispatch, SetStateAction } from 'react'
import PopupConfirm from 'src/common/PopupConfirm'
import ButtonDanger from 'src/components/ui/button-danger/ButtonDanger'

interface IProps {
  selected: Array<any>
  blockUser: () => void
  openBlocked: boolean
  setOpenBlocked: Dispatch<SetStateAction<boolean>>
  title?: string
  loading?: boolean
  body?: string
  okButtonCaption?: string
  cancelButtonCaption?: string
  className?: string
  disabled?: boolean
}

const UserListGrouping = ({
  selected,
  blockUser,
  openBlocked,
  setOpenBlocked,
  title = ' Block Selected',
  loading,
  body,
  okButtonCaption,
  cancelButtonCaption,
  className = '',
  disabled,
}: IProps) => {
  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span>{selected.length}</span> Selected
      </div>
      <ButtonDanger
        className={className}
        title={title}
        onClick={() => setOpenBlocked(true)}
        loading={loading}
        disabled={disabled}
        size='small'
      />
      <PopupConfirm
        open={openBlocked}
        setOpen={setOpenBlocked}
        onClick={blockUser}
        body={body}
        okButtonCaption={okButtonCaption}
        cancelButtonCaption={cancelButtonCaption}
      />
    </div>
  )
}

export default UserListGrouping
