import React, { useEffect, useRef, useState } from 'react'
import { Player, ControlBar } from 'video-react'
import { VideoSeekSlider } from 'react-video-seek-slider'
import 'video-react/dist/video-react.css'
import 'react-video-seek-slider/styles.css'
import { QuestionProviderProvider } from 'src/components/question-bank/shared/providers/QuestionProvider'
import './videoTimeline.scss'
import ChooseQuestionModal from '../modal-choose-question'
import { convertTime } from 'src/utils/string'
interface IProps {
  url?: string
  currentTime: number
  setCurrentTime: any
  listTimeLine: any[]
  setListTimeLine: any
  duration: any
  setDuration: any
  setOpenQuestionSelect: any
  openQuestionSelect: any
  setOpenMenuSelect: any
  openMenuSelect: any
  setListTimeLineMenu: any
  setSortedListTimeLine?: any
}
const VideoTimeLine = ({
  url,
  currentTime,
  setCurrentTime,
  setListTimeLine,
  duration,
  setDuration,
  setOpenQuestionSelect,
  openQuestionSelect,
  setSortedListTimeLine,
}: IProps) => {
  const playerRef = useRef(null) as any
  const [player, setPlayer] = useState<any>()
  // const [open, setOpen] = useState({state: false, data: {}, edit: false})
  // const [listTimeLine, setListTimeLine] = useState<any[]>([])
  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.subscribeToStateChange((e: any) => {
        setPlayer(e)
        setCurrentTime(e.currentTime)
        setDuration(e.duration)
      })
      //   setPlayer(playerRef.current.getState())
    }
  }, [playerRef, url])

  return (
    <QuestionProviderProvider>
      <Player ref={playerRef} aspectRatio='16:9'>
        <source src={url} />

        <ControlBar disableCompletely={true} />
      </Player>
      <div>
        {convertTime(currentTime)} / {convertTime(duration)}
      </div>
      {player !== undefined && (
        <>
          <VideoSeekSlider
            max={player?.duration * 1000 || 0}
            currentTime={player?.currentTime * 1000}
            onChange={(e) => {
              playerRef?.current?.seek(e / 1000)
              playerRef?.current?.pause()
            }}
          />
        </>
      )}
      <ChooseQuestionModal
        open={openQuestionSelect}
        onClose={() => setOpenQuestionSelect({ state: false, data: {}, edit: false })}
        player={player}
        setListTimeLine={setListTimeLine}
        setSortedListTimeLine={setSortedListTimeLine}
        isPreventDependentQuestion
      />
    </QuestionProviderProvider>
  )
}
export default VideoTimeLine
